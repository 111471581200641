import { Component, OnInit } from '@angular/core';
import {UserService} from "../services/user.service";
import { AdminService } from "../services/admin.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-block',
  templateUrl: './block.component.html',
  styleUrls: ['./block.component.css']
})
export class BlockComponent implements OnInit {

  constructor(public auth:UserService,
              public adminService:AdminService,
              public router:Router) { }
    findobj={
      ipv4_addr:'',
      _csrf:'',
      ipv6_addr:''
    }
  ngOnInit(): void {
    this.initialFuntion();
  }
  initialFuntion(){
    // check current ip
    this.auth.getIpvfourClient().subscribe((ipres)=>{
    this.auth.getIpvsixClient().subscribe((ipressix)=>{
      this.auth.csrf().subscribe((response) => {
        this.findobj.ipv4_addr=ipres.ip;
        this.findobj._csrf = response._csrf
        this.findobj.ipv6_addr=ipressix.ip
        console.log("getipAddrbyIP ******")
        this.adminService.getipAddrbyIP(this.findobj).subscribe(ipfind=>{
        },(err)=>{
            this.router.navigate(['']);
        })
      });
    });
    })
  }
}
