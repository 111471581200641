import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserService } from '../services/user.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { NotificationService } from '../notification/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { KycserviceService } from '../services/kycservice.service';
import { StorageService } from '../services/storage.service';
@Component({
  selector: 'app-apiconfig-management',
  templateUrl: './apiconfig-management.component.html',
  styleUrls: ['./apiconfig-management.component.css']
})
export class ApiconfigManagementComponent implements OnInit {
  displayedColumns: string[] = ['LegalName', 'ShortName', 'KeyName', 'ApiKey', 'IPAddresses', 'ViewPermission', 'TradePermission', 'TransferPermission', 'ExpiryAt', 'CreatedAt', 'action'];
  dataSource: MatTableDataSource<UserData>;
  userid: any;
  pageSize = 10;
  currentPage = 0;
  pageSizeOptions = [2, 5, 10, 25, 100];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private userService: UserService,
    private router: Router,
    private notifyService: NotificationService,
    public dialog: MatDialog,
    public kycService: KycserviceService,
    private storageService: StorageService,
  ) { 
    this.kycService.getUserKYC().subscribe(res => {
      if (res.status == 'Not Uploaded' || res.status == 'Pending' || res.status == 'Rejected' || res.status == 'Draft') {

        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist");
        } else {
          this.notifyService.showError("", "Not Accesible until KYC Verification");
        }  
        this.router.navigate(['kyc'])      
      }
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist")
      } else {
        this.notifyService.showError("", "Not Accesible until KYC Verification")
      }
      this.router.navigate(['kyc'])
    })
  }

  ngOnInit(): void {
    this.apiKeyConfigs();
  }

  apiKeyConfigs() {
    this.userService.apiConfigs().subscribe(response => {
      if (response) {
        let resData = response.map(api => {
          api.CreatedAt = moment(api.CreatedAt).format('YYYY-MM-DD HH:mm:ss');
          api.ExpiryAt = moment(api.ExpiryAt).format('YYYY-MM-DD HH:mm:ss');
          return api;
        })
        this.dataSource = new MatTableDataSource(resData);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }
  async pageChanged(event: PageEvent) {
    this.pageSize = await event.pageSize;
    this.currentPage = await event.pageIndex;
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
}
  editApiConfig(element: any) {
    this.router.navigate(['newapiconfig', element._id])
  }
  deleteApiConfig(element: any) {
    try {
      this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: `Are you sure want to  delete configuration?`,
          buttonText: {
            ok: 'Yes',
            cancel: 'No'
          }
        }
      }).afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.userService.csrf().subscribe(data => {
            let delObj = {}
            delObj['_csrf'] = data._csrf;
            delObj['id'] = element._id
            this.userService.deleteApiConfig(delObj).subscribe(response => {
              if (response && response.IsSuccess) {
                this.notifyService.showSuccess(response.Message, "Success");
                this.apiKeyConfigs();

              } else {
                this.notifyService.showError(response.Message, "Failed");
              }
            })
          })
        }
      });
    } catch (error) {
      console.log(error);
    }

  }
}
export interface UserData {
  Client: string,
  KeyName: string,
  UserName: string,
  ShortName: string,
  ApiKey: string,
  IPAddresses: string,
  ViewPermission: string,
  TradePermission: string,
  TransferPermission: string,
  ExpiryAt: number,
  CreatedAt: number,

}