import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import AOS from 'aos';
import { UserService } from "../services/user.service";
import { Router } from '@angular/router';
@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {
  isMaintenance: boolean = false;
  constructor(
    private userService: UserService,
    private router: Router,
  ) {
  }
  ngOnInit() {

    this.userService.getAdminConfig().subscribe((response) => {
      const maintenance = response.find((conf:any) => conf.key == 'maintenance')
      if(maintenance.value === 'false'){
        this.router.navigate(['login']);
      }
    }, (err) => {
      console.log(err)

    });

    AOS.init({
      once: true
    });
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    var introCarousel = $(".carousel");
    var introCarouselIndicators = $(".carousel-indicators");
    introCarousel.find(".carousel-inner").children(".carousel-item").each(function (index) {
      (index === 0) ?
        introCarouselIndicators.append("<li data-target='#introCarousel' data-slide-to='" + index + "' class='active'></li>") :
        introCarouselIndicators.append("<li data-target='#introCarousel' data-slide-to='" + index + "'></li>");
    });

    introCarousel.on('slid.bs.carousel', function (e) {
      $(this).find('h2').addClass('animate__animated animate__fadeInDown');
      $(this).find('p, .btn-get-started').addClass('animate__animated animate__fadeInUp');
    });
    if ($('.nav-menu').length) {

      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('fa fa-bars fa fa-times');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).on('click', '.mobile-nav li > a', function (e) {
        if ($('body').hasClass('mobile-nav-active') && !$(this).parent().hasClass('drop-down')) {
          $('body').removeClass('mobile-nav-active');
          $('.mobile-nav-toggle i').toggleClass('fa fa-bars fa fa-times');
          $('.mobile-nav-overly').fadeOut();
        }
      });

      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('fa fa-bars fa fa-times');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }
  }

}
