import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { kycupload, UserService } from "../services/user.service";
import { Router } from "@angular/router";
import { NotificationService } from "../notification/notification.service";
import { Location } from "@angular/common";
import { KycserviceService } from "../services/kycservice.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import * as $ from "jquery";
import { ProfilePayload } from "../services/user.service";
import { TradingService } from "../services/trading.service";
import { environment } from "../../environments/environment";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { FormatSettings } from "@progress/kendo-angular-dateinputs";
import { NgForm } from "@angular/forms";
import io from "socket.io-client";
import { StorageService } from "../services/storage.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { KycService } from "./kyc.service";
declare let snsWebSdk: any;
declare var HyperKYCModule: any;
declare var HyperKycConfig: any;
import * as moment from 'moment';
@Component({
  selector: "app-kyc",
  templateUrl: "./kyc.component.html",
  styleUrls: ["./kyc.component.css"],
})
export class KycComponent implements OnInit {
  token:any;
  hyperverge_token:any;
  hypervergeNotApproveStatus:boolean = false;
  hypervergeApproveStatus:boolean = false;
  hypervergeFinalKYCStatus:any;
  startOnboardingProcess:any;
  isProcessing: boolean = false;
  ubo_check:any
  submit: boolean = false;
  applicantEmail: String; 
  applicantPhone: any; 
  countries: any[];
  phonecodelist: any[];
  states: any;
  thirdStepMsg: string = ''
  profile: ProfilePayload = {
    _csrf: null,
    email: "",
    name: "",
    nickname: "",
    hyperverge_status: "",
    country: "",
    state: "",
    address: "",
    legalname: "",
    pin: "",
    language: "",
    timezone: "",
    phonecode: "",
    phone: 0,
    month: "06",
    year: "2020",
    date: "23",
    countrycode: "",
    authflag: true,
    userid: 0,
    profileimage: "",
    profileImage: "",
    confirmpassword: "",
    password: "",
    currentpassword: "",
    user_id: "",
    id_proof: "",
    idproof: "",
    dob: '',
    ubo_name: "",
    ubo_dob: '',
    ubo_phonecode: "",
    ubo_phone: 0,
    ubo_email: "",
    ubo_address: "",
    ubo_country: "",
    ubo_idproof: "",
    ubo_addressproof: "",
    ubo_check: 0,
    address_proof: "",
  };
  antiphis = {
    antiphiscode: "",
    _csrf: "",
  };
  errorObj = {
    password: "",
  };
  sumsubForm = {
    _csrf : "",
    userId : ""
  };
  sumsubStatus: any;
  previousRoute: string;
  verificationstr = "";
  diables: Boolean;
  dateList: any = [];
  fileToUpload: File = null;
  passwordstatus: any = "strong";
  nameMacher: any = false;
  imagepath = environment.imageapiendpoint;
  S3_BUCKET_URL = environment.S3_BUCKET_URL;
  datemodel: NgbDateStruct;
  user_country: any = [];
  checked: any;
  public today_date = new Date();
  public tyear = this.today_date.getFullYear();
  public tmonth = this.today_date.getMonth();
  public tday = this.today_date.getDate();
  public value: any;
  public max: Date = new Date(this.tyear, this.tmonth, this.tday);

  public value_ubo: any;
  public max_ubo: Date = new Date(this.tyear, this.tmonth, this.tday);
  public format: FormatSettings = {
    displayFormat: "dd/MM/yyyy",
    inputFormat: "dd/MM/yyyy",
  };
  public format_ubo: FormatSettings = {
    displayFormat: "dd/MM/yyyy",
    inputFormat: "dd/MM/yyyy",
  };
  public value_db: any;
  public value_ubo_db: any;

  formkyc: kycupload = {
    _csrf: "",
    front: "",
    back: "",
    board_member_id: "",
    pan: "",
    company_reg: "",
    authflag: false,
    aoa: "",
    bank_accn: "",
    video_selfie: "",
    source_funds: "",
    appln_questionnaire: "",
    addn_infor: "",
    letter_of_auth: "",
  };
  frontPlace: any;
  widgetUrlMap: SafeResourceUrl;
  placeHold: any;
  currentAvatar: any;
  kycdet: any = {};
  kycstatus: String = "";
  user: any;
  urlMap: SafeResourceUrl;
  closeResult: any = "";
  basis_status: any = "";
  idproof_status: any = "no";
  basisid_status: any = "no";
  submit_view: any = "no";
  private socket: any;
  userid: any;
  addressproof_status: any = "no";
  userbasis_status: any;
  uboid_status: any = "no";
  uboaddress_status: any = "no";
  profimg_status: any = "no";
  ifNext : boolean = false;
  kycButtonEnabled: boolean = false
  userId:any;
  constructor(
    public formBuilder: FormBuilder,
    private userService: UserService,
    private kycSer: KycService,
    private router: Router,
    private notifyService: NotificationService,
    private location: Location,
    private kycService: KycserviceService,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private tradeService: TradingService,
    private storageService: StorageService,
    public translate: TranslateService,
    private http: HttpClient
  ) {
    var userd = this.userService.getUserDetails();
    this.userid = userd._id;
    
  }
  myForm:FormGroup;
  public data: any = [];
  public wallist: any = {};
  twofastatus = "Disabled";

  spacecheck = true;
  ngOnInit() {

    this.myForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.pattern("^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$")]],
      uboEmail: ['', [Validators.email, Validators.pattern("^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$")]],
      address: [''],
      uboAddress: [''],
      name: ['', [Validators.minLength(2), Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      uboName: ['', [Validators.minLength(5), Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9 ]*$')]],
      // profileUpload: ['', [Validators.required]],
      birthDate: [''],
      uboDob: [''],
      phonecode: ['', [Validators.required]],
      uboPhonecode: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      uboPhone: ['', [Validators.required]],
      UBOCheck: ['', [Validators.required]],
      country: ['', [Validators.required]],
      uboCountry: ['', [Validators.required]],
      addressUpload: [''],
      idUpload: [''],
      uboAddrproof: [''],
      uboIdproof: [''],
    });
    this.placeHold = {
      front: "",
      back: "",
      company_reg: "",
      board_member_id: "",
      aoa: "",
      bank_accn: "",
      video_selfie: "",
      source_funds: "",
      appln_questionnaire: "",
      id_proof: "",
      letter_of_auth: "",
      profile_img: "",
      idproof: "",
      addrproof: "",
      ubo_idproof: "",
      ubo_addrproof: "",

      error: {
        front: "",
        back: "",
        company_reg: "",
        board_member_id: "",
        aoa: "",
        bank_accn: "",
        video_selfie: "",
        source_funds: "",
        appln_questionnaire: "",
        id_proof: "",
        letter_of_auth: "",
      },
    };
    
      this.placeHold.company_reg = "Choose File";
      this.placeHold.board_member_id = "Choose File";
      this.placeHold.aoa = "Choose File";
      this.placeHold.bank_accn = "Choose File";
      this.placeHold.source_funds = "Choose File";
      this.placeHold.appln_questionnaire = "Choose File";
      this.placeHold.letter_of_auth = "Choose File";
      this.placeHold.profile_img = "Choose File";
      this.placeHold.idproof = "Choose File";
      this.placeHold.addrproof = "Choose File";
      this.placeHold.ubo_idproof = "Choose File";
      this.placeHold.ubo_addrproof = "Choose File";

      // this.userService.getbasis_id().subscribe((response) => {
      //   this.user = response;
      //   console.log(response, 'basis_id');
      //   this.basis_status = this.user.basis_status;
      //   if (this.user.phone && this.user.dob) {
          
      //   }
      // });
      this.kycService.getKYC().subscribe(
        (res) => {
          this.formkyc = res;
          this.kycstatus = res.status;
          if (this.kycstatus == "Pending") {
            this.submit_view = "yes";
          } else this.submit_view = "no";
          var url = this.S3_BUCKET_URL;
          this.placeHold.company_reg =
            res.company_reg == ""
              ? "Choose File"
              : res.company_reg.replace(url, "");
          this.placeHold.board_member_id =
            res.board_member_id == ""
              ? "Choose File"
              : res.board_member_id.replace(url, "");
          this.placeHold.aoa =
            res.aoa == "" ? "Choose File" : res.aoa.replace(url, "");
          this.placeHold.bank_accn =
            res.bank_accn == ""
              ? "Choose File"
              : res.bank_accn.replace(url, "");
          this.placeHold.source_funds =
            res.source_funds == ""
              ? "Choose File"
              : res.source_funds.replace(url, "");
          this.placeHold.appln_questionnaire =
            res.appln_questionnaire == ""
              ? "Choose File"
              : res.appln_questionnaire.replace(url, "");
          this.placeHold.addn_infor =
            res.addn_infor == "" ? "" : res.addn_infor.replace(url, "");
          if(res.letter_of_auth) {
            this.placeHold.letter_of_auth =
            res.letter_of_auth === ""
              ? "Choose File"
              : res.letter_of_auth.replace(url, "");
          }              
          if (res.status == "Pending") {
            this.submit_view = "yes";
          }
        },
        (err) => {
          if (this.storageService.getLanguage() == "es") {
            this.notifyService.showError("", "KYC-d ei leitud");
          } else {
            this.notifyService.showError("", "No KYC Found");
          }
        }
      );
    
    this.initialfunction();
    this.csrf();
    this.tradeService.startLoader();
    this.data = {};
    this.data.test = "test";
    this.csrf();
    this.getcountry();
    this.diables = true;
    this.get_hyperverge_access_token();
    this.getHypervergeStatus()
    this.tradeService.stopLoader();
    // this.socket.on("kycstatus" + this.userid, (data) => {
    //   this.kycService.getUserKYC().subscribe(
    //     (res) => {
    //       this.kycstatus = res.status;
    //       if (this.kycstatus == "Pending") {
    //         this.submit_view = "yes";
    //       } else {
    //         this.submit_view = "no";
    //       }
    //     },
    //     (err) => {
    //       this.kycstatus = "Pending";
    //     }
    //   );
    // });
  }
  initialfunction() {
    this.getProfile();
    this.userService.getAntiPhisCode(this.antiphis._csrf).subscribe((data) => {
      var phiscode = data.result;
      this.antiphis.antiphiscode = phiscode.antiphiscode;
    });
  }
  
  isFormDisabled(): boolean {
    return this.myForm.invalid;
  }
  handleFileInput(files) {
    this.fileToUpload = files.item(0);
  }
  csrf() {
    this.userService.csrf().subscribe(
      (response) => {
        this.profile._csrf = response._csrf;
        this.antiphis._csrf = response._csrf;
        this.formkyc._csrf = response._csrf;
      },
      (err) => {}
    );
  }
  userList:any;
  getclearValidators(fieldName) {
    this.myForm.controls[fieldName].clearValidators();
    this.myForm.controls[fieldName].updateValueAndValidity();
  }
  setValidators(fieldName) {
    this.myForm.controls[fieldName].setValidators(Validators.required)
    this.myForm.controls[fieldName].updateValueAndValidity();
  }
  getProfile() {    
    this.userService.getProfileAPI().subscribe(
      (response) => {
        if(response && response.dob) this.getclearValidators('birthDate')
        else this.setValidators('birthDate')  

        if(response && response.dob) this.getclearValidators('uboDob')
        else this.setValidators('uboDob') 

        if(response && response.full_phone) this.getclearValidators('uboPhone')
        else this.setValidators('uboPhone') 

        if(response && response.email) this.getclearValidators('email') 
        else this.setValidators('email')

        if(response && response.full_phone) this.getclearValidators('address') 
        else this.setValidators('address')

        if(response && response.full_phone) this.getclearValidators('phone') 
        else this.setValidators('phone')

        if(response && response.name) this.getclearValidators('name') 
        else this.setValidators('name')

        if(response && response.ubo[0] && response.ubo[0].residency) this.getclearValidators('uboAddress');
        else this.setValidators('uboAddress')

        if(response && response.ubo[0] && response.ubo[0].email) this.getclearValidators('uboEmail');
        else this.setValidators('uboEmail')

        if(response && response.ubo[0] && response.ubo[0].full_name) this.getclearValidators('uboName');
        else this.setValidators('uboName')

        if(response && response.id_proof) this.getclearValidators('idUpload') 
        else this.setValidators('idUpload');

        if(response && response.address_proof) this.getclearValidators('addressUpload')
        else this.setValidators('addressUpload')

        if(response && response.ubo[0] && response.ubo[0].address_proof) this.getclearValidators('uboAddrproof');
        else this.setValidators('uboAddrproof');

        if(response && response.ubo[0] && response.ubo[0].id_proof) this.getclearValidators('uboIdproof') 
        else this.setValidators('uboIdproof')

        if (response.tfa_status == 0) {
          this.twofastatus = "Disabled";
        } else {
          this.twofastatus = "Enabled";
        }
        if (response.id_proof != "" && response.id_proof !== "null") {
          this.idproof_status = "yes";
        }
        if (response.address_proof != "" && response.address_proof !== "null") {
          this.addressproof_status = "yes";
        }
        if (response.profileimage) {
          this.profimg_status = "yes";
        }
        this.userbasis_status = response.basis_status;
        this.basisid_status = "yes";
        this.profile.hyperverge_status = response.hyperverge_status;
        this.profile.profileimage = response.profileimage; //for send api
        this.profile.id_proof = response.id_proof; //for display front end
        this.profile["Email"] = response.email ? response.email : "";
        this.profile["userId"] = response.userid
          ? response.userid
          : 10000 + +response.userid;
        this.profile.email = response.email ? response.email : "";
        this.profile.country = response.country ? response.country : "";
        // this.profile.country = this.profile.country.split(" ", 1).toString();
        this.profile.countrycode = response.countrycode
          ? response.countrycode
          : "4";
        this.profile.nickname = response.nickname ? response.nickname : "";
        this.profile.state = response.state ? response.state : "Tamilnadu";
        this.profile.address = response.address ? response.address : "";
        this.profile.legalname = response.name ? response.name : "";
        this.profile.name = response.name ? response.name : "";
        this.profile.pin = response.pin ? response.pin : "";
        this.profile.language = response.language ? response.language : "";
        this.profile.timezone = response.timezone ? response.timezone : "";
        this.profile.phonecode = response.phonecode ? response.phonecode : "";
        this.profile.phone = response.phone ? response.phone : "";
        this.profile.userid = response.userid
          ? response.userid
          : 10000 + +response.userid;
        this.profile.user_id = response._id ? response._id : "";
        if (response.dob) {
          this.value = new Date(moment(response.dob, 'YYYY-MM-DD').format('MM/DD/YYYY'));
          this.profile.dob = response.dob;
        }else{
          this.value = new Date(moment().format('MM/DD/YYYY'));
          this.profile.dob = moment().format('YYYY-MM-DD');
        }
        var ubo_details = response.ubo[0];
        if (ubo_details) {
          this.profile.ubo_email = ubo_details.email ? ubo_details.email : "";
          this.profile.ubo_country = ubo_details.citizenship
            ? ubo_details.citizenship
            : "";
          this.profile.ubo_name = ubo_details.full_name
            ? ubo_details.full_name
            : "";
          this.profile.ubo_address = ubo_details.residency
            ? ubo_details.residency
            : "";
          this.profile.ubo_phonecode = ubo_details.phonecode
            ? ubo_details.phonecode
            : "";
          this.profile.ubo_phone = ubo_details.mobile_number
            ? ubo_details.mobile_number
            : "";
          this.profile.ubo_idproof = ubo_details.id_proof
            ? ubo_details.id_proof
            : "";
          this.profile.ubo_addressproof = ubo_details.address_proof
            ? ubo_details.address_proof
            : "";
          if (ubo_details.dob) {
            this.value_ubo = new Date(moment(ubo_details.dob, 'YYYY-MM-DD').format('MM/DD/YYYY'));
            this.profile.ubo_dob = ubo_details.dob;
          } else {
            this.value_ubo = new Date(moment().format('MM/DD/YYYY'));
            this.profile.ubo_dob = moment().format('YYYY-MM-DD');
          }
          this.profile.ubo_check = response.ubo_check ? response.ubo_check : 1;
          this.profile.address_proof = response.address_proof;
          if (ubo_details.id_proof != "" && ubo_details.id_proof !== "null") {
            this.uboid_status = "yes";
          }
          if (
            ubo_details.address_proof != "" &&
            ubo_details.address_proof !== "null"
          ) {
            this.uboaddress_status = "yes";
          }
        } else {
          this.value_ubo = new Date(moment().format('MM/DD/YYYY'));
        }
        var url = this.S3_BUCKET_URL;
        this.placeHold.profile_img = response.profileimage ? response.profileimage.replace(url, "") : ''; 
        this.placeHold.idproof =
          this.idproof_status === "no"
            ? "Choose File"
            : response.id_proof ? response.id_proof.replace(url, "") : '';
        this.placeHold.addrproof =
          this.addressproof_status === "no"
            ? "Choose File"
            : response.address_proof ? response.address_proof.replace(url, "") : '';
        this.placeHold.ubo_idproof =
          this.uboid_status === "no"
            ? "Choose File"
            : ubo_details.id_proof ? ubo_details.id_proof.replace(url, "") : '';
        this.placeHold.ubo_addrproof =
          this.uboaddress_status === "no"
            ? "Choose File"
            : ubo_details.address_proof ? ubo_details.address_proof.replace(url, "") : '';
        var req = {
          id: response.countrycode,
          _csrf: this.profile._csrf,
        };
        if (this.profile.state == "") {
          this.userService.getState(req).subscribe(
            (res) => {
              this.states = res;
            },
            (err) => {
              console.log(err);
            }
          );
        }
      },
      (err) => {
        this.notifyService.showError("", err.error.message);
      }
    );
  }

  sort_by(field, reverse, primer) {
    var key = primer
      ? function (x) {
          return primer(x[field]);
        }
      : function (x) {
          return x[field];
        };
    reverse = [-1, 1][+!!reverse];
    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * (+(a > b) - +(b > a));
    };
  }

  getcountry() {
    this.userService.country().subscribe(
      (res) => {
        var arr = res.sort(this.sort_by("phonecode", true, parseInt));
        var result = arr.reduce((unique, o) => {
          if (!unique.some((obj) => obj.phonecode === o.phonecode)) {
            unique.push(o);
          }
          return unique;
        }, []);
        this.phonecodelist = result;
      },
      (err) => {
        console.log(err);
      }
    );
    this.userService.country().subscribe(
      (res) => {
        this.countries = res;
        this.countries.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }
  updateProfile() {
    var req = this.profile;
    this.user_country = this.countries.filter(
      (x) => x.phonecode == this.profile.phonecode
    );
    req.country = this.profile.country;
   
    this.submit = true;
    req.name = req.legalname;
    var profilename = req.legalname;
    
    if (this.profile.email && this.profile.legalname) {     
      this.userService.updateprofile(req).subscribe(
        (res) => {
          if (res.status) {
            localStorage.setItem("username", this.profile.legalname.toString());
            $(".profile_name")
              .find("h6")
              .html(this.profile.legalname.toString());
            if (this.value_db) {
            } else {
              this.value = new Date(
                this.value.getFullYear(),
                this.value.getMonth(),
                this.value.getDate(),
                0, 0, 0, 0
              );
            }

            if (this.value_ubo_db) {
            } else {
              this.value_ubo = new Date(
                this.value_ubo.getFullYear(),
                this.value_ubo.getMonth(),
                this.value_ubo.getDate(),
                0, 0, 0, 0
              );
            }
            this.get_user_details();
            if (this.profile.country === 'India' && this.kycButtonEnabled === false) {
              setTimeout(() => {
                this.startOnboarding();
              }, 2000); 
            }
            if (this.storageService.getLanguage() == "es") {
              this.notifyService.showSuccess("", res.messagees);
            } else {
              this.notifyService.showSuccess("", res.message);
            }
           
          } else {
            if (this.storageService.getLanguage() == "es") {
              this.notifyService.showError("", res.messagees);
            } else {
              this.notifyService.showError("", res.message);
            }
          }
        },
        (err) => {
          this.notifyService.showError("", err.error.message);
        }
      );
    }
  }
  ngAfterViewInit() {
    if (this.profile.country === 'India' && this.kycButtonEnabled === false) {
      this.startOnboarding();
    }
  }
  clearError(field) {
    switch (field) {
      case "password":
        this.errorObj.password = "";
        break;
    }
  }
  checkpassword(val) {
    var strongRegex = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$",
      "g"
    );
    if (this.profile.password === "") {
      this.clearError("password");
    } else if (/\s/.test(val)) {
      this.errorObj.password = "Do not use whitespace";
    } else if (strongRegex.test(val)) {
      // this.errorObj.password = 'Password is Strong';
    } else {
      this.errorObj.password = "Password is Weak";
    }
  }
  MatchPassName(str, substr) {
    var lowstr = str.toLowerCase().trim();
    var lowsubstr = substr.toLowerCase();
    var lowstrArr = lowstr.split(" ");
    var PassErroArr = [];
    if (lowstr !== "" && lowsubstr !== "") {
      lowstrArr.forEach(function (item) {
        if (lowsubstr.search("^.*" + item + ".*$") > -1) {
          PassErroArr.push(item);
        }
      });
      if (PassErroArr.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  validateNumber(e: any) {
    var input = e.target.value;
    if (input.length > 0) {
      if (typeof input != "undefined" && input.length >= 6) {
        e.preventDefault();
      }
    }
  }

  getdate() {
    if (this.profile.month != "" && this.profile.year != "") {
      var count = this.getNumberOfDays(this.profile.year, this.profile.month);
      this.dateList = Array.from(Array(count), (_, i) => i + 1);
    }
  }

  getNumberOfDays(year, month) {
    var isLeap = year % 4 == 0 && (year % 100 != 0 || year % 400 == 0);
    return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][
      month - 1
    ];
  }
  sendotp(mobileno) {
    var req = {
      no: "9659167747",
      _csrf: this.profile._csrf,
    };
    this.userService.sendotp().subscribe(
      (res) => {
        this.states = res;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  redirectforget() {
    this.router.navigate(["forgot-password"]);
  }
  back() {
    this.location.back();
  }

  omit_special_char(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
  checkspacefn(char) {
    if (/\s/.test(char)) {
      this.spacecheck = false;
    } else {
      this.spacecheck = true;
    }
  }
  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  public onChange(files, key): void {
    var path = files[0].type;
    var filezsize: any = "";
    var maxdocsize: any = "";
    var maxvidsize: any = "";
    if (path == "image/jpeg") {
      filezsize = files[0].size / 1000;
      maxdocsize = "200.00"; //kb
    }
    if (path == "video/mp4") {
      filezsize = files[0].size / 1e6;
      maxvidsize = 3; //mb
    }
    this.fileToUpload = files.item(0);
    if (filezsize > maxdocsize && path == "image/jpeg") {
      if (this.storageService.getLanguage() == "es") {
        this.notifyService.showError("", "Pildi suuruse limiit on ületatud");
      } else {
        this.notifyService.showError("", "Image size limit exceeded");
      }
      return;
    }
    if (filezsize > maxvidsize && path == "video/mp4") {
      if (this.storageService.getLanguage() == "es") {
        this.notifyService.showError("", "Video suuruse limiit on ületatud");
      } else {
        this.notifyService.showError("", "Video size limit exceeded");
      }

      return;
    }
    if (key == "front" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }

        return;
      }
    }
    if (key == "back" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        this.notifyService.showError(
          "",
          "Only Upload jpeg Image Format Or PDF Format"
        );
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (key == "company_reg" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (key == "board_member_id" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (key == "aoa" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (key == "bank_accn" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (key == "video_selfie" && path !== "video/mp4") {
      if (this.storageService.getLanguage() == "es") {
        this.notifyService.showError("", "Laadige üles ainult videofail");
      } else {
        this.notifyService.showError("", "Only Upload Video File");
      }

      return;
    }
    if (key == "source_funds" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (key == "appln_questionnaire" && path !== "application/pdf") {
      if (path !== "image/jpeg") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (key == "letter_of_auth" && path !== "application/pdf") {
      if (path !== "image/jpeg") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    this.userService.csrf().subscribe((response) => {
      this.userService
        .uploadDocn(this.fileToUpload, key, response._csrf)
        .subscribe(
          (data) => {
            var result = data.result.secure_url;
            var url = this.S3_BUCKET_URL;
            var filename = result.replace(url, "");
            if (key == "company_reg") {
              this.formkyc["company_reg"] = result;
              this.placeHold.company_reg = filename;
              this.placeHold.error.company_reg = "";
            }
            if (key == "board_member_id") {
              this.formkyc["board_member_id"] = result;
              this.placeHold.board_member_id = filename;
              this.placeHold.error.board_member_id = "";
            }
            if (key == "aoa") {
              this.formkyc["aoa"] = result;
              this.placeHold.aoa = filename;
              this.placeHold.error.aoa = "";
            }
            if (key == "bank_accn") {
              this.formkyc["bank_accn"] = result;
              this.placeHold.bank_accn = filename;
              this.placeHold.error.bank_accn = "";
            }
            if (key == "video_selfie") {
              this.formkyc["video_selfie"] = result;
              this.placeHold.video_selfie = filename;
              this.placeHold.error.video_selfie = "";
            }
            if (key == "source_funds") {
              this.formkyc["source_funds"] = result;
              this.placeHold.source_funds = filename;
              this.placeHold.error.source_funds = "";
            }
            if (key == "appln_questionnaire") {
              this.formkyc["appln_questionnaire"] = result;
              this.placeHold.appln_questionnaire = filename;
              this.placeHold.error.appln_questionnaire = "";
            }
            if (key == "addn_infor") {
              this.formkyc["addn_infor"] = result;
              this.placeHold.addn_infor = filename;
              this.placeHold.error.addn_infor = "";
            }
            if (key == "letter_of_auth") {
              this.formkyc["letter_of_auth"] = result;
              this.placeHold.letter_of_auth = filename;
              this.placeHold.error.letter_of_auth = "";
            }
            if (this.storageService.getLanguage() == "es") {
              this.notifyService.showSuccess("", data.messagees);
            } else {
              this.notifyService.showSuccess("", data.message);
            }
          },
          (err) => {
            this.notifyService.showError("", err.error.message);
          }
        );
    });
  }

  kycSave(kyc : NgForm) {
    this.submit = true;
    if (JSON.stringify(this.placeHold).search("Choose File") == -1) {
      try {
        this.userService.csrf().subscribe((response) => {
          this.formkyc._csrf = response._csrf;
          this.userService.kycSave(this.formkyc).subscribe(
            (kycResponse) => {
              if (kycResponse.status == 200) {
                if (this.storageService.getLanguage() == "es") {
                  this.notifyService.showSuccess("", kycResponse.messagees);
                } else {
                  this.notifyService.showSuccess("", kycResponse.message);
                }

                setTimeout(() => {
                  this.submit_view = "yes";
                }, 1000);
              }
            },
            (err) => {
              this.notifyService.showError("", err.error.message);
            }
          );
        });
      } catch (error) {
        console.log(error, 'error');
      }
    } else {
      if (this.formkyc.pan == "") {
        if (this.storageService.getLanguage() == "es") {
          this.placeHold.error.pan = "Pann on nõutav";
        } else {
          this.placeHold.error.pan = "Pan is required";
        }
      }
      if (this.formkyc.company_reg == "") {
        if (this.storageService.getLanguage() == "es") {
          this.placeHold.error.company_reg =
            "Vajalik on isikut tõendava dokumendiga selfi";
        } else {
          this.placeHold.error.company_reg = "Selfie with ID Proof is required";
        }
      }
      if (this.formkyc.board_member_id == "") {
        if (this.storageService.getLanguage() == "es") {
          this.placeHold.error.board_member_id =
            "Nõutav on juhatuse liikme isikutunnistus";
        } else {
          this.placeHold.error.board_member_id =
            "Board Member National id Proof is required";
        }
      }
      if (this.formkyc.aoa == "") {
        if (this.storageService.getLanguage() == "es") {
          this.placeHold.error.aoa = "AOA on nõutav";
        } else {
          this.placeHold.error.aoa = "AOA is required";
        }
      }
      if (this.formkyc.bank_accn == "") {
        if (this.storageService.getLanguage() == "es") {
          this.placeHold.error.bank_accn = "Pangakonto on nõutav";
        } else {
          this.placeHold.error.bank_accn = "Bank Account is required";
        }
      }
      if (this.formkyc.source_funds == "") {
        if (this.storageService.getLanguage() == "es") {
          this.placeHold.error.source_funds = "Vaja on allikavahendeid";
        } else {
          this.placeHold.error.source_funds = "Source Funds is required";
        }
      }

      if (this.formkyc.letter_of_auth == "") {
        if (this.storageService.getLanguage() == "es") {
          this.placeHold.error.letter_of_auth = "Vaja on volikirja";
        } else {
          this.placeHold.error.letter_of_auth =
            "Letter of authorizaion is required";
        }
      }
      if (this.formkyc.appln_questionnaire == "") {
        if (this.storageService.getLanguage() == "es") {
          this.placeHold.error.appln_questionnaire =
            "Taotluse küsimustik on nõutav";
        } else {
          this.placeHold.error.appln_questionnaire =
            "Application Quesstionnaire is required";
        }
      }
    }
  }

  savedraftKyc() {
    this.submit = true;
    if (
      this.formkyc.company_reg == "" &&
      this.formkyc.board_member_id == "" &&
      this.formkyc.aoa == "" &&
      this.formkyc.bank_accn == "" &&
      this.formkyc.source_funds == "" &&
      this.formkyc.appln_questionnaire == "" &&
      this.formkyc.letter_of_auth == ""
    ) {
      if (this.storageService.getLanguage() == "es") {
        this.notifyService.showError(
          "",
          "Salvestamiseks laadige üles mõni tõend"
        );
      } else {
        this.notifyService.showError("", "Please upload any one proof to save");
      }
    } else {
      this.userService.csrf().subscribe((response) => {
        this.formkyc._csrf = response._csrf;
        this.userService.savedraftKyc(this.formkyc).subscribe(
          (response) => {
            if (response.status == 200) {
              if (this.storageService.getLanguage() == "es") {
                this.notifyService.showSuccess("", response.messagees);
              } else {
                this.notifyService.showSuccess("", response.message);
              }

              this.kycService.getKYC().subscribe((res) => {
                this.formkyc = res;
                this.kycstatus = res.status;
              });
            }
          },
          (err) => {
            this.notifyService.showError("", err.error.message);
          }
        );
      });
    }
  }
  handleIfNext(){
    this.ifNext = true;
  }
  sumsubData() {
    this.userService.ApplicantDataFromSumsub(this.sumsubForm.userId).subscribe((res: any) => {
      if (res !== 'undefined') {
        const sumsubData = {
          userId: res.externalUserId,
          status: res.review.reviewStatus,
          _csrf: this.sumsubForm._csrf
        };
        
        this.userService.saveApplicantData(sumsubData).subscribe((res: any) => {
          if (res) this.thirdStepMsg = res.message
          if (this.sumsubStatus === 'Pending')
            return this.thirdStepMsg = 'Your documents have submitted please wait for the approval'
          else if (this.sumsubStatus === 'Approved')
            return this.thirdStepMsg = res.message
        })
      }
    })
  }
  get_user_details() {    
    this.userService.csrf().subscribe((response) => {
      this.sumsubForm._csrf = response._csrf
      this.userService.getbasis_id().subscribe((response) => {
        this.applicantEmail = response.email;
        this.applicantPhone = response.phone;
        this.sumsubForm.userId = response._id;
        
      if(this.profile.country !== 'India') {
        this.userService.getSumsubAccessToken(this.sumsubForm).subscribe((res: any) => {        
          this.token = res.token;
          this.launchWebSdk(this.token, this.applicantEmail, this.applicantPhone);         
        })
    
        this.sumsubData();
      }
      })
      
    })    
  }
  get_hyperverge_access_token() {
    this.userService.get_hyperverge_access_token().subscribe((res: any) => {        
      this.hyperverge_token = res.result.token;
      console.log(this.hyperverge_token, 'get_hyperverge_access_token');
    })
  }
  enterStep(event) {
    if (this.thirdStepMsg)
      this.notifyService.showSuccess("", this.thirdStepMsg);
  }


   launchWebSdk(accessToken, applicantEmail, applicantPhone) {
      let snsWebSdkInstance = snsWebSdk.init(
              accessToken,
              () => this.getNewAccessToken()
          )
          .withConf({
              lang: 'en',
              email: applicantEmail,
              phone: applicantPhone,
              i18n: {"document":{"subTitles":{"IDENTITY": "Upload a document that proves your identity"}}},
              onMessage: (type, payload) => {
              },
              uiConf: {
                  customCssStr: ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}"
              },
              onError: (error) => {
              },
          })
          .withOptions({ addViewportTag: false, adaptIframeHeight: true})
          .on('stepCompleted', (payload) => {
          })
          .on('onError', (error) => {
          })
          .onMessage((type, payload) => {
            console.log(payload, 'payload');
            if(payload && payload?.reviewStatus === 'pending') this.sumsubStatus = 'Pending'
            if(payload && payload?.reviewResult?.reviewAnswer === 'GREEN') this.sumsubStatus = 'Approved'
            if(payload && payload?.reviewResult?.reviewAnswer === 'RED') this.sumsubStatus = 'Rejected'
            if(payload && ((payload?.reviewStatus === 'pending' || payload?.reviewStatus === 'prechecked') && payload?.reviewResult?.reviewAnswer === 'RED')) this.sumsubStatus = 'Re-Submitted';
           
          })
          .build();
      snsWebSdkInstance.launch('#sumsub-websdk-container')
  }
  getSumsubStatus() {
    return this.sumsubStatus == 'Pending' ||
      this.sumsubStatus == 'Re-Submitted' ||
      this.sumsubStatus == 'Approved'
  }
   getNewAccessToken() {
    return Promise.resolve(this.token)
  }
  get_basis_id() {
    this.userService.kycUpdation().subscribe((res: any) => {
      if (res.status == true) {
        this.get_user_details();
      }
    });
  }

 

  open(content) {
    if (
      this.user.phone == "" ||
      this.user.phone == undefined ||
      this.user.phone == null ||
      this.user.dob == "" ||
      this.user.dob == undefined ||
      this.user.dob == null
    ) {
      if (this.storageService.getLanguage() == "es") {
        this.notifyService.showError("", "Palun uuenda oma profiili");
      } else {
        this.notifyService.showError("", "Please update your profile");
      }

      this.router.navigateByUrl("/profile");
    } else {
      this.basisid_status = "yes";
      this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }
  

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  public onprofileChange(files, key): void {
    var path = files[0].type;
    var filezsize: any = "";
    var maxdocsize: any = "";
    var maxvidsize: any = "";
    if (path == "image/jpeg") {
      filezsize = files[0].size / 1000;
      maxdocsize = "200.00"; //kb
    }
    this.fileToUpload = files.item(0);
    if (key == "id_proof" && filezsize > maxdocsize && path == "image/jpeg") {
      if (this.storageService.getLanguage() == "es") {
        this.notifyService.showError(
          "",
          "ID tõendi suuruse limiit on ületatud, lubatud faili suurus on 200 kb"
        );
      } else {
        this.notifyService.showError(
          "",
          "ID Proof size limit exceeded, allowed file size is 200kb"
        );
      }

      return;
    }
    if (key == "id_proof" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }

        return;
      }
    }
    if (
      key == "ubo_idproof" &&
      filezsize > maxdocsize &&
      path == "image/jpeg"
    ) {
      if (this.storageService.getLanguage() == "es") {
        this.notifyService.showError(
          "",
          "ID tõendi suuruse limiit on ületatud, lubatud faili suurus on 200 kb"
        );
      } else {
        this.notifyService.showError(
          "",
          "ID Proof size limit exceeded, allowed file size is 200kb"
        );
      }
      return;
    }
    if (key == "ubo_idproof" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (
      key == "ubo_addressproof" &&
      filezsize > maxdocsize &&
      path == "image/jpeg"
    ) {
      if (this.storageService.getLanguage() == "es") {
        this.notifyService.showError(
          "",
          "Aadressi tõendi suuruse limiit on ületatud, lubatud faili suurus on 200 kb"
        );
      } else {
        this.notifyService.showError(
          "",
          "Address Proof size limit exceeded, allowed file size is 200kb"
        );
      }
      return;
    }
    if (key == "ubo_addressproof" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (
      key == "address_proof" &&
      filezsize > maxdocsize &&
      path == "image/jpeg"
    ) {
      if (this.storageService.getLanguage() == "es") {
        this.notifyService.showError(
          "",
          "Aadressi tõendi suuruse limiit on ületatud, lubatud faili suurus on 200 kb"
        );
      } else {
        this.notifyService.showError(
          "",
          "Address Proof size limit exceeded, allowed file size is 200kb"
        );
      }
      return;
    }
    if (key == "address_proof" && path !== "image/jpeg") {
      if (path !== "application/pdf") {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg-vormingus või PDF-vormingus"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg Image Format Or PDF Format"
          );
        }
        return;
      }
    }
    if (key == "profile_pic") {
      if (!this.isImage(this.fileToUpload)) {
        if (this.storageService.getLanguage() == "es") {
          this.notifyService.showError(
            "",
            "Laadige üles ainult jpeg, jpg ja png pildivormingud"
          );
        } else {
          this.notifyService.showError(
            "",
            "Only Upload jpeg, jpg and png Image Formats"
          );
        }

        return;
      }
    }
    this.userService.csrf().subscribe((response) => {
      this.userService
        .uploadDocn(this.fileToUpload, key, response._csrf)
        .subscribe(
          (data) => {
            var result = data.result.secure_url;
            var url = this.S3_BUCKET_URL;
            var filename = result.replace(url, "");
            if (key == "profile_pic") {
              this.profile.profileimage = result;
              this.placeHold.profile_img = filename;
            }
            if (key == "id_proof") {
              this.profile.id_proof = result;
              this.idproof_status = "yes";
              this.placeHold.idproof = filename;
            }
            if (key == "ubo_idproof") {
              this.profile.ubo_idproof = result;
              this.placeHold.ubo_idproof = filename;
            }
            if (key == "ubo_addressproof") {
              this.profile.ubo_addressproof = result;
              this.placeHold.ubo_addrproof = filename;
            }
            if (key == "address_proof") {
              this.profile.address_proof = result;
              this.addressproof_status = "yes";
              this.placeHold.addrproof = filename;
            }
            if (this.storageService.getLanguage() == "es") {
              this.notifyService.showSuccess("", data.messagees);
            } else {
              this.notifyService.showSuccess("", data.message);
            }
          },
          (err) => {
            this.notifyService.showError("", err.error.message);
          }
        );
    });
  }

  public ondateChange(value: Date): void {
    this.value = new Date(
      value.getUTCFullYear(),
      value.getUTCMonth(),
      value.getUTCDate() + 1,
      0, 0, 0, 0
    );
    this.value_db = new Date(
      value.getFullYear(),
      value.getMonth(),
      value.getDate() + 1,
      0, 0, 0, 0
    ); // for live
    this.profile.dob = moment(value).format('YYYY-MM-DD')
  }

  public ondateuboChange(value: Date): void {
    this.value_ubo = new Date(
      value.getUTCFullYear(),
      value.getUTCMonth(),
      value.getUTCDate() + 1,
      0, 0, 0, 0
    );
    this.value_ubo_db = new Date(
      value.getFullYear(),
      value.getMonth(),
      value.getDate() + 1,
      0, 0, 0, 0
    ); // for live
    this.profile.ubo_dob = moment(value).format('YYYY-MM-DD')

  }

  checkUBO(event) {    
    if (event.checked == true) {
      this.myForm.controls['uboAddrproof'].clearValidators()
      this.myForm.controls['uboAddrproof'].updateValueAndValidity()
      this.myForm.controls['uboIdproof'].clearValidators()
      this.myForm.controls['uboIdproof'].updateValueAndValidity()
      this.profile.ubo_check = 1;
      this.profile.ubo_name = this.profile.name;
      this.value_ubo = this.value;
      this.profile.ubo_dob = moment(this.value_ubo).format('YYYY-MM-DD');
      this.profile.ubo_address = this.profile.address;
      this.profile.ubo_country = this.profile.country;
      this.profile.ubo_phone = this.profile.phone;
      this.profile.ubo_phonecode = this.profile.phonecode;
      this.profile.ubo_email = this.profile.email;
      this.profile.ubo_idproof = this.profile.id_proof;
      this.profile.ubo_addressproof = this.profile.address_proof;
      var url = this.S3_BUCKET_URL;
      this.placeHold.ubo_idproof = this.profile.ubo_idproof.replace(url, "");
      this.placeHold.ubo_addrproof = this.profile.ubo_addressproof.replace(
        url,
        ""
      );
      this.value_ubo_db = this.value_db;
    } else {
      this.myForm.controls['uboAddrproof'].setValidators(Validators.required)
      this.myForm.controls['uboAddrproof'].updateValueAndValidity()
      this.myForm.controls['uboIdproof'].setValidators(Validators.required)
      this.myForm.controls['uboIdproof'].updateValueAndValidity()
      this.profile.ubo_check = 0;
      this.profile.ubo_name = "";
      this.profile.ubo_dob = moment(this.value_ubo).format('YYYY-MM-DD');
      this.profile.ubo_address = "";
      this.profile.ubo_country = "";
      this.profile.ubo_phone = 0;
      this.profile.ubo_phonecode = "";
      this.profile.ubo_email = "";
      this.profile.ubo_idproof = "";
      this.profile.ubo_addressproof = "";
      this.placeHold.ubo_idproof = "Choose File";
      this.placeHold.ubo_addrproof = "Choose File";
    }
  }
  confirm_selfie() {
    if (this.storageService.getLanguage() == "es") {
      if (window.confirm("Kas soovite kindlasti sulgeda ?")) {
        this.modalService.dismissAll();
        this.notifyService.showSuccess(
          "",
          "Teie dokumendid on edukalt edastatud, oodake kinnitamist"
        );
      }
    } else {
      if (window.confirm("Are you sure want to close ?")) {
        this.modalService.dismissAll();
        this.notifyService.showSuccess(
          "",
          "Your documents submiited successfully, Please wait for approval"
        );
      }
    }
  }
  // HYPERVERGE KYC STARTS 
  startOnboarding(): void {
    this.startOnboardingProcess = true;
    console.log('startOnboarding clicked........');
    const sdkContainer = document.getElementById('hyperverge-sdk-container');
    
    if (!sdkContainer) {
      console.error('Hyperverge SDK container not found.');
      return;
    }
  
    const hyperKycConfig = new HyperKycConfig(
      this.hyperverge_token, 
      environment.VITE_WORKFLOW_ID,
      this.profile.user_id  
    );
    
    try {
      HyperKYCModule.launch(hyperKycConfig, this.hyperKycHandler.bind(this));
  
      console.log('Hyperverge KYC SDK launched successfully.');
  
    } catch (error) {
      console.error('Error launching Hyperverge KYC SDK:', error);
      this.notifyService.showError("", 'KYC process initialization failed.');
    }
  }
  

  // Handler function to process the result
  hyperKycHandler(HyperKycResult: any): void {
    console.log(HyperKycResult, 'HyperKycResult')
    // hyperverge webhook subscription
    this.userService.get_hyperverge_webhook_subscription().subscribe((res: any) => {        
      console.log(res, 'get_hyperverge_webhook_subscription');
    })
    
    if (HyperKycResult) {
      this.isProcessing = true;
      setTimeout(() => {
        this.retryGetUserDetails();
        this.isProcessing = false

      }, 20000);
    }
    this.hypervergeFinalKYCStatus = HyperKycResult?.status

    if(HyperKycResult?.status === 'user_cancelled' || 
      HyperKycResult?.status === 'auto_declined' || 
      HyperKycResult?.status === 'manually_declined'
    ) {
      this.hypervergeNotApproveStatus = true;
      setTimeout(() => {
        let userConfirmed = confirm(`Your KYC was ${HyperKycResult?.status}. Would you like to reload the page?`);
        if (userConfirmed) {
          window.location.reload();
        }
      }, 2000);
    }
    if(HyperKycResult?.status === 'needs_review' || 
      HyperKycResult?.status === 'auto_approved' || 
      HyperKycResult?.status === 'manually_approved'
    ) {
      this.hypervergeApproveStatus = true;
    }
    if (HyperKycResult?.status === 'user_cancelled') {
      console.log(HyperKycResult);
    } else if (HyperKycResult?.status === 'auto_declined') {
      console.log(HyperKycResult);
    } else if (HyperKycResult?.status === 'auto_approved') {
      console.log(HyperKycResult);
    }
  }
  retryGetUserDetails(): void {
    this.get_user_details();
    this.getHypervergeStatus();
  }
  getHypervergeStatus() {
    this.kycButtonEnabled = this.profile.hyperverge_status === 'auto_approved' ||
    this.profile.hyperverge_status === 'manually_approved' ||
    this.profile.hyperverge_status === 'needs_review'
    return this.kycButtonEnabled;
  }
  convertToCamelCaseWithSpaces = (text: any) => {
    if (!text) return '';

    return text
      .replace(/_/g, ' ')
      .replace(/\b\w/g, (char: any) => char.toUpperCase())
      .trim();
  }
}