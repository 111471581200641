import { Component, OnInit, OnDestroy  } from '@angular/core';
import { NgwWowService } from 'ngx-wow';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription }   from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
declare var $: any;
import { UserService} from "../services/user.service";
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.css']
})
export class HomeHeaderComponent implements OnInit, OnDestroy  {

  private wowSubscription: Subscription;
  currentlang = "";
  LANG:any = 'ENG'
  constructor(private router: Router, private wowService: NgwWowService,
              private userService:UserService,
              private translate: TranslateService,
              private storageService : StorageService){
    this.router.events.filter(event => event instanceof NavigationEnd).subscribe(event => {
      // Reload WoW animations when done navigating to page,
      // but you are free to call it whenever/wherever you like
      this.wowService.init(); 
    });
    var lang_storage = this.storageService.getLanguage()
    if(lang_storage == 'en'){
      this.storageService.setLanguage('en');
      this.LANG = 'ENG'
    }
    if(lang_storage == 'es'){
      this.storageService.setLanguage('es');
      this.LANG = 'EST'
    }
    }
  isloggedin:any=false;
  routenav:any;
  routename:any;
  ngOnInit(): void {
    if (this.userService.isLoggedIn()) {
      this.isloggedin=true;
      this.routenav = '/kyc';
      this.routename = 'Page.Account'
    }else{
      this.isloggedin=false;
      this.routenav = '/login';
      this.routename = 'HomePage.Login'
    }
     // you can subscribe to WOW observable to react when an element is revealed
     this.wowSubscription = this.wowService.itemRevealed$.subscribe(
      (item:HTMLElement) => {
        // do whatever you want with revealed element
      });
      $(document).ready(function(){
          $(".animate_scroll").click(function() {
              var target = $(this).attr('data-target');
                $('html, body').animate({
                    scrollTop: $("#"+target).offset().top
                }, 500);
            });
      });
    
       if(this.storageService.getLanguage()=='es'){
        this.currentlang = 'es'
       }else{
        this.currentlang = 'en'
       }
  }

  ngOnDestroy() {
    // unsubscribe (if necessary) to WOW observable to prevent memory leaks
    this.wowSubscription.unsubscribe();
  }
  changeLang(language: string) {
    this.storageService.setLanguage(language)
    this.translate.use(language);
    if(language=='es'){
      this.currentlang = 'es'
      this.LANG = 'EST'
      this.ngOnInit();
    }else{
      this.currentlang =  language;
      this.ngOnInit();
      this.LANG = 'ENG'
    }
  }

}
