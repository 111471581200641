import {EventEmitter, Injectable, Output} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {UserService} from "./user.service";

@Injectable({
    providedIn: 'root'
})

export class ReportService {

    constructor(private userService: UserService) {
    }
    @Output() tradeprice: EventEmitter<boolean> = new EventEmitter();
    toggleTradeChangePrice(data){
        this.tradeprice.emit(data);
    }
    public getOrderTradeReport(searchOption): Observable<any> {
      var token = localStorage.getItem('mean-token')
      return this.userService.userequest('post', 'getOrderTradeReport',searchOption,{ headers: { Authorization: 'Bearer '+token }, withCredentials: true} );
    }
    public getUserTradeReport(obj): Observable<any>{
        return this.userService.userequest('post', 'usertradereport',obj);
    }
    public getPairs(obj): Observable<any>{
        return this.userService.userequest('get', 'pairs',obj);
    }

    public getCreditOrderTradeReport(searchOption): Observable<any> {
        var token = localStorage.getItem('mean-token')
        return this.userService.userequest('post', 'getCreditOrderTradeReport',searchOption,{ headers: { Authorization: 'Bearer '+token }, withCredentials: true} );
      }
    public getCreditUserTradeReport(obj): Observable<any>{
        return this.userService.userequest('post', 'getCreditUserTradeReport',obj);
    }
}
