import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import AOS from 'aos';
import { AdminService } from '../services/admin.service';
import { UserService } from '../services/user.service';
import { NotificationService } from '../notification/notification.service';
import { TradingService } from '../services/trading.service';
import { NgForm } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services/storage.service';
import { HomeService } from './home.service';
import { SocketService } from 'src/app/services/socket.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { SocketWrapperService } from '../services/socket-wrapper.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  //private socket: any;
  loginstatus: any;
  priceConfig: any = {};
  home: any = {};
  homeList: any = {}
  firstkeyword = 'name';
  secondkeyword = 'name';
  public siteKey: any = '';
  public theme = 'Normal';
  submit:any;
  size:any;
  lang:any;
  type:any;
  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  constructor(private router: Router,
    private adminService: AdminService,
    private notifyService: NotificationService,
    private userService: UserService,
    private tradeService: TradingService,
    public translate: TranslateService,
    private storageService: StorageService,
    private readonly socketService: SocketService,
    private socketWrapper: SocketWrapperService,
    private homeService: HomeService) {
    this.siteKey = "6LfmI0MlAAAAAKQhPIF7bW1AKWgaZ_FO2L5iQj-J"
    this.loginstatus = localStorage.getItem('mean-token')
    this.obj.firstcurrency = "USDT"
    this.obj.secondcurrency = "INR"
    this.socketService.subscribePriceConfigChannels(this.obj.firstcurrency + this.obj.secondcurrency);
    if (this.loginstatus) {
      var detail = this.userService.getUserDetails();
      if(detail && detail._id) var userid: any = detail._id;
    } else {
      var userid: any = Math.floor(Math.random() * 100000000000)
    }
    this.loadTableData();
  }
  loadTableData() {
    this.homeService.geAllHomes().subscribe(res => {
      this.home = res.data[0];
      this.setHome();
    })
  }

  createobj: any = {}
  errorObj: any = {}
  //getTradingVal: any = [];
  firstcurrArr = [];
  secondcurrArr = [];

  //public vm: any = [];
  //tickerBlocksize_btc: any = [];
  //changetype = 'qty';
  //public tickerclick = 'crypto';
  public pairSelKey: any = "USDTINR";

  //public highprice: any;
  //public lowprice: any;
  //public highprice1: any;
  //public lowprice1: any;

  //public high: any = [];
  //public low: any = [];
  public buyQty: any = 0.5;
  public buyPrice: any = 0;
  public buyAmount: any = 0;

  
  firstCurrencyControl = new FormControl('');
  secondCurrencyControl = new FormControl('');

  firstCurrency: string = '';
  secondCurrency: string = '';

  firstCurrencies: string[] = [];
  secondCurrencies: string[] = [];

  filteredFirstCurrencies: Observable<string[]>;
  filteredSecondCurrencies: Observable<string[]>;

  //public bestbid: any = [];
  //public bestask: any = [];
  public sizebidshow: any = 0;
  //currPair: any;
  //public changetf: any = 0;

  obj: any = {
    firstcurrency: '',
    secondcurrency: '',
    price: 0,
    volume: 1
  }
  currentlang: any = "";
  ngOnInit(): void {

    AOS.init({
      once: true
    });

    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    var introCarousel = $(".carousel");
    var introCarouselIndicators = $(".carousel-indicators");
    introCarousel.find(".carousel-inner").children(".carousel-item").each(function (index) {
      (index === 0) ?
        introCarouselIndicators.append("<li data-target='#introCarousel' data-slide-to='" + index + "' class='active'></li>") :
        introCarouselIndicators.append("<li data-target='#introCarousel' data-slide-to='" + index + "'></li>");
    });

    introCarousel.on('slid.bs.carousel', function (e) {
      $(this).find('h2').addClass('animate__animated animate__fadeInDown');
      $(this).find('p, .btn-get-started').addClass('animate__animated animate__fadeInUp');
    });
    if ($('.nav-menu').length) {

      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('fa fa-bars fa fa-times');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).on('click', '.mobile-nav li > a', function (e) {
        if ($('body').hasClass('mobile-nav-active') && !$(this).parent().hasClass('drop-down')) {
          $('body').removeClass('mobile-nav-active');
          $('.mobile-nav-toggle i').toggleClass('fa fa-bars fa fa-times');
          $('.mobile-nav-overly').fadeOut();
        }
      });

      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('fa fa-bars fa fa-times');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }
    this.initFunction();
    this.loadTableData();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setHome(event.lang);
    });

    this.socketService.onPriceConfigDataReceive().subscribe(priceConfig => {
      this.priceConfig = priceConfig;
      if (this.obj.firstcurrency + this.obj.secondcurrency != this.priceConfig.Pair)
        return;

      if (this.buyQty > this.priceConfig.MinQuantity
        && this.buyQty <= this.priceConfig.Quantity) {

        this.buyPrice = parseFloat(Number((Number(this.priceConfig.AskPrice) + ((Number(this.priceConfig.AskPrice) * (this.priceConfig.Price_Spread)) / 100))).toFixed(this.priceConfig.Price_Precision));
        this.buyAmount = parseFloat((parseFloat(this.buyQty) * parseFloat(this.buyPrice)).toFixed(this.priceConfig.Price_Precision));
      }
    })

    this.socketWrapper.on('connect',()=>{
      console.log('-------------------socket connected--------------------------');
      setTimeout(() => {
        this.socketService.subscribePriceConfigChannels(this.obj.firstcurrency + this.obj.secondcurrency);
      }, 1000);
    });

    this.socketWrapper.on('disconnect',()=>{
      console.log('-------------------socket disconnected--------------------------');
    });

    this.filteredFirstCurrencies = this.firstCurrencyControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterFirstCurrencies(value || '')),
    );

    this.filteredSecondCurrencies = this.secondCurrencyControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSecondCurrencies(value || '')),
    );

  }

  private _filterFirstCurrencies(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.firstCurrencies.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterSecondCurrencies(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.secondCurrencies.filter(option => option.toLowerCase().includes(filterValue));
  }

  setHome(currentLang = this.translate.currentLang || 'en') {
    if (!Object.keys(this.home).length) return
    this.homeList['otc_desk'] = this.home.otc_desk[currentLang],
      this.homeList['eotc_desk'] = this.home.eotc_desk[currentLang],
      this.homeList['liquidity'] = this.home.liquidity[currentLang],
      this.homeList['api'] = this.home.api[currentLang],
      this.homeList['fully_compliant'] = this.home.fully_compliant[currentLang],
      this.homeList['highlights'] = this.home.highlights[currentLang],
      this.homeList['getin_touch'] = this.home.getin_touch[currentLang],
      this.homeList['white_label_solutions'] = this.home.white_label_solutions[currentLang],
      this.homeList['aboutus'] = this.home.aboutus[currentLang],
      this.homeList['block_trading'] = this.home.block_trading[currentLang],
      this.homeList['crypto_coins'] = this.home.crypto_coins[currentLang],
      this.homeList['coin_marketing'] = this.home.coin_marketing[currentLang],
      this.homeList['why_choose'] = this.home.why_choose[currentLang]

  }
  initFunction() {
    /*this.vm.orderForm = {};
    this.vm.orderForm.firstCurrency = '';
    this.vm.orderForm.secondCurrency = '';
    this.vm.orderForm.type = 'buy';
    this.vm.orderForm.ordertype = 'Market';
    this.vm.orderForm._csrf = '';

    this.vm.orderForm.buy = {};
    this.vm.orderForm.buy.qty = 0.5;
    this.vm.orderForm.buy.tot = 0;*/
    if (!this.loginstatus) {
      this.getTradePairWOLogin();
    } else {
      this.getTradePair();
    }
  }
  ngOnDestroy() {
    this.socketService.unSubscribePriceConfigChannels(this.obj.firstcurrency + this.obj.secondcurrency);
    //this.socket.disconnect();
  }
  handleSuccess(captchaResponse: any) {
    this.createobj['captcha'] = captchaResponse;
  }
  addGetinTouch(getin:NgForm){
     if(!this.createobj['business_name'])  {
      return this.notifyService.showError('', 'Business Name is required')
    } else if(!this.createobj['customer_name'])  {
      return this.notifyService.showError('', 'Customer Name is required')
    } else if(!this.createobj['email'])  {
      return this.notifyService.showError('', 'Email is required')
    } else if(!this.createobj['contact_number'])  {
      return this.notifyService.showError('', 'Contact Number is required')
    } else if(!this.createobj['telegram_id'])  {
      return this.notifyService.showError('', 'Telegram Id is required')
    } else if(!this.createobj['trading_vol_monthly'])  {
      return this.notifyService.showError('', 'Trading volume monthly is required')
    } else if(!this.createobj['message'])  {
      return this.notifyService.showError('', 'Message is required')
    } else if(!this.createobj['captcha'])  {
      return this.notifyService.showError('', 'Captcha is required')
    } 
    this.userService.csrf().subscribe(resp =>{
      this.createobj._csrf = resp._csrf
      this.adminService.addgetIntouch(this.createobj).subscribe(data => {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showSuccess('', data.messagees)
        } else {
          this.notifyService.showSuccess('', data.message)
        }
        getin.resetForm();
        this.captchaElem.resetCaptcha();
      }, (err) => {
        if (this.storageService.getLanguage() == 'es') {
          this.errorObj = err.error.messagees;
        } else {
          this.errorObj = err.error.message;
        }
      })
    })
  }
  getTradePair() {
    this.tradeService.getInternalPair().subscribe(currencies => {
      var tempfirstcurrArr = [];
      var tempsecondcurrArr = [];
      for (let index = 0; index < currencies.length; index++) {
        const element = currencies[index];
        tempfirstcurrArr.push({ id: index, name: element.BaseCCY });
        tempsecondcurrArr.push({ id: index, name: element.QuoteCCY });
      }

      this.firstcurrArr = this.getUniqvalArr(tempfirstcurrArr, 'name')
      let sortedFVaules = this.firstcurrArr.sort((a, b) => a.name > b.name ? 1 : -1);
      this.secondcurrArr = this.getUniqvalArr(tempsecondcurrArr, 'name')
      let sortedSValues = this.secondcurrArr.sort((a, b) => a.name > b.name ? 1 : -1);
      this.obj.firstcurrency = sortedFVaules.map(cur => cur.name).find(c => c === 'USDT');
      this.obj.secondcurrency = sortedSValues.map(cur => cur.name).find(c => c === 'INR');

      this.firstCurrencies = sortedFVaules.map(function(item) {
        return item['name'];
      });
      this.secondCurrencies = sortedSValues.map(function(item) {
        return item['name'];
      });

      this.pairSelKey = this.obj.firstcurrency + this.obj.secondcurrency;
    })
  }
  getTradePairWOLogin() {
    this.tradeService.getTradingWO().subscribe(currencies => {
      var tempfirstcurrArr = [];
      var tempsecondcurrArr = [];

      for (let index = 0; index < currencies.length; index++) {
        const element = currencies[index];
        tempfirstcurrArr.push({ id: index, name: element.BaseCCY });
        tempsecondcurrArr.push({ id: index, name: element.QuoteCCY });

      }
      this.firstcurrArr = this.getUniqvalArr(tempfirstcurrArr, 'name')
      let sortedFVaules = this.firstcurrArr.sort((a, b) => a.name > b.name ? 1 : -1);

      this.secondcurrArr = this.getUniqvalArr(tempsecondcurrArr, 'name');
      let sortedSValues = this.secondcurrArr.sort((a, b) => a.name > b.name ? 1 : -1);

      this.obj.firstcurrency = sortedFVaules.map(cur => cur.name).find(c => c === 'USDT');
      this.obj.secondcurrency = sortedSValues.map(cur => cur.name).find(c => c === 'INR');

      this.firstCurrencies = sortedFVaules.map(function(item) {
        return item['name'];
      });
      this.secondCurrencies = sortedSValues.map(function(item) {
        return item['name'];
      });

      this.pairSelKey = this.obj.firstcurrency + this.obj.secondcurrency;
    })
  }
  getUniqvalArr(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  }

  qtyChange(type = 'buy') {
    if (type = 'buy') {
      this.buyPrice = '';
      this.buyAmount = '';
      //this.vm.orderForm.buy.tot = '';
    }
  }
  async changecurr() {
    this.socketService.unSubscribePriceConfigChannels(this.obj.firstcurrency + this.obj.secondcurrency);

    this.pairSelKey = this.obj.firstcurrency + this.obj.secondcurrency;
    //this.tickerclick = 'crypto';

    this.buyQty = 0.5;
    this.buyPrice = '';
    this.buyAmount = '';
    //this.vm.orderForm.buy.tot = 0;
    this.firstCurrency = this.obj.firstcurrency;
    this.secondCurrency = this.obj.secondcurrency;

    setTimeout(() => {
      this.socketService.subscribePriceConfigChannels(this.pairSelKey);
    }, 1000);

    this.userService.csrf().subscribe(resp => {
      var getobj = {
        pair: this.pairSelKey,
        _csrf: resp._csrf
      }
    }, err => {
      console.log(err.error.message);
    })
  }

  navTrade() {
    if (this.loginstatus) {
      this.userService.csrf().subscribe(resp => {
        var getobj = {
          pair: this.obj.firstcurrency + this.obj.secondcurrency,
          _csrf: resp._csrf
        }
        this.tradeService.getPairDetail(getobj).subscribe(data => {
          if (data) {
            this.router.navigate(['/trade/' + this.obj.firstcurrency + '_' + this.obj.secondcurrency])
          }
        }, err => {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", "Paar pole saadaval");
          } else {
            this.notifyService.showError("", "Pair Not Available");
          }
        })
      }, err => {
        console.log(err.error.message);
      })
    } else {
      this.userService.csrf().subscribe(resp => {
        var getobj = {
          pair: this.obj.firstcurrency + this.obj.secondcurrency,
          _csrf: resp._csrf
        }
        this.tradeService.getPairDetail(getobj).subscribe(data => {
          if (data) {
            this.router.navigate(['/login'])
            if (this.storageService.getLanguage() == 'es') {
              this.notifyService.showError("", 'Jätkamiseks tuleb sisse logida');
            } else {
              this.notifyService.showError("", 'Must Login To Continue');
            }
          }
        }, err => {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", "Paar pole saadaval");
          } else {
            this.notifyService.showError("", "Pair Not Available");
          }
        })
      }, err => {
        console.log(err.error.message);
      })
    }
  }


  ngAfterViewInit() {
    this.setHome()
  }
}
