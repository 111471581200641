import { Component, OnInit } from "@angular/core";
import { CareerService } from "./career.service";
import * as $ from "jquery";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-careers",
  templateUrl: "./careers.component.html",
  styleUrls: ["./careers.component.css"],
})
export class CareersComponent implements OnInit {
  jobList = [];
  cmsList = [];
  jobListReal = [];

  constructor(
    private careerService: CareerService,
    private translate: TranslateService
  ) {}
  loadTableData() {
    this.careerService.geAllCareers().subscribe((res) => {
      this.jobListReal = res.data;
      this.setJobList();
    });
  }

  setJobList(currentLang = this.translate.currentLang) {
    this.jobList = this.jobListReal.map((job) => {
      return {
        _id: job._id,
        job_title: job.job_title[currentLang],
        job_type: job.job_type[currentLang],
        location: job.location[currentLang],
        exp_from: job.exp_from[currentLang],
        exp_to: job.exp_to[currentLang],
      };
    });
  }

  getCms() {
    this.careerService.getCms().subscribe((res) => {
      this.cmsList = res.data;
    });
  }
  ngOnInit(): void {
    this.loadTableData();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setJobList(event.lang);
    });
    this.getCms();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $("#header").addClass("header-scrolled");
      } else {
        $("#header").removeClass("header-scrolled");
      }
    });
  }
}
