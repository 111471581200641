import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
// import { NotificationsService } from 'angular2-notifications';
import { CareerService } from "../careers/career.service";
import { NotificationService } from "../notification/notification.service";
import { UserService } from "../services/user.service";
import * as $ from "jquery";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { StorageService } from "../services/storage.service";

@Component({
  selector: "app-jobdescriptions",
  templateUrl: "./jobdescriptions.component.html",
  styleUrls: ["./jobdescriptions.component.css"],
})
export class JobdescriptionsComponent implements OnInit {
  formObj: any = { job_id: "" };
  jobList:any = {};
  job: any = {};
  errorObj: boolean = false;
  file;
  fileObj;
  activeRoute;
  id;
  submit = false;
  csrf: string;
  @ViewChild("fileInput") fileInput: ElementRef;
  constructor(
    private careerService: CareerService,
    private _Activatedroute: ActivatedRoute,
    private _router: Router,
    private notif: NotificationService,
    private userService: UserService,
    private translate: TranslateService,
    private storageService : StorageService,
  ) {
    this.careerService.geAllCareers().subscribe((res) => {
      this.jobList = res.data;  
    });
    this.userService.csrf().subscribe((resp) => {
      this.csrf = resp._csrf;
    });
  }
  addCandidate(candidate) {
    this.submit = true;
    this.formObj.job_id = this.id;
    this.formObj._csrf = this.csrf;
    console.log(this.formObj, "Formdata");
    this.careerService
      .createCandidate({ ...this.formObj, fileToUpload: this.file })
      .subscribe(
        (res) => {
          this.notif.showSuccess(res.data.message, "Successfully Saved");
          candidate.resetForm({});
          this.submit = false;
        },
        (err) => (this.errorObj = err.error.message)
      );
  }
 
  ngOnInit(): void {
    this.activeRoute = this._Activatedroute.paramMap.subscribe((params) => {
      this.id = params.get("id");
      this.careerService.getCareer(this.id).subscribe((res) => {
        this.job = res.data;
        this.setJob()
      });
    });
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $("#header").addClass("header-scrolled");
      } else {
        $("#header").removeClass("header-scrolled");
      }
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setJob(event.lang)
      this.addDataTitle();
    });
  }
  setJob(currentLang = this.translate.currentLang) {
    setTimeout(() => {
      this.jobList['job_title'] =  this.job.job_title[currentLang];
    this.jobList['job_type'] =  this.job.job_type[currentLang];
    this.jobList['location'] =  this.job.location[currentLang];
    this.jobList['exp_from'] =  this.job.exp_from[currentLang];
    this.jobList['exp_to'] =  this.job.exp_to[currentLang];
    this.jobList['description'] =  this.job.description[currentLang]; 
    },100)
    
  }
  addDataTitle() {
    setTimeout(() => {
      this.fileInput.nativeElement.setAttribute(
        "data-title",
        this.fileInput.nativeElement.getAttribute("title")
      );
    }, 100);
  }

  ngAfterViewInit() {
    this.addDataTitle();
    this.setJob()
  }

  setFile(event) {
    const input = event.target;
    if (input.files && input.files[0]) {
      this.fileObj = input.files[0];
      if (input.files[0].type !== "application/pdf") {
        if (this.storageService.getLanguage()=='es') {
          this.notif.showError("", "Laadige üles ainult doc-vormingus või PDF-vormingus")
        }else{
          this.notif.showError("","Only Upload doc Format Or PDF Format")
        }
       
        return;
      }
      let reader = new FileReader();
      reader.onload = (e) => {
        let imgData = e.target.result;
        let imgName = input.files[0].name;
        input.setAttribute("data-title", imgName);
        this.file = e.target.result;
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
}
