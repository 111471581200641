import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Globals {
    convertapi = {
    };
    eventACount =0;
    eventPCount =0;
    permission= false;
    setAEventCount(val) {
        this.eventACount = Number(val);
    }
    getAEventCount(){
        return this.eventACount;
    }
    setPEventCount(val) {
        this.eventPCount = Number(val);
    }
    getPEventCount(){
        return this.eventPCount;
    }
    setPermission(val){
        this.permission = val;
    }
    getPermission(){
        return this.permission;
    }
    setPriceValue(val){
        this.convertapi[val.key] =val.item;
    }
    getPriceValue(key){
        return this.convertapi[key];
    }
    getAllPrice(){
        return this.convertapi;
    }
    deleteAllPrice(){
        this.convertapi={};
    }
    UserCurrencyCounter=0;
    TradePriceCounter=0;
}