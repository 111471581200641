import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Router } from '@angular/router';

@Component({
    selector: 'app-custom-button',
    templateUrl: './ag-custom-button.component.html',
    styleUrls: ['./ag-custom-button.component.css']
})
export class AgCustomButtonComponent implements ICellRendererAngularComp {
    params;
    label: string;

    constructor(
      private router: Router,
    ) { }
  
    agInit(params): void {
      this.params = params;
    }
  
    refresh(params?: any): boolean {
      return true;
    }
  
    onClick($event) {
      this.router.navigate(['edit-referral/'+this.params.data._id])  
    }
}
