import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  skipUrls = [
    'api.ipify.org',
    '/apiroute/getipaddrbyip'
  ];

  activeRequests = 0;
  constructor(private spinnerService: NgxSpinnerService) {
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let displayLoadingScreen = true;

    for (const skipUrl of this.skipUrls) {
      if (new RegExp(skipUrl).test(request.url)) {
        displayLoadingScreen = false;
        break;
      }
    }
    if (!displayLoadingScreen) {
      return next.handle(request);
    }

    if (this.activeRequests === 0) {
      this.spinnerService.show();
    }
    this.activeRequests++;
    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.spinnerService.hide();
        }
      })
    )
  };
}
