import { Component, ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';
import { UserIdleService } from 'angular-user-idle';
import {UserService} from "./services/user.service";
import { AdminService } from "./services/admin.service";
import { Router } from "@angular/router";
// import { Socket } from 'ngx-socket-io';
import { NotificationService } from './notification/notification.service';
import { StorageService } from './services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {

  constructor(private userIdle: UserIdleService,public auth: UserService,
              public adminService:AdminService,
              private router: Router,
              // private socket:Socket,
              private notifyserice:NotificationService,
              private translate: TranslateService,
              private storageService : StorageService
              ) {

                translate.addLangs(['en', 'es']);
                if (this.storageService.getLanguage()) {
                  const browserLang = this.storageService.getLanguage();
                  translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
                } else {
                  this.storageService.setLanguage('en');
                  translate.setDefaultLang('en');
                }
  }
  findobj={
    ipv4_addr:'',
    ipv6_addr:'',
    _csrf:''
 }
  ngOnInit() {
    this.getpswdchangestatus();
    // get current ip start
    this.auth.getIpvfourClient().subscribe((ipres)=>{
    this.auth.getIpvsixClient().subscribe((ipressix)=>{
      // get csrf start
      this.auth.csrf().subscribe((response) => {
        //  this.findobj={ip_addr:ipres.ip,
        //   _csrf:response._csrf}
        this.findobj.ipv4_addr=ipres.ip;
        this.findobj._csrf = response._csrf
        this.findobj.ipv6_addr=ipressix.ip
        // find by ip api start
          if(!this.auth.isLoggedIn()){
            console.log("getipAddrbyIP ***")
            this.adminService.getipAddrbyIP(this.findobj).subscribe(ipfind=>{
                if(ipfind.result){
                  // window.location.href="/block";
                  this.router.navigate(['/block'])
                }
            })
          }
        // find by ip api end
      });
      // get csrf end
    })
    });
    //this.ipStatusWatch();
    const userd = this.auth.getUserDetails();
    //   if(userd)
    //   {
    //     this.socket.emit('CREATEROOM',userd._id)
    //   }
    // // get current ip end
    // this.socket.on('deleteuser',(data)=>{
    //   this.notifyserice.showError('Alert', 'Your Account has been deleted by admin.');
    //   this.auth.logout();
    // })
  }
  stop() {
    this.userIdle.stopTimer();
  }
 
  stopWatching() {
    this.userIdle.stopWatching();
  }
 
  startWatching() {
    this.userIdle.startWatching();
  }
 
  restart() {
    this.userIdle.resetTimer();
  }
  getpswdchangestatus(){
    // this.socket.on('pswdchangeStatus', (response)=>{
    //   if(response!==null){
    //       // window.location.href="/login";
    //       this.auth.getProfileAPI().subscribe((userdetail) => {
    //         if(response._id == userdetail._id){
    //           this.notifyserice.showSuccess("", "Password changed successfully, You must Re-login");
    //           this.auth.logout()
    //         }
    //       },(err)=>{
    //         console.log(err.error.message);
    //       })
    //   }
    // })
  }
  //ipStatusWatch(){
  //   this.socket.on('ipstatus',(response)=>{
  //     this.auth.csrf().subscribe((data)=>{
  //       this.auth.getIpvfourClient().subscribe((ipres)=>{
  //         this.auth.getIpvsixClient().subscribe((ipressix)=>{
  //           var ipdetail = {}
  //           ipdetail['ipv4_addr'] = ipres.ip;
  //           ipdetail['ipv6_addr'] = ipressix.ip
  //           ipdetail['_csrf'] = data._csrf;
  //           // this.auth.getloginlog(ipdetail).subscribe((data)=>{
  //             if(response.ip_addr == ipres.ip || response.ip_addr ==ipressix.ip){
  //               this.auth.logout()
  //               window.location.href="/block";
  //             }
  //           // },(err)=>{
  //           //   console.error(err); 
  //           // })
  //         })
  //       })
  //       })
  // })
  //}
}
