import { Component, OnInit, Injectable, ViewChild, ElementRef, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TradingService } from '../services/trading.service';
import { ReportService } from "../services/report.service";
import { UserService } from '../services/user.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../notification/notification.service';
import * as XLSX from 'xlsx';
import { PagerService } from "../services/pager.service";
import { KycserviceService } from '../services/kycservice.service'
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from 'src/app/services/socket.service';
import { MatTableDataSource } from '@angular/material/table';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import * as moment from 'moment';
import { AgCustomImageComponent } from '../ag-custom-image/custom-image.component';
import { saveAs } from 'file-saver';
import { SocketWrapperService } from '../services/socket-wrapper.service';

var filterBalance = true;
@Component({
  selector: 'app-trade',
  templateUrl: './trade.component.html',
  styleUrls: ['./trade.component.css'],
  styles: [`
    .ag-theme-balham .ag-cell-data-changed {
      background-color:#d1d9ff !important;
    }
    .ag-header-row.ag-header-row-column{
      color:black;
    }
  `],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class TradeComponent implements OnInit {

  public pairDataSource: any = new MatTableDataSource();
  public pairColumns: any[] = [
    { columnDef: 'PairName', header: '', cell: (element: any) => element.PairName },
  ];
  displayedPairColumns = this.pairColumns.map(c => c.columnDef);
  searchValue = "";
  selectedQuoteCurrency = 'ALL';
  selectedPair = "";
  selectedOrderType = 'Market';
  quickQuantity: number = 0;
  quantityArray: any = [];
  isQuickTradeEnabled = false;
  disableBuyQuickTrade = false;
  disableSellQuickTrade = false;


  //AG GRID IMPLEMENTATION
  public columnDefs: ColDef[] = [
    { field: 'Quantity', headerName: 'Quantity', sort: 'asc' },
    { field: 'BidPrice', headerName: 'Bid' },
    { field: 'AskPrice', headerName: 'Ask' },
  ];
  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    enableCellChangeFlash: true
  };
  public priceRowData: any[] = [];
  @ViewChild(AgGridAngular) agGrid: AgGridAngular;
  priceGridApi!: GridApi;

  //Trade History ag grid
  public tradeColumnDefs: ColDef[] = [
    { field: 'TradeId', headerName: 'TradeId' },
    { field: 'Pair', headerName: 'Pair' },
    {
      field: 'TradeTS', headerName: 'TradeAt', sort: 'desc',
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    },
    { field: 'OrderId', headerName: 'OrderId' },
    { field: 'TradeQuantity', headerName: 'Quantity' },
    { field: 'TradePrice', headerName: 'Price' },
    { field: 'TradeAmount', headerName: 'TradeAmount' },
    { field: 'OrderType', headerName: 'OrderType' },
    {
      field: 'Side', headerName: 'Side',
      cellStyle: param => {
        if (param.data.Side == 'BUY') {
          return { color: 'green' };
        } else {
          return { color: 'red' };
        }
      }
    },
  ];
  public tradeDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    enableCellChangeFlash: true
  };
  public tradeRowData: any[] = [];
  @ViewChild(AgGridAngular) tradeAgGrid: AgGridAngular;
  tradeGridApi!: GridApi;

  //Wallet ag grid
  public walletColumnDefs: ColDef[] = [
    { field: 'Assets', headerName: 'Assets', flex: 1, cellRendererFramework: AgCustomImageComponent },
    { field: 'Balance', headerName: 'Balance', flex: 1 },
    { field: 'Type', headerName: 'Type', flex: 1 },
    // { field: 'WalletId', headerName: 'WalletId', flex: 1 },
  ];
  public walletDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    enableCellChangeFlash: true
  };
  public walletRowData: any[] = [];
  @ViewChild(AgGridAngular) walletAgGrid: AgGridAngular;
  walletGridApi!: GridApi;


  //Order History Ag Grid
  public orderColumnDefs: ColDef[] = [
    { field: 'OrderId', headerName: 'OrderId' },
    { field: 'Pair', headerName: 'Pair' },
    { field: 'Quantity', headerName: 'Quantity' },
    { field: 'OrderType', headerName: 'OrderType' },
    {
      field: 'Side', headerName: 'Side',
      cellStyle: param => {
        if (param.data.Side == 'BUY') {
          return { color: 'green' };
        } else {
          return { color: 'red' };
        }
      }
    },
    {
      field: 'OrderTS', headerName: 'OrderAt', sort: 'desc',
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    },
    { field: 'OrderStatus', headerName: 'OrderStatus' },
    { field: 'FillQuantity', headerName: 'FillQuantity' },
    { field: 'AvgFillPrice', headerName: 'AvgFillPrice' },
    { field: 'CommentText', headerName: 'CommentText' },
  ];
  public orderDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    enableCellChangeFlash: true
  };
  public orderRowData: any[] = [];
  @ViewChild(AgGridAngular) orderAgGrid: AgGridAngular;
  orderGridApi!: GridApi;


  //AG GRID IMPLEMENTATION

  exportOrderHistory: any = [];
  exportTradeHistory: any = [];
  tickerBlocksize_btc: any = [];
  changeBlocksize_btc: any = [];
  changeBlocksize_eth: any = [];
  tickerBlocksize_eth: any = [];

  public data: any = [];
  public zeroBalance: boolean = false;
  public isZeroBalanceChecked: boolean = true;

  public isloader = false;

  public wallist: any = [];

  public buyPrice: any; // this is quick trade entry buy and sell price based on selected quantity.
  public sellPrice: any;
  public spreadVal: string = '';

  public tradelist: any = [];
  public orderhistorylist: any = [];

  public vm: any = [];

  userId: string = '';
  userData: any;

  firstCurrency: string = '';
  secondCurrency: string = '';

  firstCurrencyBalance: string = '0';
  secondCurrencyBalance: string = '0';

  buyButton: boolean = true;
  sellButton: boolean = true;
  buyErrorMessage: string = '';
  sellErrorMessage: string = '';
  orderFormBuyPrice: string = '';
  orderFormSellPrice: string = '';

  pairlist: any = [];
  selectedForm: string = 'BUY';
  selectedPairData: any = {};

  //priceConfig: any = {};// this we can remove..
  updatedOrder: any = {};
  updatedWallet: any = {};
  updatedTrade: any = {};
  fCurrencyIcon: any;
  sCurrencyIcon: any;

  fCurrencyPrecision: number = 0;
  sCurrencyPrecision: number = 0;

  quantityMap = new Map();
  pairsMap = new Map();

  ref_m = '';
  searchObj = {
    exchangetype: '',
    search: '',
    // time: new Date(),
    perpage: 0,
    page: 0,
    fromPlace: 'Fr',
    _csrf: ''
  }

  tradeHistory = [];
  pager1: any = {};
  Tpager: number;
  pager: number;
  tradepager: number;
  currentPage: number;
  public wallistFilter: any = [];
  //limitbuyPrice: any = 0;
  //limitsellPrice: any = 0

  constructor(
    private tradeService: TradingService,
    private userService: UserService,
    private reportService: ReportService,
    private http: HttpClient,
    private notifyService: NotificationService,
    private pagerService: PagerService,
    private kycService: KycserviceService,
    private router: Router,
    private actrout: ActivatedRoute,
    private storageService: StorageService,
    public translate: TranslateService,
    private readonly socketService: SocketService,
    private socketWrapper: SocketWrapperService,
    private cdref: ChangeDetectorRef

  ) {
    this.userId = this.userService.getUserId();
    this.userService.getProfileAPI().subscribe((response) => {
      this.userData = response;
      if (response.referal_user) {
        this.vm.orderForm.ReferredUserId = response.referal_user._id;
        this.vm.orderForm.ReferredBonusCurrency = response.referal_user.bonus_currency;
      }
    });

    this.kycService.getUserKYC().subscribe(res => {
      if (res.status == 'Pending' || res.status == 'Rejected' || res.status == 'Draft') {

        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist");
        } else {
          this.notifyService.showError("", "Not Accesible until KYC Verification");
        }

        this.router.navigate(['profile'])
      }
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Pole juurdepääsetav enne KYC kinnitamist");
      } else {
        this.notifyService.showError("", "Not Accesible until KYC Verification");
      }

      this.router.navigate(['profile'])
      this.router.navigate(['profile'])
    })
    setTimeout(() => {
      this.socketService.subscribeOrderDataChannels(this.userId);
      this.socketService.subscribeWalletDataChannels(this.userId);
      this.socketService.subscribeTradeDataChannels(this.userId);
    }, 1000);

    this.actrout.params.subscribe(params => {
      if (params.pair) {
        var splitpair = params.pair.split("_")
        this.firstCurrency = splitpair[0];
        this.secondCurrency = splitpair[1];
        this.selectedPair = this.firstCurrency + this.secondCurrency;
        this.getCurrenciesSymbol(this.firstCurrency, this.secondCurrency);
        setTimeout(() => {
          this.socketService.subscribePriceConfigChannels(this.firstCurrency + this.secondCurrency);
        }, 1000);
      }
    })

  }
  getCurrenciesSymbol(fCurrency, sCurrency) {
    this.tradeService.getCurrencies().subscribe(currencies => {
      let resCurrency = currencies.map(currency => {
        return { currency: currency.CurrencySymbol, icon: currency.Icon, precision: currency.DecimalPlace }
      });
      let fCIcon = resCurrency.find(data => {
        if (data.currency === fCurrency) {
          return data.icon;
        }
      });
      let sCIcon = resCurrency.find(data => {
        if (data.currency === sCurrency) {
          return data.icon;
        }
      });

      this.fCurrencyIcon = fCIcon.icon;
      this.sCurrencyIcon = sCIcon.icon;

      this.fCurrencyPrecision = fCIcon.precision;
      this.sCurrencyPrecision = sCIcon.precision;
    })
  }

  getData(e) {
    this.getCurrenciesSymbol(e.BaseCCY, e.QuoteCCY)
  }
  //Not sure what this is used.
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  ngOnInit(): void {

    this.tradepager = 1
    this.pager = 1;
    this.searchObj['FilPerpage'] = 10;
    this.searchObj['FilPage'] = 1;
    this.data = {};
    this.csrf();

    this.vm.orderForm = {};
    this.vm.orderForm.BaseCurrency = '';
    this.vm.orderForm.QuoteCurrency = '';
    this.vm.orderForm.Side = 'BUY';     // BUY/SELL
    this.vm.orderForm.OrderType = 'MARKET';  // MARKET/LIMIT
    this.vm.orderForm.OrderSource = 'OTCWEB';
    this.vm.orderForm.Quantity = '';
    this.vm.orderForm.Price = '-1';
    this.vm.orderForm.Amount = '-1';
    this.vm.orderForm.ChangeType = 'QTY';  //QTY/AMOUNT
    this.vm.orderForm._csrf = '';
    this.vm.orderForm.ReferredUserId = '';
    this.vm.orderForm.ReferredBonusCurrency = '';

    this.vm.orderForm.buy = {};
    this.vm.orderForm.sell = {};

    //this.fetchWallet();

    this.userService.getProfileAPI().subscribe((response) => {
      this.userData = response;
      this.socketService.onPriceConfigDataReceive().subscribe(priceConfig => {
        //this.priceConfig = priceConfig;
        var local_priceConfig = JSON.parse(JSON.stringify(priceConfig));
        if (this.firstCurrency + this.secondCurrency != local_priceConfig.Pair)
          return;

        local_priceConfig.AskPrice = parseFloat(Number((Number(local_priceConfig.AskPrice) + ((Number(local_priceConfig.AskPrice) * (Number(local_priceConfig.Price_Spread) + Number(this.userData.ask_price_spread) + Number(this.userData.referal_ask_price_spread))) / 100))).toFixed(local_priceConfig.Price_Precision));
        local_priceConfig.BidPrice = parseFloat(Number((Number(local_priceConfig.BidPrice) - ((Number(local_priceConfig.BidPrice) * (Number(local_priceConfig.Price_Spread) + Number(this.userData.bid_price_spread) + Number(this.userData.referal_bid_price_spread))) / 100))).toFixed(local_priceConfig.Price_Precision));
        local_priceConfig.Spread = Number(local_priceConfig.AskPrice) == 0 ? 0 : (100 * (Number(local_priceConfig.AskPrice) - Number(local_priceConfig.BidPrice)) / Number(local_priceConfig.AskPrice));
        local_priceConfig.Spread = local_priceConfig.Spread.toFixed(2);

        //quick trade screen price table build
        if (!this.quantityMap.has(local_priceConfig.Quantity)) {
          if (this.quantityArray && !this.quantityArray.includes(local_priceConfig.Quantity)) {
            this.quantityArray.push(local_priceConfig.Quantity);
            this.quantityArray = this.quantityArray.sort((a, b) => Number(a) - Number(b));
            this.quickQuantity = this.quantityArray[0];
          }
          this.quantityMap.set(local_priceConfig.Quantity, {})
        }

        if (this.userData.use_level1_price == true && this.quantityArray.length > 0) {
          let top_quatity = this.quantityArray[0];

          if (local_priceConfig.Quantity == top_quatity) {
            //update the other levels
            for (let [key, value] of this.quantityMap) {
              if (key == top_quatity)
                continue;
              value.BidPrice = local_priceConfig.BidPrice;
              value.AskPrice = local_priceConfig.AskPrice;
              value.Spread = local_priceConfig.Spread;
              this.bindGrid(value);
            }
          }
          else {
            let topquantityMap = this.quantityMap.get(top_quatity);
            if (topquantityMap) {
              local_priceConfig.BidPrice = topquantityMap.BidPrice;
              local_priceConfig.AskPrice = topquantityMap.AskPrice;
              local_priceConfig.Spread = topquantityMap.Spread;
            }
          }
        }

        this.bindGrid(local_priceConfig);

        //quick trade screen prices
        if (this.quickQuantity > local_priceConfig.MinQuantity
          && this.quickQuantity <= local_priceConfig.Quantity) {
          this.buyPrice = local_priceConfig.BidPrice;
          this.sellPrice = local_priceConfig.AskPrice;
          this.spreadVal = local_priceConfig.Spread;
        }

        var quantityMapData = this.quantityMap.get(local_priceConfig.Quantity);
        quantityMapData.BidPrice = local_priceConfig.BidPrice;
        quantityMapData.AskPrice = local_priceConfig.AskPrice;
        quantityMapData.Spread = local_priceConfig.Spread;
        quantityMapData.Quantity = local_priceConfig.Quantity;

        if (Number(this.vm.orderForm.buy.qty) > local_priceConfig.MinQuantity
          && Number(this.vm.orderForm.buy.qty) <= local_priceConfig.Quantity) {

          this.orderFormBuyPrice = local_priceConfig.AskPrice;
          this.vm.orderForm.buy.tot = parseFloat((parseFloat(this.vm.orderForm.buy.qty) * parseFloat(local_priceConfig.AskPrice)).toFixed(8));

          var status = this.performManualOrderBuyValidation(true);
          if (status.is_valid == false) {
            if (this.storageService.getLanguage() == 'es') {
              this.buyErrorMessage = status.Messagess;
            } else {
              this.buyErrorMessage = status.Message;
            }
          }
          else {
            this.buyErrorMessage = '';
            this.buyButton = false;
          }
        }

        if (Number(this.vm.orderForm.sell.qty) > local_priceConfig.MinQuantity
          && Number(this.vm.orderForm.sell.qty) <= local_priceConfig.Quantity) {

          this.orderFormSellPrice = local_priceConfig.BidPrice;
          this.vm.orderForm.sell.tot = parseFloat((parseFloat(this.vm.orderForm.sell.qty) * parseFloat(local_priceConfig.BidPrice)).toFixed(8));

          var status = this.performManualOrderSellValidation(true);
          if (status.is_valid == false) {
            if (this.storageService.getLanguage() == 'es') {
              this.sellErrorMessage = status.Messagess;
            } else {
              this.sellErrorMessage = status.Message;
            }
          }
          else {
            this.sellErrorMessage = '';
            this.sellButton = false;
          }
        }
      })
    });

    this.socketService.onOrderDataReceive().subscribe((updatedOrder: any) => {
      if (updatedOrder.WalletType == 'CREDIT') return;
      console.log("updatedOrder", updatedOrder);
      this.bindSingleOrder(updatedOrder)
      if (updatedOrder.OrderStatus == 'REJECTED') {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError('', "Teie tellimus lükati tagasi");
        } else {
          this.notifyService.showError('', "Your order rejected");
        }
      }
    })

    this.socketService.onWalletDataReceive().subscribe((updatedWallet: any) => {
      if (updatedWallet.walletType == 'CREDIT') return;
      console.log("updatedWallet", this.updatedWallet);
      this.updatedWallet = updatedWallet;
      this.bindSingleWallet(updatedWallet);
      var foundIndex = this.wallist.findIndex(x => x._id == this.updatedWallet._id);
      if (foundIndex >= 0) {
        this.wallist[foundIndex] = this.updatedWallet;
      } else {
        this.wallist = [this.updatedWallet, ...this.wallist]
      }
      this.hideZeroBalance(this.zeroBalance);

      if (this.firstCurrency == this.updatedWallet.currency) {
        this.firstCurrencyBalance = this.updatedWallet.availableBalance;
        this.firstCurrencyBalance = this.truncateDecimals(this.firstCurrencyBalance, this.fCurrencyPrecision).toFixed(this.fCurrencyPrecision);
      }
      if (this.secondCurrency == this.updatedWallet.currency) {
        this.secondCurrencyBalance = this.updatedWallet.availableBalance;
        this.secondCurrencyBalance = this.truncateDecimals(this.secondCurrencyBalance, this.sCurrencyPrecision).toFixed(this.sCurrencyPrecision);
      }
    })

    this.socketService.onTradeDataReceive().subscribe((updatedTrade: any) => {
      if (updatedTrade.WalletType == 'CREDIT') return;
      console.log("updatedTrade", updatedTrade);
      this.bindSingleTrade(updatedTrade);
    })

    this.socketService.onUserDataReceive().subscribe((updatedUser: any) => {
      console.log("updatedTrade", updatedUser);
      this.userData = updatedUser;
    })

    this.socketWrapper.on('connect', () => {
      console.log('-------------------socket connected--------------------------');
      setTimeout(() => {
        this.socketService.subscribeOrderDataChannels(this.userId);
        this.socketService.subscribeWalletDataChannels(this.userId);
        this.socketService.subscribeTradeDataChannels(this.userId);
        this.socketService.subscribeUserDataChannels(this.userId);
        this.socketService.subscribePriceConfigChannels(this.firstCurrency + this.secondCurrency);
      }, 1000);
    });

    this.socketWrapper.on('disconnect', () => {
      console.log('-------------------socket disconnected--------------------------');

    });

  }

  csrf() {
    this.userService.csrf().subscribe((response) => {
      this.searchObj._csrf = response._csrf;
      this.vm.orderForm._csrf = response._csrf;
      this.data._csrf = response._csrf;
      //this.getFavouritePair();
      this.fetchTradeHistory();
      this.fetchOrderHistory();
      this.fetchPairs();
      this.fetchWallet();

      this.vm.orderForm.buy.qty = '';
      this.vm.orderForm.buy.tot = '';
      this.vm.orderForm.sell.qty = '';
      this.vm.orderForm.sell.tot = '';
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", "Ilmnes viga, proovige uuesti.");
      } else {
        this.notifyService.showError("", "Error occured, please try again.");
      }

    });
  }

  /*search(): void {
    let term = this.searchTerm;
    this.getTradingVal = this.currencyListSearchCopy.filter(function (tag) {
      if (tag.pair.toLowerCase().indexOf(term.toLowerCase()) >= 0 || tag.pair.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
        return tag;
      }
    });
  }*/

  validateNumber(e: any) {
    /*var input = e.target.value;
    var id = e.target.id;
    var test = input.split(".");
    if (id == 'buytotal' || id == 'selltotal') {
      var len = 2;
      var field = 'Total';
    }
    else {
      var len = 5;
      var field = 'Qty';
    }

    if (test.length > 0) {
      if (typeof test[1] != 'undefined' && test[1].length >= len) {
        this.vm.orderForm.buy.qty = '';
        this.vm.orderForm.buy.tot = '';
        this.vm.orderForm.sell.qty = '';
        this.vm.orderForm.sell.tot = '';
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError('', 'Max ' + field + ' kümnendkoha piirang on' + len);

        } else {
          this.notifyService.showError('', 'Max ' + field + ' decimal Limit is ' + len);
        }
        e.preventDefault();
      }
    }*/
    //Adding log
  }

  truncateDecimals = function (number, digits) {
    var multiplier = Math.pow(10, digits);
    var adjustedNum = number * multiplier;
    //var truncatedNum = Math[adjustedNum < 0 ? 'ceil' : 'floor'](adjustedNum);
    var truncatedNum = Math.floor(adjustedNum + 0.000001);

    return truncatedNum / multiplier;
  };

  qtyChange(type, event) {
    if (event == 'change')
      return;

    if (type == 'BUY') {
      this.orderFormBuyPrice = '';
      this.vm.orderForm.buy.tot = '';
      this.buyButton = true;
      this.buyErrorMessage = '';

      if (this.vm.orderForm.buy.qty == null) {
        if (this.storageService.getLanguage() == 'es') {
          this.buyErrorMessage = 'Enter valid quanity';
        } else {
          this.buyErrorMessage = 'Enter valid quanity';
        }
        return;
      }
      if (Number(this.vm.orderForm.buy.qty) < 0 || isNaN(parseFloat(this.vm.orderForm.buy.qty)))
        this.vm.orderForm.buy.qty = '';
      else
        this.vm.orderForm.buy.qty = this.truncateDecimals(this.vm.orderForm.buy.qty, this.selectedPairData.BasePrecision);
      //this.vm.orderForm.buy.qty = parseFloat(this.vm.orderForm.buy.qty.toFixed(this.selectedPairData.BasePrecision));     

      var status = this.performManualOrderBuyValidation(false);
      if (status.is_valid == false) {
        if (this.storageService.getLanguage() == 'es') {
          this.buyErrorMessage = status.Messagess;
        } else {
          this.buyErrorMessage = status.Message;
        }
      }
      else {
        this.buyErrorMessage = '';
        this.buyButton = false;
      }

    } else if (type == 'SELL') {
      this.orderFormSellPrice = '';
      this.vm.orderForm.sell.tot = '';
      this.sellButton = true;
      this.sellErrorMessage = '';

      if (this.vm.orderForm.sell.qty == null) {
        if (this.storageService.getLanguage() == 'es') {
          this.sellErrorMessage = 'Enter valid quanity';
        } else {
          this.sellErrorMessage = 'Enter valid quanity';
        }
        return;
      }

      if (Number(this.vm.orderForm.sell.qty) < 0 || isNaN(parseFloat(this.vm.orderForm.sell.qty)))
        this.vm.orderForm.sell.qty = ''
      else
        this.vm.orderForm.buy.qty = this.truncateDecimals(this.vm.orderForm.sell.qty, this.selectedPairData.BasePrecision);

      //this.vm.orderForm.sell.qty = parseFloat(this.vm.orderForm.sell.qty.toFixed(this.selectedPairData.BasePrecision));     

      var status = this.performManualOrderSellValidation(false);
      if (status.is_valid == false) {
        if (this.storageService.getLanguage() == 'es') {
          this.sellErrorMessage = status.Messagess;
        } else {
          this.sellErrorMessage = status.Message;
        }
      }
      else {
        this.sellErrorMessage = '';
        this.sellButton = false;
      }
    }
  }

  placeQuickOrder(type, amount) {

    this.temproryDisableButtons(type);
    let orderObj = {
      BaseCurrency: this.firstCurrency,
      QuoteCurrency: this.secondCurrency,
      Side: type,
      OrderType: 'MARKET',
      //OrderSource: 'OTCWEB',
      Quantity: this.quickQuantity,
      Price: 0,
      ChangeType: 'QTY',
      ReferredUserId: this.vm.orderForm.ReferredUserId,
      ReferredBonusCurrency: this.vm.orderForm.ReferredBonusCurrency,
      //Client: 'OTC',//this.userData.short_name,
      Amount: Number(amount),
      _csrf: this.data._csrf
    };
    console.log(orderObj);
    if (this.performOrderValidation(orderObj)) {
      //place order
      console.log('Order Placed');
      this.tradeService.orderPlaceService(orderObj, 'orderplace').subscribe((result) => {
        if (!result.status) {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError('', result.messagees);
          } else {
            this.notifyService.showError('', result.message);
          }
        } else {
          this.isloader = false;
          //this.orderhistorylist = [result.data, ...this.orderhistorylist]
          this.bindSingleOrder(result.data)
          if (type === 'BUY') {
            var message = "Order executed successfully for " + orderObj.Quantity + ' ' + this.firstCurrency
            var messagees = "Tellimus on edukalt täidetud " + orderObj.Quantity + ' ' + this.firstCurrency
            if (this.storageService.getLanguage() == 'es') {
              this.notifyService.showSuccess('', messagees);
            } else {
              this.notifyService.showSuccess('', message);
            }
          } else {
            var message = "Order executed successfully for " + orderObj.Quantity + ' ' + this.firstCurrency
            var messagees = "Tellimus on edukalt täidetud  " + orderObj.Quantity + ' ' + this.firstCurrency
            if (this.storageService.getLanguage() == 'es') {
              this.notifyService.showSuccess('', messagees);
            } else {
              this.notifyService.showSuccess('', message);
            }
          }
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  temproryDisableButtons(type) {
    if (type === 'BUY') {
      this.disableBuyQuickTrade = true;
      setTimeout(() => this.disableBuyQuickTrade = false, 500)
    } else {
      this.disableSellQuickTrade = true;
      setTimeout(() => this.disableSellQuickTrade = false, 500)
    }
  }

  get disableBuyButton() {
    if (!this.isQuickTradeEnabled) {
      return true;
    }

    if (this.disableBuyQuickTrade) {
      return true;
    }
    return false;
  }
  get disableSellButton() {
    if (!this.isQuickTradeEnabled) {
      return true;
    }

    if (this.disableSellQuickTrade) {
      return true;
    }
    return false;
  }

  orderPlace(type) {
    this.vm.orderForm.Side = type;
    this.vm.orderForm.BaseCurrency = this.firstCurrency;
    this.vm.orderForm.QuoteCurrency = this.secondCurrency;
    this.vm.orderForm.Client = 'OTC';
    if (type == 'BUY') {
      var status = this.performManualOrderBuyValidation(false);
      if (status.is_valid == false) {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError('', status.Messagess);
        } else {
          this.notifyService.showError('', status.Message);
        }
      }
      else {
        this.vm.orderForm.Quantity = this.vm.orderForm.buy.qty;
        this.orderPlaceService(type);
      }
    } else if (type == 'SELL') {
      var status = this.performManualOrderSellValidation(false);
      if (status.is_valid == false) {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError('', status.Messagess);
        } else {
          this.notifyService.showError('', status.Message);
        }
      }
      else {
        this.vm.orderForm.Quantity = this.vm.orderForm.sell.qty
        this.orderPlaceService(type);
      }
    }
    else {
      console.log("Error on validation");
    }
  }

  performManualOrderBuyValidation(bIsPriceUpdate) {
    let validOrder = false;
    let Message = '';
    let Messagess = '';
    var max_channel = 0;
    if (this.quantityArray.length > 0) {
      max_channel = this.quantityArray[this.quantityArray.length - 1];
    }

    if (Number(this.vm.orderForm.buy.qty) < this.selectedPairData.MinQuantity) {
      Messagess = "Lubatud minimaalne kogus on " + this.selectedPairData.MinQuantity;
      Message = 'Allowed minimum quantity is ' + this.selectedPairData.MinQuantity;;
    } else if (Number(this.vm.orderForm.buy.qty) > this.selectedPairData.MaxQuantity) {
      Messagess = "Lubatud maksimaalne kogus on " + this.selectedPairData.MaxQuantity;
      Message = 'Allowed maximum quantity is ' + this.selectedPairData.MaxQuantity;
    }
    else if (Number(this.vm.orderForm.buy.qty) > max_channel) {
      Messagess = "Lubatud maksimaalne kogus on " + max_channel;
      Message = 'Allowed maximum quantity is ' + max_channel;
    }
     /*else if (this.vm.orderForm.buy.tot < this.selectedPairData.MinNotional) {
        Messagess= "Lubatud minimaalne tinglik on" + this.selectedPairData.MinNotional;
        Message = "Allowed minimum notional is " + this.selectedPairData.MinNotional;
    } else if (this.vm.orderForm.buy.tot > this.selectedPairData.MaxNotional) {
        Messagess= "Lubatud maksimaalne tinglik on" + this.selectedPairData.MaxNotional;
        Message = "Allowed maximum notional is " + this.selectedPairData.MaxNotional;
    } */else if (this.vm.orderForm.buy.tot > this.secondCurrencyBalance) {
      Messagess = "Teil pole piisavalt tasakaalu.";
      Message = "Insufficient balance.";
      if (!bIsPriceUpdate)
        console.log(`require ${this.vm.orderForm.buy.tot} available ${this.secondCurrencyBalance}`);
    }
    else {
      validOrder = true;
    }

    return { is_valid: validOrder, Message: Message, Messagess: Messagess };
  }

  performManualOrderSellValidation(bIsPriceUpdate) {
    let validOrder = false;
    let Message = '';
    let Messagess = '';
    var max_channel = 0;
    if (this.quantityArray.length > 0) {
      max_channel = this.quantityArray[this.quantityArray.length - 1];
    }

    if (Number(this.vm.orderForm.sell.qty) < this.selectedPairData.MinQuantity) {
      Messagess = "Lubatud minimaalne kogus on " + this.selectedPairData.MinQuantity;
      Message = 'Allowed minimum quantity is ' + this.selectedPairData.MinQuantity;;
    } else if (Number(this.vm.orderForm.sell.qty) > this.selectedPairData.MaxQuantity) {
      Messagess = "Lubatud maksimaalne kogus on " + this.selectedPairData.MaxQuantity;
      Message = 'Allowed maximum quantity is ' + this.selectedPairData.MaxQuantity;
    }
    else if (Number(this.vm.orderForm.sell.qty) > max_channel) {
      Messagess = "Lubatud maksimaalne kogus on " + max_channel;
      Message = 'Allowed maximum quantity is ' + max_channel;
    }/*else if (this.vm.orderForm.sell.tot < this.selectedPairData.MinNotional) {
        Messagess= "Lubatud minimaalne tinglik on" + this.selectedPairData.MinNotional;
        Message = "Allowed minimum notional is " + this.selectedPairData.MinNotional;
    } else if (this.vm.orderForm.sell.tot > this.selectedPairData.MaxNotional) {
        Messagess= "Lubatud maksimaalne tinglik on" + this.selectedPairData.MaxNotional;
        Message = "Allowed maximum notional is " + this.selectedPairData.MaxNotional;
    } */else if (this.vm.orderForm.sell.qty > this.firstCurrencyBalance) {
      Messagess = "Teil pole piisavalt tasakaalu.";
      Message = "Insufficient balance.";
      if (!bIsPriceUpdate)
        console.log(`require ${this.vm.orderForm.buy.tot} available ${this.secondCurrencyBalance}`);
    }
    else {
      validOrder = true;
    }

    return { is_valid: validOrder, Message: Message, Messagess: Messagess };
  }

  performOrderValidation(OrderObj: any) {
    let validOrder = false;

    if (!OrderObj.Quantity) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Kogus ei tohi olla null ega tühi " + this.selectedPairData.MinQuantity);
      } else {
        this.notifyService.showError('', 'Quantity Can not be zero or empty ' + this.selectedPairData.MinQuantity);
      }
    }
    else if (OrderObj.Quantity < this.selectedPairData.MinQuantity) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Lubatud minimaalne kogus on " + this.selectedPairData.MinQuantity);
      } else {
        this.notifyService.showError('', 'Allowed minimum quantity is ' + this.selectedPairData.MinQuantity);
      }
    } else if (OrderObj.Quantity > this.selectedPairData.MaxQuantity) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Lubatud maksimaalne kogus on " + this.selectedPairData.MaxQuantity);
      } else {
        this.notifyService.showError('', 'Allowed maximum quantity is ' + this.selectedPairData.MaxQuantity);
      }
    } else/* if (OrderObj.Amount < this.selectedPairData.MinNotional) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Lubatud minimaalne tinglik on" + this.selectedPairData.MinNotional);
      } else {
        this.notifyService.showError('', "Allowed minimum notional is " + this.selectedPairData.MinNotional);
      }
    } else if (OrderObj.Amount > this.selectedPairData.MaxNotional) {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError('', "Lubatud maksimaalne tinglik on" + this.selectedPairData.MaxNotional);
      } else {
        this.notifyService.showError('', "Allowed maximum notional is " + this.selectedPairData.MaxNotional);
      }
    } else*/ if (OrderObj.Side == 'BUY' && OrderObj.Amount > Number(this.secondCurrencyBalance)) {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError('', "Teil pole piisavalt tasakaalu.");
        } else {
          this.notifyService.showError('', "Insufficient balance.");
        }
        console.log(`required: ${OrderObj.Amount} Available:${this.secondCurrencyBalance} `);
      } else if (OrderObj.Side == 'SELL' && OrderObj.Quantity > Number(this.firstCurrencyBalance)) {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError('', "Teil pole piisavalt tasakaalu.");
        } else {
          this.notifyService.showError('', "Insufficient balance.");
        }

        console.log(`required: ${OrderObj.Quantity} Available:${this.firstCurrencyBalance} `);
      } else {
        validOrder = true;
      }
    return validOrder;
  }

  orderPlaceService(type) {
    var data = JSON.stringify(this.vm.orderForm);

    this.tradeService.orderPlaceService(data, 'orderplace').subscribe((result) => {
      if (!result.status) {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError('', result.messagees);
        } else {
          this.notifyService.showError('', result.message);
        }
      } else {
        this.isloader = false;
        //this.orderhistorylist = [result.data, ...this.orderhistorylist];
        this.bindSingleOrder(result.data)
        if (type === 'BUY') {
          var quantity = this.vm.orderForm.buy.qty;
          var message = type + " Order request successfully for " + quantity + ' ' + this.firstCurrency + this.secondCurrency;
          var messagees = "Tellimus on edukalt täidetud " + quantity + ' ' + this.firstCurrency + this.secondCurrency;

          this.vm.orderForm.buy.qty = '';
          this.vm.orderForm.buy.pri = 0;
          this.orderFormBuyPrice = '';
          this.vm.orderForm.buy.tot = '';
          this.buyButton = true;
        } else {
          var quantity = this.vm.orderForm.sell.qty;
          var message = type + " Order request successfully for " + quantity + ' ' + this.firstCurrency + this.secondCurrency;
          var messagees = "Tellimus on edukalt täidetud  " + quantity + ' ' + this.firstCurrency + this.secondCurrency;

          this.vm.orderForm.sell.qty = '';
          this.vm.orderForm.sell.pri = 0;
          this.orderFormSellPrice = '';
          this.vm.orderForm.sell.tot = '';
          this.sellButton = true;
        }

        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showSuccess('', messagees);
        } else {
          this.notifyService.showSuccess('', message);
        }
      }
    }, (err) => {
      console.log(err);
    });
  }
  changeQuantity(quickQuantity) {
    this.quickQuantity = parseInt(quickQuantity);
    this.buyPrice = '';
    this.sellPrice = '';
  }
  round(value, float = 0) {
    return parseFloat(value).toFixed(float);
  }
  dateformat(value) {
    return new DatePipe('en-Us').transform(value, 'dd-MM-yyyy HH:mm:ss', 'GMT+2');
  }

  changePair(BaseCCY, QuoteCCY) {
    this.router.navigate(['trade/' + BaseCCY + '_' + QuoteCCY])
    //this.t.select('tab-buyTabNg')
    this.socketService.unSubscribePriceConfigChannels(this.firstCurrency + this.secondCurrency);
    this.firstCurrency = BaseCCY;
    this.secondCurrency = QuoteCCY;
    this.setBalance(this.firstCurrency, 'firstCurrency');
    this.setBalance(this.secondCurrency, 'secondCurrency');
    this.setSelectedPairData(this.firstCurrency + this.secondCurrency);
    this.vm.orderForm.buy.qty = '';
    this.vm.orderForm.sell.qty = '';
    this.buyPrice = '';
    this.sellPrice = '';
    this.spreadVal = '';
    //this.limitbuyPrice = 0;
    this.quickQuantity = parseInt('0');
    //this.limitsellPrice = 0;

    this.vm.orderForm.sell.tot = '';
    this.vm.orderForm.buy.tot = '';
    this.orderFormBuyPrice = '';
    this.orderFormSellPrice = '';
    this.buyErrorMessage = '';
    this.sellErrorMessage = '';
    this.quantityMap.clear();
    this.quantityArray = [];
    this.priceRowData = [];
    this.priceGridApi.setRowData(this.priceRowData);
    this.priceGridApi.refreshCells();
  }

  fetchOrderHistory(pair = null) {

    this.searchObj['pair'] = '';
    this.searchObj['expvalue'] = false;

    this.reportService.getOrderTradeReport(this.searchObj).subscribe((successRes) => {
      this.Tpager = successRes.pages;
      this.currentPage = successRes.current ? successRes.current : 1;
      this.setPage(this.searchObj['FilPage']);
      //console.log("fetchOrderHistory",successRes.result)
      this.orderhistorylist = successRes.result;
      this.bindOrderGrid(this.orderhistorylist)
      if (successRes.status.length == 0) {
        this.orderhistorylist = [];
      }
    }, (err) => {
      this.notifyService.showError('', err.error.message);
    });
  }

  fetchTradeHistory() {
    this.searchObj['expvalue'] = false;

    this.reportService.getUserTradeReport(this.searchObj).subscribe((successRes) => {
      var tradeData = successRes.transactions;
      //console.log("fetchTradeHistory",successRes.transactions)
      this.currentPage = successRes.current ? successRes.current : 1;
      this.tradelist = tradeData.trade;
      this.bindTradeGrid(this.tradelist);

    }, (error) => {
    })
  }

  fetchWallet() {
    this.tradeService.getCwalUserBalapi(this.data).subscribe(async (result) => {
      if (result.wallist) {
        let walletArray = [];
        for (const wlt of result.wallist) {
          try {
            if (wlt) {
              walletArray.push({
                Balance: wlt.availableBalance,
                WalletId: wlt._id,
                Status: wlt.status,
                Currency: wlt.currency,
                Assets: wlt.currency,
                Type: wlt.currency_type,
                Icon: wlt.icon,
              });
            }
          } catch (error) {
            console.log(error);
          }
        }

        this.bindWalletGrid(walletArray);
        this.wallist = []
        this.wallistFilter = [];
        this.wallist = result.wallist;
        this.wallistFilter = result.wallist.filter(wal => wal.availableBalance > 0);
        this.setBalance(this.firstCurrency, 'firstCurrency');
        this.setBalance(this.secondCurrency, 'secondCurrency');
        console.log(result)
      }

    }, (err) => {
      console.log(err);
    });

  }

  fetchPairs() {
    this.reportService.getPairs('').subscribe((successRes) => {
      successRes = successRes.sort((a, b) => (a.BaseCCY + a.QuoteCCY > b.BaseCCY + b.QuoteCCY) ? 1 : -1)
      this.pairDataSource.data = successRes.map(data => {
        return {
          PairName: data.PairName,
          BaseCCY: data.BaseCCY,
          QuoteCCY: data.QuoteCCY
        }
      });

      this.pairlist = successRes;
      console.log(this.firstCurrency + this.secondCurrency)
      this.setSelectedPairData(this.firstCurrency + this.secondCurrency);

      for (let pair of this.pairlist) {
        if (!this.pairsMap.has(pair.QuoteCCY)) {
          this.pairsMap.set(pair.QuoteCCY, {
            pairs: []
          })
        }
        var pairsMap = this.pairsMap.get(pair.QuoteCCY)
        pairsMap.pairs.push({
          PairName: pair.PairName,
          BaseCCY: pair.BaseCCY,
          QuoteCCY: pair.QuoteCCY
        })
      }
      // console.log(this.pairlist)
      // console.log(this.pairsMap)

    }, (error) => {
    })
  }

  setBalance(currency, type) {
    var wallet = this.wallist.find(element => element.currency == currency);

    if (type == 'firstCurrency') {
      if (wallet)
        this.firstCurrencyBalance = wallet.availableBalance;
      else
        this.firstCurrencyBalance = "0";
    }

    if (type == 'secondCurrency') {
      if (wallet)
        this.secondCurrencyBalance = wallet.availableBalance;
      else
        this.secondCurrencyBalance = "0";
    }

    this.firstCurrencyBalance = this.truncateDecimals(this.firstCurrencyBalance, this.fCurrencyPrecision).toFixed(this.fCurrencyPrecision);
    this.secondCurrencyBalance = this.truncateDecimals(this.secondCurrencyBalance, this.sCurrencyPrecision).toFixed(this.sCurrencyPrecision);
  }

  setSelectedPairData(pair) {
    this.selectedPairData = this.pairlist.find(element => element.PairName == pair);
  }

  exportExcel(history: string) {
    if (history == 'order_history') {
      this.searchObj['expvalue'] = true
      this.reportService.getOrderTradeReport(this.searchObj).subscribe((successRes) => {
        this.exportOrderHistory = [];
        for (let i = 0; i < successRes.result.length; i++) {
          this.exportOrderHistory.push({
            'Pair': successRes.result[i].pair,
            'CreatedAt (CET)': this.dateformat(successRes.result[i].createdAt),
            'Orderid': successRes.result[i].clienttradeid,
            'Price': successRes.result[i].price,
            'Quantity': this.round(successRes.result[i].amount, 5),
            'Total': this.round(successRes.result[i].totalamount, 2),
            'Type': successRes.result[i].exchangetype,
            'Reason': successRes.result[i].reasonForCancellation,
            'Status': successRes.result[i].status
          })

        }
        let fileName = 'OrderHistory.xlsx';
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportOrderHistory);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, fileName);
      }, (err) => {
        console.error('unable to export excel');

      })

    } else if (history == 'trade_history') {
      this.searchObj['expvalue'] = true;
      this.reportService.getUserTradeReport(this.searchObj).subscribe((successRes) => {
        var positionData = successRes.transactions;
        this.exportTradeHistory = [];
        for (let i = 0; i < positionData.trade.length; i++) {
          this.exportTradeHistory.push({
            'Pair': positionData.trade[i].pair,
            'CreatedAt (CET)': this.dateformat(positionData.trade[i].createdAt),
            'Orderid': positionData.trade[i].clienttradeid,
            'price': positionData.trade[i].price,
            'Quantity': this.round(positionData.trade[i].amount, 5),
            'Total': this.round(positionData.trade[i].totalamount, 2),
            'Type': positionData.trade[i].exchangetype,
            'Order type': positionData.trade[i].ordertype,
            'Status': positionData.trade[i].status
          })
        }

        let fileName = 'TradeHistory.xlsx';
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportTradeHistory);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, fileName);
      }, (err) => {
        console.error('unable to export excel');
      })
    } else {

    }
  }

  setPage(page: number) {
    // get pager object from service
    this.pager1 = this.pagerService.getPager(this.Tpager, page);
  }


  setPageOnClickTradehistory(page: number) {
    this.tradepager = page;
    this.searchObj['FilPage'] = this.tradepager;
    this.fetchTradeHistory();
  }

  ngOnDestroy() {
    this.socketService.unSubscribePriceConfigChannels(this.firstCurrency + this.secondCurrency);
    this.socketService.unSubscribeOrderDataConfigChannels(this.userId);
    this.socketService.unSubscribeWalletDataConfigChannels(this.userId);
    this.socketService.unSubscribeUserDataConfigChannels(this.userId);
    this.socketService.unSubscribeTradeDataConfigChannels(this.userId);
  }

  hideZeroBalance(isChecked: boolean) {
    this.zeroBalance = isChecked;
    if (isChecked) {
      this.wallistFilter = this.wallist.filter(wal => wal.availableBalance > 0);
    } else {
      this.wallistFilter = this.wallist;

    }
  }
  public applyPairFilter(event: Event) {
    this.pairDataSource.filter = (event.target as HTMLInputElement).value;;
  }
  clearFilters() {
    this.pairDataSource.filter = '';
    this.searchValue = ''
  }

  onRowClicked(row) {
    this.selectedPair = row.PairName;
    this.changePair(row.BaseCCY, row.QuoteCCY)
  }

  filterQuoteCurrency() {
    if (this.selectedQuoteCurrency != 'ALL') {
      this.pairDataSource.data = this.pairlist.filter(a => a.QuoteCCY == this.selectedQuoteCurrency).map(data => {
        return {
          PairName: data.PairName,
          BaseCCY: data.BaseCCY,
          QuoteCCY: data.QuoteCCY
        }
      })
    } else {
      this.pairDataSource.data = this.pairlist.map(data => {
        return {
          PairName: data.PairName,
          BaseCCY: data.BaseCCY,
          QuoteCCY: data.QuoteCCY
        }
      })
    }
  }

  getBuyAmount() {
    let pairdtObj = this.getPairData();
    if (pairdtObj) {
      return (Number(this.buyPrice || 0) * Number(this.quickQuantity || 0)).toFixed(pairdtObj.ValuePrecision)
    }
    return (Number(this.buyPrice || 0) * Number(this.quickQuantity || 0)).toFixed(0)
  }

  getSellAmount() {
    let pairdtObj = this.getPairData();
    if (pairdtObj) {
      return (Number(this.sellPrice || 0) * Number(this.quickQuantity || 0)).toFixed(pairdtObj.ValuePrecision)
    }
    return (Number(this.sellPrice || 0) * Number(this.quickQuantity || 0)).toFixed(0)
  }

  getPairData() {
    return this.pairlist.find(a => a.PairName == this.selectedPair);
  }

  onOrderEntryTabChanged(event: any) {
    // this value is not used at all anywhere
    this.selectedForm = event.tab.textLabel;
  }

  onGridReady(params: GridReadyEvent) {
    this.priceGridApi = params.api;
    this.priceGridApi.sizeColumnsToFit();
    this.priceGridApi.setDomLayout('autoHeight');
  }

  getRowNodeId(data) {
    return data['Quantity']
  }

  bindGrid(data: any) {
    let selectedNode = this.priceGridApi.getRowNode(data['Quantity']);
    if (selectedNode) {
      this.priceGridApi.applyTransaction({ update: [data] });
      //this.priceGridApi.flashCells({ rowNodes: [selectedNode], flashDelay: 250, fadeDelay: 100 });
    } else {
      this.priceGridApi.applyTransaction({ add: [data] })
    }
  }

  onOrderGridReady(params: GridReadyEvent) {
    this.orderGridApi = params.api;
    this.orderGridApi.sizeColumnsToFit();
    this.orderGridApi.setDomLayout('autoHeight');
  }

  getOrderRowNodeId(data) {
    return data['OrderId']
  }

  onTradeGridReady(params: GridReadyEvent) {
    this.tradeGridApi = params.api;
    this.tradeGridApi.sizeColumnsToFit();
    this.tradeGridApi.setDomLayout('autoHeight');
  }

  onWalletGridReady(params: GridReadyEvent) {
    this.walletGridApi = params.api;
    this.walletGridApi.setDomLayout('autoHeight');
  }

  getTradeRowNodeId(data) {
    return data['TradeId']
  }

  getWalletRowNodeId(data) {
    return data['WalletId']
  }


  bindOrderGrid(data: any) {
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.orderGridApi.getRowNode(dt['OrderId']);
        if (selectedNode) {
          this.orderGridApi.applyTransaction({ update: [dt] });
        } else {
          this.orderGridApi.applyTransaction({ add: [dt] })
        }
      }
    }
  }

  bindSingleOrder(dt: any) {
    let selectedNode = this.orderGridApi.getRowNode(dt['OrderId']);
    if (selectedNode) {
      this.orderGridApi.applyTransaction({ update: [dt] });
    } else {
      this.orderGridApi.applyTransaction({ add: [dt] })
    }
  }

  bindTradeGrid(data: any) {
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.tradeGridApi.getRowNode(dt['TradeId']);
        if (selectedNode) {
          if (dt['IsActive'] == false) {
            this.tradeGridApi.applyTransaction({ remove: [dt] });
          } else {
            this.tradeGridApi.applyTransaction({ update: [dt] });
          }
        } else if (dt['IsActive'] == true) {
          this.tradeGridApi.applyTransaction({ add: [dt] })
        }
      }
    }
  }

  bindWalletGrid(data: any) {
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.walletGridApi.getRowNode(dt['WalletId']);
        if (selectedNode) {
          this.walletGridApi.applyTransaction({ update: [dt] });
        } else {
          this.walletGridApi.applyTransaction({ add: [dt] })
        }
      }
    }
  }


  bindSingleWallet(wlt: any) {
    try {
      let selectedNode = this.walletGridApi.getRowNode(wlt._id);
      if (selectedNode) {
        selectedNode.setDataValue('Balance', wlt.availableBalance);
        this.walletGridApi.refreshCells();
      }
    } catch (error) {
      console.log(error);
    }
  }
  bindSingleTrade(dt: any) {
    if (dt) {
      let selectedNode = this.tradeGridApi.getRowNode(dt['TradeId']);
      if (selectedNode) {
        if (dt['IsActive'] == false) {
          this.tradeGridApi.applyTransaction({ remove: [dt] });
        }
        else {
          this.tradeGridApi.applyTransaction({ update: [dt] });
        }
      } else if (dt['IsActive'] == true) {
        this.tradeGridApi.applyTransaction({ add: [dt] })
      }
    }
  }

  refreshOrders() {
    this.fetchOrderHistory();
  }
  refreshTrades() {
    this.fetchTradeHistory();
  }

  refreshWallets() {
    this.fetchWallet();
  }

  exportWalletData() {
    var blob = new Blob([this.walletGridApi.getDataAsCsv()], { type: 'text/csv' })
    saveAs(blob, "wallets.csv");
  }

  exportOrderData() {
    var blob = new Blob([this.orderGridApi.getDataAsCsv()], { type: 'text/csv' })
    saveAs(blob, "orders.csv");
  }

  exportTradeData() {
    var blob = new Blob([this.tradeGridApi.getDataAsCsv()], { type: 'text/csv' })
    saveAs(blob, "trades.csv");
  }

  applyOrderFilter(event: any) {
    this.orderGridApi.setQuickFilter(event.target.value)
  }

  applyWalletFilter(event: any) {
    this.walletGridApi.setQuickFilter(event.target.value)
  }

  applyTradeFilter(event: any) {
    this.tradeGridApi.setQuickFilter(event.target.value)
  }

  doesExternalFilterPass(node: any): boolean {
    return node.data && Number(node.data['Balance']) > 0;
  }

  isExternalFilterPresent(): boolean {
    return filterBalance;
  }

  doZeroFilter(event: any) {
    filterBalance = event.checked;
    this.walletGridApi.onFilterChanged();
  }
}
