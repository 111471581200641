import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-display-key-popup',
  templateUrl: './display-key-popup.component.html',
  styleUrls: ['./display-key-popup.component.css']
})
export class DisplayKeyPopupComponent implements OnInit {
  element: any = {}
  myForm: any = FormControl;
  public note = "This is the only step to view and copy secret key. For your account security, please keep it in a secure location and do not disclose."
  constructor(public dialogRef: MatDialogRef<DisplayKeyPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    public fb: FormBuilder, private toastr: ToastrService,
    private router: Router,
    private clipboard: Clipboard
  ) {
    Object.assign(this.element, data);
    dialogRef.disableClose = true;
  }
  ngOnInit(): void {
  }

  copyKey() {
    this.clipboard.copy(this.element.apiKey);
  }
  copySecret() {
    this.clipboard.copy(this.element.apiSecret);
  }

  redirect() {
    this.router.navigate(['apiconfig']);
  }


}
