import { Injectable } from '@angular/core';
import { UserService } from "../services/user.service";
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';


export interface withdraw {

    _csrf: String
    amount?: String;
    eurvalue?: Number;
    address?: String;
    note?: String;
    currency?: String;
    agree?: String;
    withdraw_fee: Number;
    withdraw_minimum: Number,
    withdraw_maximum: Number,
    exchangefee_apply?: String;
    des_tag: String
}
@Injectable({
    providedIn: 'root'
})
// export interface OrderPayload {
//   buy?:Object
// }

export class TradingService {

    api_url: string = environment.apiEndPoint;
    private token: string;
    constructor(private http: HttpClient, private router: Router, private userService: UserService, private ngxService: NgxUiLoaderService) { }

    // New trade start
    private getToken(): string {
        let temp_token = localStorage.getItem('mean-token');
        if (!this.token)
            this.token = temp_token;

        if (this.token == temp_token) {
            return temp_token;
        }
        return '';
    }
    public getCwalUserBalance(pData, urlSuf): Observable<any> {
        return this.http.post<any>(this.api_url + 'getcwaluserbalance', pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }
    public getuserWhitelistStatus(): Observable<any> {
        return this.http.get<any>(this.api_url + 'getuserWhitelistStatus', {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }
    public getUserAddress(data): Observable<any> {
        return this.http.post<any>(this.api_url + 'get_user_address', data, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }

    public Withdrawsubmit(pData, urlSuf, lang): Observable<any> {
        return this.http.post<any>(this.api_url + 'Withdrawsubmit?lang=' + lang, pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }

    public cryptoWithdrawFees(pData, lang): Observable<any> {
        return this.http.post<any>(this.api_url + 'cryptoWithdrawFees?lang=' + lang, pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }
    public withdrawconfirm(pData, urlSuf): Observable<any> {
        return this.http.post<any>(this.api_url + 'withdrawconfirm', pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }

    public withdrawcancel(pData, urlSuf): Observable<any> {
        return this.http.post<any>(this.api_url + 'withdrawcancel', pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }

    public getcurrency(pData, urlSuf): Observable<any> {
        return this.http.post<any>(this.api_url + 'getcurrency', pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }

    public getOpenOrders(): Observable<any> {
        return this.http.get<any>(this.api_url + 'getOpenOrders', {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }
    public orderPlaceService(pData, urlSuf): Observable<any> {
        return this.http.post<any>(this.api_url + urlSuf, pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }

    public orderPlaceCredit(pData, urlSuf): Observable<any> {
        return this.http.post<any>(this.api_url + urlSuf, pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }

    public orderPlaceServicelimit(pData, urlSuf): Observable<any> {
        return this.http.post<any>(this.api_url + urlSuf, pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }

    public getDeposit(perpage, page, searchoption, expvalue): Observable<any> {
        return this.userService.userequest('get', 'getdeposit', null, {
            perpage: perpage,
            page: page,
            searchoption,
            export: expvalue
        });
    }

    public getFiatDeposit(perpage, page, currency, expvalue): Observable<any> {
        return this.userService.userequest('get', 'getfiatdeposit', null, {
            perpage: perpage,
            page: page,
            currency: currency,
            export: expvalue
        });
    }
    // public getDepositGraph(): Observable<any>{
    //   return this.http.get<any>(this.exchange_url + 'getdepositedgraph' , {
    //     withCredentials: true,
    //     headers: {'Content-Type':  'application/json',Authorization: `Bearer ${this.getToken()}`}
    //   });
    // }
    public getwithdrawtrans(perpage, page, searchoption, expvalue): Observable<any> {
        return this.userService.userequest('get', 'withdrawtrans', null, {
            perpage: perpage,
            page: page,
            searchoption,
            export: expvalue
        });
    }

    // New trade end

    public getCurrencies(): Observable<any> {
        return this.userService.userequest('get', 'getcurrencies');
    }

    public getInternalPair(): Observable<any> {
        return this.userService.userequest('get', 'currencies');
    }
    public getTradingWO(): Observable<any> {
        return this.userService.userequest('get', 'getTradingWO');
    }
    public getPairDetail(obj): Observable<any> {
        return this.userService.userequest('post', 'getpairdetail', obj);
    }
    public getCwalUserBalapi(config): Observable<any> {
        return this.userService.userequest('post', 'getCwalUserBalapi', config);
    }
    public updateAdminUser(obj): Observable<any> {
        return this.userService.userequest('post', 'updateadminuser', obj);
    }

    // public getWalletdetails(): Observable<any> {
    //    return this.userService.userequest('get', 'getwalletconfig');
    // }

    public startLoader() {
        this.ngxService.start();
    }
    public stopLoader() {
        this.ngxService.stop();
    }

    public Whitelistsubmit(pData, urlSuf, lang): Observable<any> {
        return this.http.post<any>(this.api_url + 'whitelistsave?lang=' + lang, pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }

    public whitelist_process(pData, urlSuf): Observable<any> {
        return this.http.post<any>(this.api_url + 'whitelist_process', pData, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${this.getToken()}` }
        });
    }
}