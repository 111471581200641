import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp, ITooltipAngularComp } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, ICellRendererParams, ITooltipParams } from 'ag-grid-community';


interface ToolTipParams extends ICellRendererParams {
    lineBreak?: boolean;
    toolTipArray?: string[];
    toolTip?: string;
}

@Component({
    selector: 'app-custom-image',
    templateUrl: './custom-image.component.html',
    styleUrls: ['./custom-image.component.css']
})
export class AgCustomImageComponent implements ICellRendererAngularComp {
    params: any;
    iconUrl;
    asset:any=''
    constructor() { }

    agInit(params: any) {
        this.params = params;
        this.iconUrl=this.params.data.Icon;
        this.asset=this.params.value;
    }

    refresh(params: ICellRendererParams): boolean {
        throw new Error('Method not implemented.');
    }
    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
        throw new Error('Method not implemented.');
    }
}
