import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zignsec-failure',
  templateUrl: './zignsec-failure.component.html',
  styleUrls: ['./zignsec-failure.component.css']
})
export class ZignsecFailureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
