import { NgModule } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';

const MaterialComponents = [
	MatSelectModule
];

@NgModule({
  imports: [
   MaterialComponents,
  ],
  exports: [
  MaterialComponents
  ]
})
export class MaterialModule { }
