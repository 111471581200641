import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { UserService } from "../services/user.service";

@Injectable()
export class KycService {
  token: string;
  api_url: string = environment.apiEndPoint;
  apiKey: string = environment.zignsecApiKey;
  constructor(private http: HttpClient, private userSvc: UserService) {}
  
  getSumsubStatus(id){
    return this.http.get<any>(this.api_url + 'sumsub/' + id);
  }
}
