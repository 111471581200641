// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  name: 'staging',
  apiEndPoint: 'https://otcapi-staging.blockoville.com/apiroute/',
  zignsecSuccessUrl: 'https://otc-staging.blockoville.com/success',
  zignsecFailedUrl: 'https://otc-staging.blockoville.com/failed',
  walletserviceurl: 'https://otcapi-staging.blockoville.com/',
  zignsecApiKey: 'da615ba7aae34ecea38da29491e82c6c',
  imageapiendpoint: 'https://otcapi-staging.blockoville.com/uploads/',
  S3_BUCKET_URL: 'https://blockoville-otc.s3.eu-central-1.amazonaws.com/',
  VITE_WORKFLOW_ID:"general_onboarding",
  recaptchsitekey: '6Le6eW4UAAAAAFFTQqLmvxFGMNhp8Xbs1JDwV3ot',
  SOCKET_SERVER: 'https://otcapi-staging.blockoville.com/',
  socketurl: 'https://otcapi-staging.blockoville.com/',
  socketurlexchange: 'https://otcapi-staging.blockoville.com/',
  home: 'https://otc-staging.blockoville.com/',
  walletConnectURL: 'https://otc-deposit-staging.blockoville.com/',
  wcSupportedChains: {
    "Ethereum Testnet Goerli": 5,
    "Ethereum Testnet Sepolia": 11155111,
    "Ethereum Testnet Rinkeby": 4,
    "Dogecoin Testnet": 568,
    "Ethereum Classic Testnet Mordor": 6,
    "BNB Smart Chain Testnet": 97
  }
};
