import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from "../services/user.service";
import { NotificationService } from '../notification/notification.service';
import { PagerService } from "../services/pager.service";
import { DatePipe } from '@angular/common';
import { ConfigurationService } from '../services/configuration.service';
import * as XLSX from 'xlsx';
import { StorageService } from '../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, CsvExportParams, GridApi, GridReadyEvent, PaginationChangedEvent } from 'ag-grid-community';
import * as moment from 'moment';
import { AgCustomButtonComponent } from '../ag-custom-button/ag-custom-button.component'
@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})
export class ReferralComponent implements OnInit {

  referrallinks = "";
  copytext = "copy";
  public usersreferal: any = [];
  pager1: any = {};
  Tpager: number;
  pager: number;
  FilPage: number;
  FilPerpage: number;
  currentPage: number;
  fileName: string;
  ref_total_users: number;
  refbonus_total_users: number;
  searchObj: any = {
    search: "",
    searchref: ""
  };
  phonecodelist: any[];
  profile = {
    phonecode: '+91',
  }
  req: any = {
    bonus_currency: '',
    _csrf: null,
  }

  pager2: any = {};
  Tpagerbon: number;
  pagerbonust: number;
  FilPagebon: number;
  FilPerpagebon: number;
  currentPagebon: number;
  public usersreferal_bonus: any = [];
  referalcurrency = [];
  referralCode:any;
  selectedReferralCurrency: string = 'INR';
  registerForm: FormGroup;
  public referralUserHistoryColumnDefs: ColDef[] = [
    { field: 'action', headerName: 'Action', flex: 1, cellRendererFramework: AgCustomButtonComponent},
    { field: 'name', headerName: 'Client Legal Name', flex: 1 },
    { field: 'short_name', headerName: 'Client Short Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'status', headerName: 'User Status', flex: 1 },
    { field: 'referal_ask_price_spread', headerName: 'Referral Ask Price Spread', flex: 1 },
    { field: 'referal_bid_price_spread', headerName: 'Referral Bid Price Spread', flex: 1 },
    { field: 'kycstatus', headerName: 'KYC Status', flex: 1 },
    {
      field: 'createdAt', headerName: 'Created At', sort: 'desc', flex: 1,
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    }
  ];
  public referralUserHistoryDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable:true,
    enableCellChangeFlash: true
  };
  public referralUserHistoryRowData: any[] = [];
  @ViewChild(AgGridAngular) referralUserHistoryAgGrid!: AgGridAngular;
  referralUserHistoryGridApi!: GridApi;
  // For user bonus history
  public referralBonusHistoryColumnDefs: ColDef[] = [
    { field: 'name', headerName: 'User Name', flex: 1 },
    { field: 'currency', headerName: 'Currency', flex: 1 },
    { field: 'amount', headerName: 'Bonus Amount', flex: 1 },
    { field: 'description', headerName: 'Description', flex: 1 },
    {
      field: 'createdAt', headerName: 'Created At', sort: 'desc', flex: 1,
      valueFormatter: param => {
        if (param.value) {
          return moment(param.value).format('YYYY-MM-DD HH:mm:ss a');
        }
        return '';
      }
    }
  ];
  public referralBonusHistoryDefaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable:true,
    enableCellChangeFlash: true
  };
  public referralBonusHistoryRowData: any[] = [];
  @ViewChild(AgGridAngular) referralBonusHistoryAgGrid!: AgGridAngular;
  referralBonusHistoryGridApi!: GridApi;
  constructor(private userService: UserService,
    private notifyService: NotificationService,
    private pagerService: PagerService,
    private configService: ConfigurationService,
    private storageService : StorageService,
    public translate: TranslateService,
    public fb : FormBuilder,
    private cdr: ChangeDetectorRef
    ) { }

  ngOnInit(): void {
    
    this.csrf();
    this.FilPerpage = 10;
    this.FilPage = 1;
    this.pager = 1;
    this.FilPerpagebon = 10;
    this.FilPagebon = 1;
    this.pagerbonust = 1;
    this.getProfile();    
    this.initForm();
    this.getPhone();
  }
  onReferralCurrencyChange(newValue: string): void {
    this.selectedReferralCurrency = newValue;
    this.req.bonus_currency = this.selectedReferralCurrency;
  }
  // For Register referral user
  initForm(): any {
    this.registerForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]], 
      short_name: ['', [Validators.required, Validators.minLength(2)]], 
      email: ['',  [
        Validators.required,
        Validators.email, // Built-in email validator
        this.emailValidator, // Custom email validator
      ]],
      phonecode : ['+91', [Validators.required]],
      phone: [
        '',
        [
          Validators.required,
        ],
      ],
      referal_ask_price_spread : ['',[Validators.min(0)]],
      referal_bid_price_spread : ['',[Validators.min(0)]],
    }) 
  }
  emailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const email = control.value as string;  
    if (email && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      return { 'invalidEmail': true };
    }
  
    return null;
  }
  getPhone() {
    this.userService.country().subscribe(
      (res) => {
        var arr = res.sort(this.sort_by("phonecode", true, parseInt));
        var result = arr.reduce((unique, o) => {
          if (!unique.some((obj) => obj.phonecode === o.phonecode)) {
            unique.push(o);
          }
          return unique;
        }, []);
        this.phonecodelist = result;
      },
      (err) => {
        console.log(err);
      }
    );
  }
  sort_by(field, reverse, primer) {
    var key = primer
      ? function (x) {
          return primer(x[field]);
        }
      : function (x) {
          return x[field];
        };
    reverse = [-1, 1][+!!reverse];
    return function (a, b) {
      return (a = key(a)), (b = key(b)), reverse * (+(a > b) - +(b > a));
    };
  }
  saveRegisterForm() :any { 
    if (this.registerForm.valid) {
      this.userService.csrf().subscribe((success) => {
        this.registerForm.value['_csrf'] = success['_csrf'];
        console.log(this.registerForm.value, 'formvalue')
        this.userService.saveReferralUser(this.registerForm.value).subscribe(
          (data) => {
            if (data.status) {
              if (this.storageService.getLanguage() == 'es') {
                this.notifyService.showSuccess("", data.messagees)
              } else {
                this.notifyService.showSuccess("", data.message)
              }
              // Reset the form fields
              this.registerForm.reset();
      
              // Manually clear validation errors from form controls
              Object.keys(this.registerForm.controls).forEach((key) => {
                this.registerForm.get(key)?.setErrors(null);
              });
            } else this.notifyService.showError("", data.message)
          },
          (error) => this.notifyService.showError('', error)
        );
      });
    }
    
    
  }
  // STARTS USER REFERRAL HISTORY TABLE
  getReferralUserHistoryRowNodeId(data: any) {
    return data['_id']
  }
  onReferralHistoryGridReady(params: GridReadyEvent) {
    this.referralUserHistoryGridApi = params.api;
    this.referralUserHistoryGridApi.sizeColumnsToFit();
    this.referralUserHistoryGridApi.setDomLayout('autoHeight');
  }
 
  bindReferralUserHistoryGrid(data: any) {
    this.referralUserHistoryGridApi?.setRowData([]);
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.referralUserHistoryGridApi?.getRowNode(dt['_id']);
        if (selectedNode) {
          this.referralUserHistoryGridApi?.applyTransaction({ update: [dt] });
        } else {
          this.referralUserHistoryGridApi?.applyTransaction({ add: [dt] })
        }
      }
    }
  }
  bindSingleReferralHistory(dt: any) {
    let selectedNode = this.referralUserHistoryGridApi.getRowNode(dt['_id']);
    if (selectedNode) {
      this.referralUserHistoryGridApi.applyTransaction({ update: [dt] });
    } else {
      this.referralUserHistoryGridApi.applyTransaction({ add: [dt] })
    }
  }
  exportToCsv() {
    const params: CsvExportParams = {
      fileName: 'referralUserHistory.csv',
    };

    this.referralUserHistoryGridApi.exportDataAsCsv(params);
  }
  applyReferralUserHistoryFilter(event: any) {
    this.referralUserHistoryGridApi.setQuickFilter(event.target.value)
  }
 
  // STARTS REFRERRAL BONUS HISTORY
  onReferralBonusGridReady(params: GridReadyEvent) {
    this.referralBonusHistoryGridApi = params.api;
    this.referralBonusHistoryGridApi.sizeColumnsToFit();
    this.referralBonusHistoryGridApi.setDomLayout('autoHeight');
  }
  getReferralBonusHistoryRowNodeId(data: any) {
    return data['_id']
  }
  bindBonusHistoryGrid(data: any) {
    this.referralBonusHistoryGridApi?.setRowData([]);
    if (data && data.length > 0) {
      for (let dt of data) {
        let selectedNode = this.referralBonusHistoryGridApi?.getRowNode(dt['_id']);
        if (selectedNode) {
          this.referralBonusHistoryGridApi?.applyTransaction({ update: [dt] });
        } else {
          this.referralBonusHistoryGridApi?.applyTransaction({ add: [dt] })
        }
      }
    }
  }
  exportToCsvForBonus() {
    const params: CsvExportParams = {
      fileName: 'referralBonusHistory.csv',
    };

    this.referralBonusHistoryGridApi.exportDataAsCsv(params);
  }
  getReferralBonusHistory() {
    this.getBonus();
  }
  applyReferralBonusHistoryFilter(event: any) {
    this.referralBonusHistoryGridApi.setQuickFilter(event.target.value)
  }
  async csrf() {
    this.userService.csrf().subscribe((response) => {
      this.req._csrf = response._csrf;     
      this.getBonus();
      this.getRefset();
      this.getReferrals();
    }, (err) => {

    });
  }

  getProfile() {
    this.userService.getProfileAPI().subscribe((response) => {
      this.referralCode =response.referal_code;
      this.profile.phonecode =response.phonecode;
      var currentUrl = window.location.origin;
      this.selectedReferralCurrency = response.bonus_currency;
      this.referrallinks = currentUrl + "/contactus?ref_code=" + response.referal_code;
    });
  }

  updateReferral() {
    this.userService.updateReferral(this.req).subscribe(res => {
      if (res.status) {
        if (this.storageService.getLanguage()=='es') {
          this.notifyService.showSuccess("", res.messagees)
        }else{
          this.notifyService.showSuccess("", res.message)
        }
        
      } else {
        if (this.storageService.getLanguage()=='es') {
          this.notifyService.showError("", res.messagees)
        }else{
          this.notifyService.showError("", res.message)
        }
      }
    });
  }

  getRefset() {
    this.configService.getRefsettings().subscribe(Referral => {
      var bons = Referral.result[0].bonussentcur;
      var bonus_currency = Referral.result[0].bonussentcur;
      this.referalcurrency = bonus_currency;
    });
  }
  copy(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 9000);
    this.copytext = "copied";
  }

  setPage(page: number) {
    // get pager object from service
    this.pager1 = this.pagerService.getPager(this.Tpager, page);
  }

  setPageOnClick(page: number) {
    this.pager = page;
    this.FilPage = this.pager;
    this.getReferrals();
  }



  setPages(page: number) {
    // get pager object from service
    this.pager2 = this.pagerService.getPager(this.Tpagerbon, page);
  }

  setPageOnClicks(page: number) {
    this.pagerbonust = page;
    this.FilPagebon = this.pagerbonust;
    this.getBonus();
  }

  getReferrals() {
    this.userService.getReferralsAPI(this.FilPerpage, this.FilPage, this.searchObj.searchref).subscribe((response) => {
      this.usersreferal = response.referal_user;
      this.referralUserHistoryRowData = this.usersreferal;
      if(response.referal_user) this.bindReferralUserHistoryGrid(response.referal_user);
      this.cdr.detectChanges();
      this.ref_total_users = response.totalori_record;
      this.Tpager = response.pages;
      this.currentPage = response.current ? response.current : 1;
      this.setPage(this.FilPage);
      this.FilPerpage = 10;
      this.FilPage = 1;
      this.pager = 1;
    });
  }
  getBonus() {
    this.userService.getReferralBonusAPI(this.FilPerpagebon, this.FilPagebon, this.searchObj.search).subscribe((response) => {
      if (response?.status) {
        this.usersreferal_bonus = response.referal_user;
        this.referralBonusHistoryRowData = this.usersreferal_bonus;
        this.bindBonusHistoryGrid(response.referal_user)
        this.refbonus_total_users = response.totalori_record;
        this.Tpagerbon = response.pages;
        this.currentPagebon = response.current ? response.current : 1;
        this.setPages(this.FilPagebon);
        this.FilPerpagebon = 10;
        this.FilPagebon = 1;
        this.pagerbonust = 1;
      }
    });
  }

  resetBonus() {
    this.searchObj.search = "";
    this.getBonus();
  }

  resetreferrals() {
    this.searchObj.searchref = "";
    this.getReferrals();
  }

  dateformat(value) {
    return new DatePipe('en-Us').transform(value, 'dd-M-yyyy', 'GMT+2');
  }

  onBtnClick1(e) {
    alert();
  }


}
