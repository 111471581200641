import { Component, OnInit } from '@angular/core';
import { ExchangeService } from "../services/exchange.service";
import { UserService } from "../services/user.service";
import { NotificationService } from '../notification/notification.service';
import { ActivatedRoute, Router } from "@angular/router";
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-generate-password',
  templateUrl: './generate-password.component.html',
  styleUrls: ['./generate-password.component.css']
})
export class GeneratePasswordComponent implements OnInit {

  constructor(private userService: UserService,
    private exchageService: ExchangeService,
    private notifyService: NotificationService,
    private router: Router,
    private actroute: ActivatedRoute,
    private storageService : StorageService
    ) { }
  resetPassword = {
    userid: '',
    password: '',
    confirmpassword: '',
    _csrf: null
  };
  errorObj = {
    password: ''
  }
  submit: boolean;
  navstatus = false
  ngOnInit(): void {
    this.resetPassword.userid = this.actroute.snapshot.paramMap.get('id');
    this.userService.csrf().subscribe((response) => {
      this.resetPassword._csrf = response._csrf;
      this.userService.checkPasswordLink(this.resetPassword).subscribe(async (response) => {
        if (response.result == true) {
          await this.router.navigate(['linkexpire']);
        }
      }, (err) => {
        console.error(err)
      })
    }, (err) => {
      this.notifyService.showError('', err.error.message);
    });
  }
  clearError(field) {
    switch (field) {
      case 'password':
        this.errorObj.password = '';
        break;
    }
  }
  reset() {
    this.submit = true;
    if (JSON.stringify(this.resetPassword).search('""') != -1) {
      return false
    } else {
      var strongRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$", "g").test(this.resetPassword.password);
      var spacecheck = /\s/.test(this.resetPassword.password);
      if (strongRegex && !spacecheck) {
        this.exchageService.setPassword(this.resetPassword).subscribe((response) => {
          if (this.storageService.getLanguage()=='es') {
            this.notifyService.showSuccess("", response.messagees)
          }else{
            this.notifyService.showSuccess("",response.message)
          }
         
          setTimeout(() => {
            this.router.navigateByUrl('/login');
          }, 1000)
        }, (err) => {
          this.notifyService.showError("", err.error.message)
        });
      }
    }
  }
  back() {
    this.router.navigateByUrl('/');
  }
  checkpassword(val) {
    var strongRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$", "g");
    if (this.resetPassword.password === '') {
      this.clearError('password');
    }
    else if (/\s/.test(val)) {
      if (this.storageService.getLanguage()=='es') {
        this.errorObj.password = 'Ärge kasutage tühikuid';

      }else{
        this.errorObj.password = 'Do not use whitespace';

      }
    }
    else if (strongRegex.test(val)) {
      // this.errorObj.password = 'Password is Strong';
    }
    else {
      if (this.storageService.getLanguage()=='es') {
        this.errorObj.password = 'Parool on nõrk';

      }else{
        this.errorObj.password = 'Password is Weak';

      }
     
    }
  }
}
