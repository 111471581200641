import { Component, OnInit, HostListener } from '@angular/core';
import * as $ from 'jquery';
import { UserService } from "../services/user.service";
import { Router, NavigationEnd } from "@angular/router";
import { NotificationService } from '../notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services/storage.service';

@HostListener('scroll', ['$event'])
@HostListener('document:click', ['$event'])
@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.css'],
})

export class TopHeaderComponent implements OnInit {
  status: boolean = false;

  adminList = {
    name: ''
  };
  accountDetails = {
    name: '',
    _id: ''
  };
  companyWalletsList: Array<any>;
  admindetails = {
    _id: ''
  }
  msgdetail = {
    _id: '',
    read: 0,
    message: '',
    _csrf: '',
  }
  ipdetail = {
    _csrf: ''
  }
  notificationobj = {
    limit: 10,
    skip: 0
  }
  isNavbarCollapsed: boolean = true;
  totalmessagecount: any = 10;
  profileimage: any;
  profileImageName: any;
  kycdet: any = {};
  private socket: any;
  userid: any;
  currentlang = "";
  LANG: any = 'ENG'
  currentRoute: string;
  allowCredit: boolean = false; 
  creditLimitLinks = [ '/credit-dashboard', '/credit-limit/USDT_INR', '/collateral-deposit'];

  constructor(public auth: UserService,
    private router: Router,
    // private socket: Socket,
    private notifyService: NotificationService,
    private translate: TranslateService,
    private storageService: StorageService) {
    var userd = this.auth.getUserDetails();
    this.userid = userd?._id

    var lang_storage = this.storageService.getLanguage()
    if (lang_storage == 'en') {
      this.storageService.setLanguage('en');
      this.LANG = 'ENG'
    }
    if (lang_storage == 'es') {
      this.storageService.setLanguage('es');
      this.LANG = 'EST'
    }

    router.events.filter(event => event instanceof NavigationEnd)
      .subscribe(event => {
        this.currentRoute = this.router.url.split('?')[0];          
      }
    );
  }
  messageList: Array<any> = [];
  messageCount: any = 0;
  id
  interval: any
  isOpen: any

  ngOnInit(): void {
    this.isOpen = false;
    if (!this.auth.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }
    //this.socket.connect();
    this.auth.getProfileAPI().subscribe((response) => {
      this.accountDetails.name = response.legalname ? response.legalname : response.name;
      this.accountDetails._id = response._id;
      this.profileImageName = response.profileimage ? response.profileimage : "";
      //this.profileimage = response.profileimage? this.imagepath+response.profileimage:"";
      this.profileimage = response.profileimage ? response.profileimage : "";
      this.allowCredit = response.allowCredit ? response.allowCredit : false;
      if (!this.allowCredit && (this.creditLimitLinks.includes(this.currentRoute)) ) {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showError("", "Pole ligipääsetav enne, kui administraator lubab");
        } else {
          this.notifyService.showError("", "Not accessible until admin allow");
        }
        this.router.navigateByUrl('/dashboard');
      }
    });

    $('.nav-link').click(function(){
        $('.navbar div').removeClass('show');
    });  

    $('.inside_top_header ul li.dropdown').hover(function () {
      $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeIn(500);
    }, function () {
      if (!$(this).find('.dropdown-menu').hasClass("show")) {
        $(this).find('.dropdown-menu').stop(true, true).delay(200).fadeOut(500);
      }
    });

    this.csrf();
  }
  csrf() {
  }

  changeLang(language: string) {
    this.storageService.setLanguage(language)
    this.translate.use(language);
    if (language == 'es') {
      this.currentlang = 'es'
      this.LANG = 'EST'
      this.ngOnInit();
    } else {
      this.currentlang = language;
      this.ngOnInit();
      this.LANG = 'ENG'
    }
  }

  changeProfileDropDown() {
    $(document).ready(function () {
      $(".dropdown").click(function () {
        $(this).find(".dropdown-menu").slideToggle("fast");
      });
    });
    $(document).on("click", function (event) {
      var $trigger = $(".dropdown");
      if ($trigger !== event.target && !$trigger.has(event.target).length) {
        $(".dropdown-menu").slideUp("fast");
      }
    });

    if ($('.inside_top_header ul li.dropdown').find('.dropdown-menu-profile').hasClass("show")) {
      $('.inside_top_header ul li.dropdown').find('.dropdown-menu-profile').stop(true, true).delay(200).fadeOut(500);
    }
    else {
      $('.inside_top_header ul li.dropdown').find('.dropdown-menu-profile').stop(true, true).delay(200).fadeIn(500);
    }
  }

  closemenu(){
    $('.navbar div').removeClass('show');
  }
}
