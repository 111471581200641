import {EventEmitter, Injectable, Output} from '@angular/core';
import {BankPayload, UserDetails, UserService} from "./user.service";
import {Observable} from "rxjs/Observable";

@Injectable({
    providedIn: 'root'
})

export class AdminService {

    @Output() groupupdate: EventEmitter<boolean> = new EventEmitter();
    private permission: string;
    constructor(private userService: UserService) {
    }
    private getMenuPermission(): string {

        this.permission = localStorage.getItem('permission');
        return this.permission;
    }
    public getPermission() {
        const token = this.getMenuPermission();
        if (token) {
            return token
        } else {
            return null;
        }
    }
    toggleGroupUpdate(data){
        this.groupupdate.emit(data.data);
    }
    //api for fetch user groups
    public getUserGroups(): Observable<any> {
        return this.userService.request('get', 'getgroups');
    }
    //api call for get admin user list
    public getadminusersList(perpage,page,search):Observable<any> {
        return this.userService.request('get','adminuser', null, {perpage:perpage,page:page,search:search});
    }
    //api for fetch user groups
    public getAdminMenus(): Observable<any> {
        return this.userService.request('get', 'adminmenu');
    }
    //api call for get admin user list
    public getGroupList(perpage,page,search):Observable<any> {
        return this.userService.request('get','grouplist', null, {perpage:perpage,page:page,search:search});
    }
    public changeGroupStatus(groupid,status):Observable<any> {
        return this.userService.request('get','changegroupstatus', null, {groupid:groupid,status:status});
    }
    //api for save user groups
    public addNewGroup(object): Observable<any> {
        return this.userService.request('post', 'savegroup',object);
    }
    public groupDelete(groupid): Observable<any> {
        return this.userService.request('get','deletegroup',null,{groupid:groupid});
    }
    public getGroupById(groupid): Observable<any> {
        return this.userService.request('get','groupbyid',null,{groupid:groupid});
    }
    public updateGroup(object): Observable<any> {
        return this.userService.request('post', 'updategroup',object);
    }
    public getAdminPanelMenus(): Observable<any> {
        return this.userService.request('get', 'menurules');
    }
    public getAllAdmins(): Observable<any> {
        return this.userService.request('get', 'getalladmin');
    }
    public getAdminUserById(userid): Observable<any> {
        return this.userService.request('get','adminuserbyid',null,{userid:userid});
    }
    public getipAddrbyIP(ipobj): Observable<any> {              
        return this.userService.request('post','getipaddrbyip',ipobj);
    }
    public addgetIntouch(obj): Observable<any> {
        return this.userService.request('post','addgetintouch',obj);
    }
}
