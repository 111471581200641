import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TradingService } from '../services/trading.service';
import { StorageService } from '../services/storage.service';
import { WalletsService } from '../services/wallets.service';
import { SocketService } from 'src/app/services/socket.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../notification/notification.service';
import { SocketWrapperService } from '../services/socket-wrapper.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-credit-dashboard',
  templateUrl: './credit-dashboard.component.html',
  styleUrls: ['./credit-dashboard.component.css']
})
export class CreditDashboardComponent implements OnInit {
  
  data: any = {};
  wallist: any = [];
  wallistFilter: any = [];
  ledger: any = [];
  ledgerFilter: any = [];
  currencylist: any = [];
  userId: string = '';
  updatedWallet: any = {};
  
  fiatCurrencies: any = [];

  isZeroBalanceChecked: boolean = false;
  selectedMarketPriceCurrency: string = 'EUR';
  selectedMarketPriceCurrencyImage: string = '';
  
  marketPrices: any = [];
  totalPortfolioValue: number = 0;

  creditLimit: number = 0;
  creditLimitMarketPrice: number = 0;

  collateralDeposit: number = 0;
  collateralDepositMarketPrice: number = 0;

  unrealizedProfit: number = 0; 
  unrealizedProfitPercentage: number = 0; 

  transferForm: FormGroup;
  
  selectedTo: string = 'USER';
  selectedFrom: string = 'CREDIT';

  selectedToBalance: number = 0;
  selectedFromBalance: number = 0;

  constructor(
    private userService: UserService,
    private tradeService: TradingService,
    private walletsService: WalletsService,
    private notifyService: NotificationService,
    private storageService : StorageService,
    private socketWrapper: SocketWrapperService,
    public fb : FormBuilder,
    private readonly socketService: SocketService,

  ) {

    this.userId = this.userService.getUserId();
    setTimeout(() => {
      this.socketService.subscribeWalletDataChannels(this.userId);
    }, 1000);

   }

  ngOnInit(): void {

    this.userService.csrf().subscribe((response) => {
      this.data['_csrf'] = response._csrf;
        this.getUserBlance();
        this.fetchLedger();
    });

    this.transferForm = this.fb.group({
      amount: ['', [Validators.required, ]],
      asset: ['BTC', [Validators.required, ]],
      to: ['USER', [Validators.required, ]],
      from: ['CREDIT', [Validators.required, ]],
    }) 

    this.socketService.onWalletDataReceive().subscribe(updatedWallet => {
      this.updatedWallet = updatedWallet;
      console.log("updatedWallet",this.updatedWallet)
      var foundIndex = this.wallist.findIndex(x => x._id == this.updatedWallet._id);
      if(foundIndex >= 0){
        this.wallist[foundIndex].availableBalance = this.updatedWallet.availableBalance;
      }else{
        this.wallist = [this.updatedWallet, ...this.wallist]
      }
      this.changeMarketPriceCurrency();
      this.hideZeroBalance();
      this.transferFormChange('');
    });


    this.socketWrapper.on('connect',()=>{
      console.log('-------------------socket connected--------------------------');
      setTimeout(() => {
        this.socketService.subscribeWalletDataChannels(this.userId);
      }, 1000);
    });

    this.socketWrapper.on('disconnect',()=>{
      console.log('-------------------socket disconnected--------------------------');
    });



  }

  displayedColumns = ['position', 'name', 'weight', 'symbol'];
  displayedLedgerColumns = ['createdAt','txnID', 'txnref','Assets', 'txnType','type', 'amount', 'availableBalance'];
  
  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
    
  }

  getUserBlance() {
    this.userService.getMarketPrices().subscribe(async (marketPrices) => {
      this.marketPrices = marketPrices.result;
      this.walletsService.getUserBlance(this.data).subscribe(async (result) => {
        if (result.wallist) {
          this.wallist = result.wallist;
          this.changeMarketPriceCurrency();
          this.wallistFilter = this.wallist.sort((a, b) => b.value - a.value);
          this.hideZeroBalance();
          this.transferFormChange('');
        }
      }, (err) => {
        console.log(err);
      });

      this.walletsService.getUserCreditLimit(this.data).subscribe(async (result) => {
        if (result.status && result.data && result.data.amount) {
          this.creditLimit = result.data.amount
          this.collateralDeposit = result.data.collateral_deposit_amount
          this.changeMarketPriceCurrency();
        }
      }, (err) => {
        console.log(err);
      });
    });
  }

  fetchLedger() {
    this.tradeService.getCurrencies().subscribe(list => {
      this.currencylist = list;
      this.fiatCurrencies = list.filter(currency => currency.CurrencyType == 'FIAT');
      var selectedMarketPriceCurrencyImage = this.fiatCurrencies.find(currency => currency.CurrencySymbol == this.selectedMarketPriceCurrency);
      this.selectedMarketPriceCurrencyImage = selectedMarketPriceCurrencyImage.Icon;
      this.userService.getLedgerlistbyuserid(this.data, '10', '1', '', '', 'CREDIT',false).subscribe((result) => {
        this.ledger = []
        this.ledgerFilter = [];
        this.ledger = result.ledger.map(v => ({...v, image: this.currencylist.find(e => e.code === v.currency )}));
        this.ledgerFilter = result.ledger.map(v => ({...v, image: this.currencylist.find(e => e.code === v.currency )})).slice(0,10);
      }, (withdrawerr) => {
        this.notifyService.showError('', withdrawerr.error.message);
      })
    })
  }

  dateformat(value) {
    return new DatePipe('en-Us').transform(value, 'dd-MM-yyyy HH:mm:ss', 'GMT+2');
  }

  hideZeroBalance(){
    if(this.isZeroBalanceChecked){
      this.wallistFilter = this.wallist.filter(wallet => wallet.availableBalance > 0 && wallet.walletType == 'CREDIT');
    }else{
      this.wallistFilter = this.wallist.filter(wallet => wallet.walletType == 'CREDIT');
    }
  }

  changeMarketPriceCurrency(){
    this.totalPortfolioValue = 0;
    this.creditLimitMarketPrice = 0;
    var collateralMarketPrice = this.marketPrices.find(
      e => (e.BaseCurrency === 'EUR' && e.QuoteCurrency === this.selectedMarketPriceCurrency));
    if(typeof collateralMarketPrice !== 'undefined'){
      this.collateralDepositMarketPrice = this.collateralDeposit * collateralMarketPrice.CurrentPrice;
      this.creditLimitMarketPrice = this.creditLimit * collateralMarketPrice.CurrentPrice;
    }

    this.wallist = this.wallist.map(v => {
      var Price = this.marketPrices.find(e => (e.BaseCurrency === v.currency && e.QuoteCurrency === this.selectedMarketPriceCurrency))
      if(typeof Price !== 'undefined'){
        v.marketPrice = Price.CurrentPrice;
        v.value = v.availableBalance * Price.CurrentPrice;
        if(v.walletType == 'CREDIT'){
          this.totalPortfolioValue = this.totalPortfolioValue + v.value;
        }
      }else{
        v.marketPrice = 0;
        v.value = 0;
      }
      if(typeof v.icon == 'undefined'){
        var CurrencyImage =  this.currencylist.find(e => e.CurrencySymbol === v.currency );
        v.icon = CurrencyImage.Icon;
      }
      return v;
    }
    );
   var selectedMarketPriceCurrencyImage = this.fiatCurrencies.find(currency => currency.CurrencySymbol == this.selectedMarketPriceCurrency);
   this.selectedMarketPriceCurrencyImage = selectedMarketPriceCurrencyImage.Icon;
   this.unrealizedProfit = this.totalPortfolioValue - this.creditLimitMarketPrice;
   if(this.unrealizedProfit && this.creditLimitMarketPrice)
   this.unrealizedProfitPercentage = ((this.unrealizedProfit/this.creditLimitMarketPrice)*100); 

  }

  submitTransferForm() :any { 
    if (this.transferForm.valid) {
      var asset = this.transferForm.get('asset').value;
      var from = this.transferForm.get('from').value;
      var to = this.transferForm.get('to').value;
      var amount = this.transferForm.get('amount').value;

      if(from == 'USER'){
        if(Number(amount) > Number(this.selectedFromBalance)){
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", "Pole piisavalt kasumit")
          } else {
            this.notifyService.showError("", "Don't have a enough balance")
          }
          return;
        }
      }else{
        var Price = this.marketPrices.find(
          e => (e.BaseCurrency === asset && e.QuoteCurrency === this.selectedMarketPriceCurrency));

        if(typeof Price == 'undefined') {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", "Turuhinda ei värskendata, proovige mõne aja pärast")
          } else {
            this.notifyService.showError("", "Market Price is not updated please try after some time")
          }
          return;
        }
        var transferMarketPrices = amount * Price.CurrentPrice;
        if(Number(this.unrealizedProfit) < Number(transferMarketPrices)){
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", "Pole piisavalt kasumit")
          } else {
            this.notifyService.showError("", "Don't have a enough profit")
          }
          return;
        }
      }

      this.transferForm.value['_csrf'] = this.data['_csrf'];
      console.log(this.transferForm.value, 'formvalue')
      this.walletsService.fundTransfer(this.transferForm.value).subscribe(
        (data) => {
          if (data.status) {
            if (this.storageService.getLanguage() == 'es') {
              this.notifyService.showSuccess("", data.messagees)
            } else {
              this.notifyService.showSuccess("", data.message)
            }
            // // Reset the form fields
            // this.transferForm.reset();
    
            // // Manually clear validation errors from form controls
            // Object.keys(this.transferForm.controls).forEach((key) => {
            //   this.transferForm.get(key)?.setErrors(null);
            // });
          } else this.notifyService.showError("", data.message)
        },
        (error) => this.notifyService.showError('', error)
      );
    }
    
  }

  transferFormChange(type) {
    var asset = this.transferForm.get('asset').value;
    var from = this.transferForm.get('from').value;
    var to = this.transferForm.get('to').value;
    var amount = this.transferForm.get('amount').value;

    var userWallet = this.wallist.find(e => (e.currency === asset && e.walletType === 'USER'));
    var creditWallet = this.wallist.find(e => (e.currency === asset && e.walletType === 'CREDIT'));
    if(from == 'USER'){
      this.selectedToBalance = creditWallet && creditWallet.availableBalance || 0;
      this.selectedFromBalance = userWallet.availableBalance || 0;
    }else{
      this.selectedToBalance = userWallet.availableBalance || 0;
      this.selectedFromBalance = creditWallet && creditWallet.availableBalance || 0;
    }

    if(type == 'from'){
      if(from == 'USER'){
        this.transferForm.controls['to'].setValue('CREDIT');
      }else{
        this.transferForm.controls['to'].setValue('USER');
      }
    }
    //console.log(this.transferForm.value, 'formvalue')

  }

  round(value, float = 0) {
    return parseFloat(value).toFixed(float);
  }
}
