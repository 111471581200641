import {Injectable} from '@angular/core';
import {UserService} from "../services/user.service";
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';


@Injectable({
    providedIn: 'root'
})

export class ConfigurationService {
    constructor(private http: HttpClient, private router: Router, private userService: UserService) {
    }

    public getRefsettings(): Observable<any> {
        return this.userService.userequest('get', 'getreferral');
    }
}
