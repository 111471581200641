import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { UserService } from "../services/user.service";

@Injectable()
export class HomeService {
  token: string;
  api_url: string = environment.apiEndPoint;

  constructor(private http: HttpClient, private userSvc: UserService) {}

  geAllHomes() {
    const configs = {
      withCredentials: true,
      headers: { Authorization: `Bearer ${this.userSvc.getToken()}` },
    };

    return this.http.get<any>(this.api_url + "home");
  }
  getHome(id) {
    return this.http.get<any>(this.api_url + "home/" + id);
  }

//   createCandidate(candidate) {
//     return this.http.post<any>(this.api_url + "candidates", candidate, {
//       withCredentials: true,
//     });
//   }
//   getCms() {
//     return this.http.get<any>(this.api_url + "cms");
//   }
}
