import { Component, OnInit } from '@angular/core';
import { UserService} from "../services/user.service";
import { Router} from "@angular/router";

import { NotificationService } from '../notification/notification.service';
import { Location } from '@angular/common';
import { StorageService } from '../services/storage.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  reset:any = {
    email:'',
    _csrf:''
  }
  verificationstr = '';
  submit:boolean = false ;
  constructor(
    private userService: UserService,
    private router: Router,
    private notifyService : NotificationService,
    private location: Location,private storageService : StorageService,
    public translate: TranslateService) {
     }
order
  ngOnInit() {
    if (this.userService.isLoggedIn()) {
      this.router.navigateByUrl('/profile');
    } else {
    }
  }
  back() {
    this.location.back();
  }
  resetUser(){
    this.submit = true;
    var email= {
      email:this.reset.email
    }
    var validateemail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(this.reset.email);
    if(validateemail){
      this.userService.verifyMailId(email).subscribe((result)=>{
        if(result.result != ''){
          this.userService.resetEmail(this.reset.email,this.storageService.getLanguage()).subscribe((result) => {
            if (this.storageService.getLanguage()=='es') {
              this.notifyService.showSuccess("",  'Teile saadetakse meilisõnum parooli lähtestamiseks')
            }else{
              this.notifyService.showSuccess("",'You will have received email for reset password')
            }
            this.router.navigate(['reset']);
        }, (err) => {
          if (this.storageService.getLanguage()=='es') {
            this.notifyService.showError('', err.error.messagees);
          }else{
            this.notifyService.showError('', err.error.message);
          }
           
        })
        }else {
          if (this.storageService.getLanguage()=='es') {
            this.notifyService.showError('', result.messagees);
          }else{
            this.notifyService.showError('', result.message);
          }
        }
      },(err)=>{
        this.notifyService.showError('', err.error.message);
      });
    }else{
      // this.notifyService.showError('', 'Please Enter Valid Email ID');
    }
  }
}
