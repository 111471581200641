import {EventEmitter, Injectable, Output} from '@angular/core';
import {UserService} from "./user.service";
import {Observable} from "rxjs/Observable";

export interface reset {
    password?: string;
    confirmpassword?:String
    otpcodeData?:String
    target?:String
    _csrf?:String,
}


@Injectable({
    providedIn: 'root'
})

export class ExchangeService {

    constructor(private userService: UserService) {
    }
    public resetPassword(data,lang): Observable<any> {
        return this.userService.userequest('post', 'setforgetpasswordal?lang='+lang,  data )
    }
    public setPassword(data): Observable<any> {
        return this.userService.userequest('post', 'setpassword',  data )
    }
    public getEmailVerified(id): Observable<any> {
        return this.userService.userequest('get', 'accountverify', null, { str: id })
    }
}
