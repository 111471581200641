import { Component, OnInit } from '@angular/core';
import { UserService } from "../services/user.service";
import { Router } from "@angular/router";
import { reset, ExchangeService } from "../services/exchange.service";
import { NotificationService } from '../notification/notification.service';
import { Location } from '@angular/common';
import { StorageService } from '../services/storage.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetPassword: reset = {
    password: '',
    confirmpassword: '',
    otpcodeData: '',
    target: '',
    _csrf: null
  };
  errorObj = {
    password: ''
  }
  submit: boolean;
  constructor(private userService: UserService,
    private router: Router,
    private exchageService: ExchangeService,
    private notifyService: NotificationService,
    private location: Location,
    private storageService : StorageService,
    public translate: TranslateService

  ) {
  }
  back() {
    this.location.back();
  }
  ngOnInit() {
    if (this.userService.isLoggedIn()) {
      this.router.navigateByUrl('/profile');
    } else {
      this.csrf();
    }
  }
  reset() {
    this.submit = true;
    if (JSON.stringify(this.resetPassword).search('""') != -1) {
      return false
    } else {
      var strongRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$", "g").test(this.resetPassword.password);
      var spacecheck = /\s/.test(this.resetPassword.password);
      if (strongRegex && !spacecheck) {
        this.exchageService.resetPassword(this.resetPassword,this.storageService.getLanguage()).subscribe((response) => {
          if (response.result) {
            var userDetail = response.result;
            var userId = { user_id: userDetail._id };
            if (this.storageService.getLanguage()=='es') {
              this.notifyService.showSuccess("", response.messagees)
            }else{
              this.notifyService.showSuccess("", response.message)
            }
            
            setTimeout(() => {
              this.router.navigateByUrl('/login');
            }, 1000)
          }

        }, (err) => {
          if (this.storageService.getLanguage()=='es') {
            this.notifyService.showError("", err.error.message)
          }else{
            this.notifyService.showError("", err.error.message)
          }
        });

      } else {
        console.log('password change');
      }
    }
  }
  csrf() {

    this.userService.csrf().subscribe((response) => {
      this.resetPassword._csrf = response._csrf;
    }, (err) => {
    });
  }
  clearError(field) {
    switch (field) {
      case 'password':
        this.errorObj.password = '';
        break;
    }
  }
  checkpassword(val) {
    var strongRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$", "g");

    if (this.resetPassword.password === '') {
      this.clearError('password');
    }
    else if (/\s/.test(val)) {
      if (this.storageService.getLanguage()=='es') {
        this.errorObj.password = 'Ärge kasutage tühikuid';
      }else{
        this.errorObj.password = 'Do not use whitespace';
      }
     
    }
    else if (strongRegex.test(val)) {
      // this.errorObj.password = 'Password is Strong';
    }
    else {
      if (this.storageService.getLanguage()=='es') {
        this.errorObj.password = 'Parool on nõrk';
      }else{
        this.errorObj.password = 'Password is Weak';
      }
    
    }

  }
}
