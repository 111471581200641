import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery'; 
import { environment } from '../../environments/environment';
import { KycserviceService } from '../services/kycservice.service'
import io from 'socket.io-client';
import {UserService} from '../services/user.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  private socket: any;
  userid:any;
  constructor(private router: Router,
              private kycService: KycserviceService,
              private userService: UserService,) { 
                var userd = this.userService.getUserDetails();
                this.userid= userd._id
              }
  envcheck:any;
  colordiff:any;
  kycdet:any={}
  ngOnInit(): void {
    this.jqFunc();
    this.envcheck=environment.name;
    if(this.envcheck === 'staging'){
      this.colordiff='sidebar-content test'
    }else if (this.envcheck === 'local'){
      this.colordiff='sidebar-content '
    }else if(this.envcheck === 'production'){
      this.colordiff='sidebar-content test'
    }else{ }
    this.kycService.getUserKYC().subscribe(res =>{
      this.kycdet['status'] = res.status            
    },(err)=>{
      this.kycdet['status'] = 'Pending'      
    })
    // this.socket.on('kycstatus'+this.userid,data=>{
    //   this.kycService.getUserKYC().subscribe(res =>{
    //     this.kycdet['status'] = res.status            
    //   },(err)=>{
    //     this.kycdet['status'] = 'Pending'      
    //   })
    // })
  }
  jqFunc() {
    var rLink = this.router.url;
    var rLinkArr = rLink.split('/');
    if(typeof rLinkArr[1]!='undefined'){
      $(".sidebar-menu ul li.sinLi").removeClass("active");
      $(".sidebar-menu ul li."+rLinkArr[1]).addClass("active");
      $(".sidebar-menu ul li."+rLinkArr[1]+' .sidebar-submenu').show();
    }
    $("#close-sidebar").click(function() {
      $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function() {
      $(".page-wrapper").addClass("toggled");
    });
    $(".sidebar-dropdown > a").click(function() {
      $(".sidebar-submenu").slideUp(185);
      if ($(this).parent().hasClass("active")) {
        $(".sidebar-dropdown").removeClass("active");
        $(this).parent().removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this).next(".sidebar-submenu").slideDown(185);
        $(this).parent().addClass("active");
      }
    });
  }
}
