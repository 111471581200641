import { Directive, OnChanges, Input, SimpleChanges, ElementRef, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appHighlight]'
})
export class HighlightDirective implements OnChanges {

    @Input() public appHighlight: any;

    constructor(private elementRef: ElementRef,private renderer: Renderer2) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.appHighlight && changes.appHighlight.currentValue != changes.appHighlight.previousValue) {
            this.elementRef.nativeElement.style = "background-color: #d1d9ff;"
            setTimeout(() => {
                this.elementRef.nativeElement.style = "";
            }, 350);
        }
    }
}