import { Injectable } from '@angular/core';
import {UserService} from "../services/user.service";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class KycserviceService {
    api_url: string = environment.apiEndPoint;

    constructor(private http: HttpClient, private router: Router,private userService : UserService) {
    }

    /*
          api call for get KYC list
       */
    public getKycList(perpage,page,search):Observable<any> {
        return this.userService.request('get','customerskyc', null, {perpage:perpage,page:page,search:search});
    }

    /*
    * api call for kyc verify by id */
    public getKycVerifyById(id):Observable<any>{
        return this.userService.request('get','updatekyc',null,{id:id,status:"Approve"});
    }


    /* api call for Kyc Reject by id */
    public setKycReject(id):Observable<any>{
        return this.userService.request('get','updatekyc',null,{id:id,status:"Reject"});
    }

    /*
    * api call for Kyc Remove by id */
    public setKycRemove(id):Observable<any>{
        return this.userService.request('get','removekyc',null,{id:id});
    }

    /*
    * api call for kyc pending by id */
    public kycPending(id):Observable<any>{
        return this.userService.request('get','updatekyc',null,{id:id,status:"Pending"});
    }
    public getUserKYC():Observable<any> {
        return this.userService.request('get','getkyc');
    }

    public getKYC():Observable<any> {
        return this.userService.request('get','kycdetails');
    }
}
