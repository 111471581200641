import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ERROR_MESSAGES } from '../../../../src/consts/error.messages'
import { NotificationService } from '../../notification/notification.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from '../../services/storage.service';


@Component({
  selector: 'app-edit-referral',
  templateUrl: './edit-referral.component.html',
  styleUrls: ['./edit-referral.component.css']
})
export class EditReferralComponent implements OnInit {
  referralForm: FormGroup;
  keyName: any;
  errorMsg: typeof ERROR_MESSAGES = ERROR_MESSAGES;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private notifyService: NotificationService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.referralForm = this.fb.group({
      _id: [''],
      name: [''], 
      short_name: [''], 
      email: [''],
      phonecode : ['+91'],
      phone: [''],
      referal_ask_price_spread : ['',[Validators.min(0)]],
      referal_bid_price_spread : ['',[Validators.min(0)]],
    });
    this.route.params.subscribe(params => {
      if (params && params['id']) {
        this.getReferralUserById(params['id'])
      }
    })
  }

  submit() {
    this.userService.csrf().subscribe(success => {
      this.referralForm.value['_csrf'] = success._csrf;
      this.userService.updateReferralUserById(this.referralForm.value).subscribe(res => {
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showSuccess("", res.messagees)
        } else {
          this.notifyService.showSuccess("", res.message)
        }
      })
    })

  }

  cancel() {
    this.referralForm.reset();
    this.router.navigate(['apiconfig']);
  }
  getReferralUserById(id: any) {
    try {
      this.userService.getReferralUserById(id).subscribe(referralUser => {
        if (referralUser) {
          this.referralForm.controls["_id"].patchValue(referralUser._id);
          this.referralForm.controls["name"].patchValue(referralUser.name);
          this.referralForm.controls["short_name"].patchValue(referralUser.short_name);
          this.referralForm.controls["referal_ask_price_spread"].patchValue(referralUser.referal_ask_price_spread);
          this.referralForm.controls["referal_bid_price_spread"].patchValue(referralUser.referal_bid_price_spread);
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
}
