import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from "../../environments/environment";
import { UserService } from './user.service';
import { DeviceUUID } from 'device-uuid';

@Injectable()
export class SocketWrapperService extends Socket {

  constructor() {
    super({
      url: environment.SOCKET_SERVER, // socket server url;
      options: {
        transports: ['polling', 'websocket'],
        autoConnect: true,
        query: {
          uniqueId: new DeviceUUID().get(),
          token: localStorage.getItem('mean-token') || '',
        },
      }
    });
  }
}
