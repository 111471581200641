import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NgwWowModule } from 'ngx-wow';
import { ArchwizardModule } from 'angular-archwizard';
import { MatRadioModule } from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';

import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';

import { AppComponent } from './app.component';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
// import { ProfileComponent } from './profile/profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SideNavComponent } from './side-nav/side-nav.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { TopHeaderComponent } from './top-header/top-header.component';
import { HomeComponent } from './home/home.component';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { ProfileComponent } from './profile/profile.component';
import { GeneratePasswordComponent } from './generate-password/generate-password.component';
import { TransferComponent } from './transfer/transfer.component';
import { LedgerComponent } from './ledger/ledger.component';
import { CollateralDepositComponent } from './collateral-deposit/collateral-deposit.component';
 

// import { UserIdleModule } from 'angular-user-idle';


import { Globals } from "./app-globals";
import {UserService} from "./services/user.service";
import {KycserviceService} from "./services/kycservice.service";
import {RoutingstateService} from "./services/routingstate.service";
import {WalletsService} from "./services/wallets.service";
import {ConfigurationService} from './services/configuration.service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS} from "@angular/common/http";
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { GoogleChartsModule } from 'angular-google-charts';
import { JwPaginationModule } from 'jw-angular-pagination';
import {environment} from "../environments/environment";
import { TimeagoModule } from 'ngx-timeago';
import { BlockComponent } from './block/block.component';
import { LinkexpireComponent } from './linkexpire/linkexpire.component';
import { KycComponent } from './kyc/kyc.component';
import { SubmitComponent } from './submit/submit.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';

import { TradeComponent } from './trade/trade.component';
import { CreditLimitComponent } from './credit-limit/credit-limit.component';


import { DepositComponent } from './deposit/deposit.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ReferralComponent } from './referral/referral.component';
import { EditReferralComponent } from './referral/edit-referral/edit-referral.component';


import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { IntlModule } from '@progress/kendo-angular-intl';
import { SignupComponent } from './signup/signup.component';
import { ContentComponent } from './content/content.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StorageService } from './services/storage.service';
import { CareersComponent } from './careers/careers.component';
import { JobdescriptionsComponent } from './jobdescriptions/jobdescriptions.component';
import { CareerService } from './careers/career.service';
import { HomeService } from './home/home.service';
import { KycService } from './kyc/kyc.service';
import { SuccessComponent } from './kyc/success/success.component';
import { ZignsecFailureComponent } from './zignsec-failure/zignsec-failure.component';
import { MatIconModule } from '@angular/material/icon';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ApiconfigManagementComponent } from './apiconfig-management/apiconfig-management.component';
import { NewApiconfigManagementComponent } from './new-apiconfig-management/new-apiconfig-management.component';
import { MatButtonModule } from '@angular/material/button';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DisplayKeyPopupComponent } from './display-key-popup/display-key-popup.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CreditDashboardComponent } from './credit-dashboard/credit-dashboard.component';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { AgGridModule } from 'ag-grid-angular';
import { ToolTipComponent } from './ag-tooltip/tool-tip.component';
import { HighlightDirective } from './directive/hightlight.directive';
import { AgCustomImageComponent } from './ag-custom-image/custom-image.component';
import { AgCustomButtonComponent } from './ag-custom-button/ag-custom-button.component';
//import { DeviceUUID } from 'device-uuid';
import { SocketWrapperService } from './services/socket-wrapper.service';
import { SocketService } from './services/socket.service';
import { WithdrawConfirmPopupDialogComponent } from './withdraw-confirm-popup/withdraw-confirm-popup.component';
import { LoaderInterceptor } from './intercepter/loading.interceptor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpErrorInterceptor } from './intercepter/http.interceptor';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { MaintenanceComponent } from './maintenance/maintenance.component';
import { DisableComponent } from './disable/disable.component';

import { QRCodeModule } from 'angularx-qrcode';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    SideNavComponent,
    TopHeaderComponent,    
    HomeComponent,
    HomeHeaderComponent,
    BlockComponent,
    LinkexpireComponent,
    KycComponent,
    SubmitComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetpasswordComponent,
    ProfileComponent,
    GeneratePasswordComponent,

    TradeComponent,
    CreditLimitComponent,
    
    TransferComponent,
    DepositComponent,
    ContactusComponent,
    ReferralComponent,
    EditReferralComponent,
    
    SignupComponent,
    ContentComponent,
    CareersComponent,
    JobdescriptionsComponent,
    SuccessComponent,
    ZignsecFailureComponent,
    LedgerComponent,
    ApiconfigManagementComponent,
    NewApiconfigManagementComponent,
    DisplayKeyPopupComponent,

    DashboardComponent,
    CreditDashboardComponent,

    ConfirmationDialogComponent,
    HighlightDirective,
    ToolTipComponent,
    AgCustomImageComponent,
    AgCustomButtonComponent,
    WithdrawConfirmPopupDialogComponent,
    CollateralDepositComponent,

    MaintenanceComponent,
    DisableComponent

  ],
  imports: [
    BrowserModule,
    MatDialogModule,
    NgwWowModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTableModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgbModule,
    CarouselModule,
    NgxDatatableModule,
    HttpClientModule,
    AgGridModule.withComponents([ToolTipComponent,AgCustomImageComponent,AgCustomButtonComponent]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    FormsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    NgxSpinnerModule,
    GoogleChartsModule,
    JwPaginationModule,
    TimeagoModule.forRoot(),
    DateInputsModule,
    InputsModule,
    LabelModule,
    IntlModule,  
    ArchwizardModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule,
    AutocompleteLibModule,
    SocketIoModule,
    MatTooltipModule,
    MatTableModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatRadioModule,
    MatButtonModule,
    NgxCaptchaModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    QRCodeModule
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    UserService,
    KycserviceService,
    KycService,
    RoutingstateService,
    WalletsService,
    ConfigurationService,
    Globals,
    StorageService,
    CareerService,
    HomeService,
    SocketWrapperService,
    SocketService,
    
  ],
  entryComponents: [DisplayKeyPopupComponent, ConfirmationDialogComponent, WithdrawConfirmPopupDialogComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
 
  bootstrap: [AppComponent]
})
export class AppModule {

 }
