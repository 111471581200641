import { Injectable } from '@angular/core';
import { SocketWrapperService } from './socket-wrapper.service';

@Injectable()
export class SocketService {

  constructor(private socket: SocketWrapperService) {
    
  }

  subscribePriceConfigChannels(PriceConfig: any) {
    console.log(`subscribePriceConfigChannels: ` + PriceConfig)
    this.socket.emit('subscribePriceConfigChannels', 'OTC.'+PriceConfig);
  }

  unSubscribePriceConfigChannels(PriceConfig: any) {
    console.log(`unsubscribePriceConfigChannels: ` + PriceConfig)
    this.socket.emit('unsubscribePriceConfigChannels', 'OTC.'+PriceConfig);
  }

  subscribeOrderDataChannels(userId) {
    console.log(`subscribeOrderDataChannels: ` + userId)
    this.socket.emit('subscribeOrderDataChannels', userId);
  }

  unSubscribeOrderDataConfigChannels(userId) {
    console.log(`unsubscribeOrderDataChannels: ` + userId)
    this.socket.emit('unsubscribeOrderDataChannels', userId);
  }

  subscribeWalletDataChannels(userId) {
    console.log('subscribeWalletDataChannels: ' + userId)
    this.socket.emit('subscribeWalletDataChannels', userId);
  }

  unSubscribeWalletDataConfigChannels(userId) {
    console.log('unsubscribeWalletDataChannels')
    this.socket.emit('unsubscribeWalletDataChannels', userId);
  }

  subscribeTradeDataChannels(userId) {
    console.log(`subscribeTradeDataChannels: ` + userId)
    this.socket.emit('subscribeTradeDataChannels', userId);
  }

  unSubscribeTradeDataConfigChannels(userId) {
    console.log(`unsubscribeTradeDataChannels: ` + userId)
    this.socket.emit('unsubscribeTradeDataChannels', userId);
  }

  unSubscribeAllListeners() {
    console.log(`removeAllListeners: `)
    this.socket.removeAllListeners();
  }

  subscribeUserDataChannels(userId) {
    console.log(`subscribeUserDataChannels: ` + userId)
    this.socket.emit('subscribeUserDataChannels', userId);
  }

  unSubscribeUserDataConfigChannels(userId) {
    console.log(`unsubscribeUserDataChannels: ` + userId)
    this.socket.emit('unsubscribeUserDataChannels', userId);
  }

  onPriceConfigDataReceive() {
    return this.socket.fromEvent('onPriceConfigDataReceive');
  }

  onOrderDataReceive() {
    return this.socket.fromEvent('onOrderDataReceive');
  }

  onWalletDataReceive() {
    return this.socket.fromEvent('onWalletDataReceive');
  }

  onTradeDataReceive() {
    return this.socket.fromEvent('onTradeDataReceive');
  }

  onUserDataReceive() {
    return this.socket.fromEvent('onUserDataReceive');
  }
}
