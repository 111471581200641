import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { ProfilePayload, UserService } from "../services/user.service";
import { Router } from "@angular/router";
import { NotificationService } from '../notification/notification.service';
import { Location } from '@angular/common';
import { TradingService } from '../services/trading.service';
import { environment } from '../../environments/environment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormatSettings } from "@progress/kendo-angular-dateinputs";
import { StorageService } from '../services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {
  submit: boolean = false;
  countries: any[];
  phonecodelist: any[];
  states: any;
  profile: ProfilePayload = {
    _csrf: null,
    email: '',
    name: '',
    nickname: '',
    country: '',
    state: '',
    address: '',
    legalname: '',
    pin: '',
    language: '',
    timezone: '',
    phonecode: '',
    phone: 0,
    month: '06',
    year: '2020',
    date: '23',
    countrycode: '',
    authflag: true,
    userid: 0,
    profileimage: '',
    profileImage: '',
    confirmpassword: '',
    password: '',
    currentpassword: '',
    user_id: '',
    id_proof: '',
    idproof: '',
    dob: '',
    ubo_name: '',
    ubo_dob: '',
    ubo_phonecode: '',
    ubo_phone: 0,
    ubo_email: '',
    ubo_address: '',
    ubo_country: '',
    ubo_idproof: '',
    ubo_addressproof: '',
    ubo_check: 0,
    address_proof: ''
  };
  antiphis = {
    antiphiscode: '',
    _csrf: ''
  }
  errorObj = {
    password: ''
  }
  previousRoute: string;
  verificationstr = '';
  diables: Boolean;
  dateList: any = [];
  fileToUpload: File = null;
  passwordstatus: any = 'strong';
  nameMacher: any = false;
  imagepath = environment.imageapiendpoint;
  datemodel: NgbDateStruct;
  user_country: any = [];
  public today_date = new Date();
  public tyear = this.today_date.getFullYear() - 18;
  public tmonth = this.today_date.getMonth() + 1;
  public tday = this.today_date.getDate() + 1;
  public value: Date = new Date(this.tyear, this.tmonth, this.tday)
  public max: Date = new Date(this.tyear, this.tmonth, this.tday);

  public format: FormatSettings = {
    displayFormat: "dd/MM/yyyy",
    inputFormat: "dd/MM/yyyy",
  };

  public shownewPassword: boolean = false;
  public showcurrentPassword: boolean = false;
  public showconfirmPassword: boolean = false;

  constructor(private userService: UserService,
    private router: Router,
    private tradeService: TradingService,
    private notifyService: NotificationService,
    private location: Location,
    private storageService: StorageService,
    public translate: TranslateService


  ) {
  }

  public data: any = [];
  public wallist: any = {};
  formData = new FormData();
  twofastatus = 'Disabled';
  tfaobject = {
    _csrf: '',
    tfa_url: '',
    sixdig_code: '',
    tfa_codeencrypt: '',
    tfa_status: 0
  }
  spacecheck = true;
  ngOnInit() {

    this.tradeService.startLoader();
    this.data = {};
    this.data.test = 'test';
    this.csrf();
    this.getcountry();
    this.initialfunction();
    this.diables = true;
    this.tradeService.stopLoader();
  }

  initialfunction() {
    this.getProfile();
    this.userService.getAntiPhisCode(this.antiphis._csrf).subscribe((data) => {
      var phiscode = data.result;
      this.antiphis.antiphiscode = phiscode.antiphiscode;
    })
  }
  handleFileInput(files) {
    this.fileToUpload = files.item(0);
  }
  csrf() {
    this.userService.csrf().subscribe((response) => {
      this.profile._csrf = response._csrf;
      this.antiphis._csrf = response._csrf
      this.tfaobject._csrf = response._csrf;
    }, (err) => {
    });
  }
  getProfile() {
    this.userService.getProfileAPI().subscribe((response) => {
      if (response.tfa_status == 0) {
        this.twofastatus = (this.storageService.getLanguage() == 'es')?'Keelatud':'Disabled';
      } else {
        this.twofastatus = (this.storageService.getLanguage() == 'es')?'Lubatud':'Enabled';
      }
      this.tfaobject.tfa_status = response.tfa_status
      this.tfaobject.tfa_codeencrypt = response.tfa_code;
      this.tfaobject.tfa_url = response.tfa_url;
      this.profile.profileimage = response.profileimage;//for send api
      this.profile.profileImage = this.imagepath + response.profileimage;//for display front end
      this.profile['Email'] = response.email ? response.email : '';
      this.profile['userId'] = response.userid ? response.userid : (10000 + +response.userid);
      this.profile.email = response.email ? response.email : '';
      this.profile.country = response.country ? response.country : '';
      this.profile.country = this.profile.country.split(" ", 1).toString();
      this.profile.countrycode = response.countrycode ? response.countrycode : '4';
      this.profile.nickname = response.nickname ? response.nickname : '';
      this.profile.state = response.state ? response.state : 'Tamilnadu';
      this.profile.address = response.address ? response.address : '';
      this.profile.legalname = response.name ? response.name : '';
      this.profile.name = response.name ? response.name : '';
      this.profile.pin = response.pin ? response.pin : '';
      this.profile.language = response.language ? response.language : '';
      this.profile.timezone = response.timezone ? response.timezone : '';
      this.profile.phonecode = response.phonecode ? response.phonecode : '';
      this.profile.phone = response.phone ? response.phone : '';
      this.profile.userid = response.userid ? response.userid : (10000 + +response.userid);
      this.profile.user_id = response._id ? response._id : '';
      var req = {
        id: response.countrycode,
        _csrf: this.profile._csrf
      }
      if (this.profile.state == '') {
        this.userService.getState(req).subscribe(res => {
          this.states = res;
        }, (err) => {
          console.log(err);
        });
      }
    }, (err) => {
      this.notifyService.showError("", err.error.message)
    });
  }

  sort_by(field, reverse, primer) {
    var key = primer ?
      function (x) { return primer(x[field]) } :
      function (x) { return x[field] };
    reverse = [-1, 1][+!!reverse];
    return function (a, b) {
      return a = key(a), b = key(b), reverse * (+(a > b) - +(b > a));
    }
  }

  getcountry() {
    this.userService.country().subscribe(res => {
      var arr = res.sort(this.sort_by("phonecode", true, parseInt));
      var result = arr.reduce((unique, o) => {
        if (!unique.some(obj => obj.phonecode === o.phonecode)) {
          unique.push(o);
        }
        return unique;
      }, []);
      this.phonecodelist = result;
    }, (err) => {
      console.log(err);
    });
    this.userService.country().subscribe(res => {
      this.countries = res;
      this.countries.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
    }, (err) => {
      console.log(err);
    });
  }

  clearError(field) {
    switch (field) {
      case 'password':
        this.errorObj.password = '';
        break;
    }
  }
  checkpassword(val) {
    var strongRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$", "g");
    if (this.profile.password === '') {
      this.clearError('password');
    }
    else if (/\s/.test(val)) {
      if (this.storageService.getLanguage() == 'es') {
        this.errorObj.password = 'Ärge kasutage tühikuid';
      } else {
        this.errorObj.password = 'Do not use whitespace';
      }
    }
    else if (strongRegex.test(val)) {
      // this.errorObj.password = 'Password is Strong';
    }
    else {
      if (this.storageService.getLanguage() == 'es') {
        this.errorObj.password = 'Parool on nõrk';
      } else {
        this.errorObj.password = 'Password is Weak';
      }
    }
  }
  MatchPassName(str, substr) {
    var lowstr = str.toLowerCase().trim();
    var lowsubstr = substr.toLowerCase();
    var lowstrArr = lowstr.split(' ');
    var PassErroArr = [];
    if (lowstr !== '' && lowsubstr !== '') {
      lowstrArr.forEach(function (item) {
        if (lowsubstr.search("^.*" + item + ".*$") > -1) {
          PassErroArr.push(item)
        }
      })
      if (PassErroArr.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }
  validateNumber(e: any) {
    var input = e.target.value;
    if (input.length > 0) {
      if (typeof input != 'undefined' && input.length >= 6) {
        e.preventDefault();
      }
    }
  }
  updatePassword() {
    var strongRegex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$", "g");
    var spacecheck = /\s/.test(this.profile.password);
    var req = this.profile;
    if (this.profile.password && this.profile.confirmpassword && this.profile.currentpassword) {
      if (this.profile.password === this.profile.confirmpassword) {
        if (strongRegex.test(this.profile.password) && !spacecheck) {
          this.userService.updateuserpassword(req,this.storageService.getLanguage()).subscribe(res => {
            if (res.status == 200) {
              localStorage.setItem('username', this.profile.legalname.toString());
              if (this.storageService.getLanguage() == 'es') {
                this.notifyService.showSuccess("", res.messagees);
              } else {
                this.notifyService.showSuccess("", res.message);
              }

              
              location.href = "/profile";
            }
          }, (err) => {
           
            if (this.storageService.getLanguage() == 'es') {
              this.notifyService.showError("", err.error.messagees);
            } else {
              this.notifyService.showError("", err.error.message);
            }
           
          });
        }
      }

    }
  }

  getdate() {
    if (this.profile.month != '' && this.profile.year != '') {
      var count = this.getNumberOfDays(this.profile.year, this.profile.month);
      this.dateList = Array.from(Array(count), (_, i) => i + 1);
    }
  }

  getNumberOfDays(year, month) {
    var isLeap = ((year % 4) == 0 && ((year % 100) != 0 || (year % 400) == 0));
    return [31, (isLeap ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month - 1];
  }
  sendotp(mobileno) {
    var req = {
      no: '9659167747',
      _csrf: this.profile._csrf
    }
    this.userService.sendotp().subscribe(res => {
      this.states = res;
    }, (err) => {
      console.log(err);
    });
  }
  redirectforget() {
    this.router.navigate(['forgot-password']);
  }
  back() {
    this.location.back();
  }

  saveAntiphisingcode() {
    if (this.antiphis.antiphiscode !== '') {
      if (/\s/.test(this.antiphis.antiphiscode)) {
        this.spacecheck = false;
      } else {
        this.userService.saveAntiPhisCode(this.antiphis,this.storageService.getLanguage()).subscribe(response => {
          if (response) {
            if (this.storageService.getLanguage() == 'es') {
              this.notifyService.showSuccess("", response.messagees);
            } else {
              this.notifyService.showSuccess("", response.message);
            }
          }
        }, (err) => {
          if (this.storageService.getLanguage() == 'es') {
            this.notifyService.showError("", err.error.messagees);
          } else {
            this.notifyService.showError("", err.error.message);
          }
        });
      }
    }
  }
  updateTfa() {
    this.userService.updateTfa(this.tfaobject,this.storageService.getLanguage()).subscribe(res => {
      if (res) {
        this.tfaobject.sixdig_code = ''
        this.getProfile();
        if (this.storageService.getLanguage() == 'es') {
          this.notifyService.showSuccess("", res.messagees);
        } else {
          this.notifyService.showSuccess("", res.message);
        }
      }
    }, (err) => {
      if (this.storageService.getLanguage() == 'es') {
        this.notifyService.showError("", err.error.messagees);
      } else {
        this.notifyService.showError("", err.error.message);
      }

    });
  }
  omit_special_char(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  checkspacefn(char) {
    if (/\s/.test(char)) {
      this.spacecheck = false;
    } else {
      // console.log(char);
      this.spacecheck = true;
    }
  }
  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }
}
