import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ERROR_MESSAGES } from '../../../src/consts/error.messages'
import { NotificationService } from '../notification/notification.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DisplayKeyPopupComponent } from '../display-key-popup/display-key-popup.component';

@Component({
  selector: 'app-new-apiconfig-management',
  templateUrl: './new-apiconfig-management.component.html',
  styleUrls: ['./new-apiconfig-management.component.css']
})
export class NewApiconfigManagementComponent implements OnInit {
  apiKeyForm: FormGroup;
  keyName: any;
  errorMsg: typeof ERROR_MESSAGES = ERROR_MESSAGES;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private notifyService: NotificationService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.apiKeyForm = this.fb.group({
      _id: [''],
      ApiKeyConfigId: [''],
      KeyName: ['', Validators.required],
      ViewPermission: [false],
      TradePermission: [false],
      TransferPermission: [false],
      IsRestricted: ['', Validators.required],
      IPAddresses: [''],
    });
    this.route.params.subscribe(params => {
      if (params && params['id']) {
        this.getSingleApiConfig(params['id'])
      }
    })
  }

  submit() {
    let userd = this.userService.getUserDetails();
    this.userService.csrf().subscribe(success => {
      this.apiKeyForm.value['_csrf'] = success._csrf;
      this.apiKeyForm.value['UserId'] = userd._id
      this.userService.saveApiConfig(this.apiKeyForm.value).subscribe(res => {
        if (res && res.Data && res.Data.apiKey) {
          this.dialog.open(DisplayKeyPopupComponent, {
            height: '220px',
            width: '800px',
            data: res.Data,
            panelClass: "keyPopup",
            hasBackdrop: true
          }).afterClosed().subscribe((dialogResult) => {
            this.router.navigate(['apiconfig']);

          });
        }
        if (!this.apiKeyForm.value["ApiKeyConfigId"]) {
          this.notifyService.showSuccess(res.Message, 'Success')
        } else if (this.apiKeyForm.value["ApiKeyConfigId"]) {
          this.notifyService.showSuccess(res.Message, 'Success')
          this.router.navigate(['apiconfig']);
        }
        else this.notifyService.showError(res.Message, 'Error')
      })
    })

  }

  cancel() {
    this.apiKeyForm.reset();
    this.router.navigate(['apiconfig']);
  }
  getSingleApiConfig(id: any) {
    try {
      this.userService.apiConfig(id).subscribe(keyConfig => {
        if (keyConfig) {
          this.keyName = keyConfig.KeyName;
          this.apiKeyForm.controls["_id"].patchValue(keyConfig._id);
          this.apiKeyForm.controls["ApiKeyConfigId"].patchValue(keyConfig.ApiKeyConfigId);
          this.apiKeyForm.controls["KeyName"].patchValue(keyConfig.KeyName);
          this.apiKeyForm.controls["IsRestricted"].patchValue(keyConfig.IsRestricted);
          this.apiKeyForm.controls["IPAddresses"].patchValue(keyConfig.IPAddresses);
          this.apiKeyForm.controls["ViewPermission"].patchValue(keyConfig.ViewPermission);
          this.apiKeyForm.controls["TradePermission"].patchValue(keyConfig.TradePermission);
          this.apiKeyForm.controls["TransferPermission"].patchValue(keyConfig.TransferPermission);
        }
      })
    } catch (error) {
      console.log(error);
    }
  }
}
