import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { TradingService } from '../services/trading.service';
import { SocketService } from 'src/app/services/socket.service';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../notification/notification.service';
import { SocketWrapperService } from '../services/socket-wrapper.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  
  data: any = {};
  wallist: any = [];
  wallistFilter: any = [];
  ledger: any = [];
  ledgerFilter: any = [];
  currencylist: any = [];
  userId: string = '';
  updatedWallet: any = {};
  searchObj: any = {};
  fiatCurrencies: any = [];
  isZeroBalanceChecked: boolean = false;
  selectedMarketPriceCurrency: string = 'EUR';
  selectedMarketPriceCurrencyImage: string = '';
  marketPrices: any = [];
  totalPortfolioValue: number = 0;

  constructor(
    private userService: UserService,
    private tradeService: TradingService,
    private notifyService: NotificationService,
    private socketWrapper: SocketWrapperService,
    private readonly socketService: SocketService,

  ) {

    this.userId = this.userService.getUserId();
    setTimeout(() => {
      this.socketService.subscribeWalletDataChannels(this.userId);
    }, 1000);

   }

  ngOnInit(): void {

    this.userService.csrf().subscribe((response) => {
      this.data['_csrf'] = response._csrf;
      this.getcwaluserbalance();
      this.fetchLedger();
    });

    this.socketService.onWalletDataReceive().subscribe(updatedWallet => {
      this.updatedWallet = updatedWallet;
      console.log("updatedWallet",this.updatedWallet)
      var foundIndex = this.wallistFilter.findIndex(x => x._id == this.updatedWallet._id);
      if(foundIndex >= 0){
        this.wallistFilter[foundIndex].availableBalance = this.updatedWallet.availableBalance;
      }else{
        this.wallistFilter = [this.updatedWallet, ...this.wallistFilter]
      }
      this.changeMarketPriceCurrency();
      this.hideZeroBalance();
    })


    this.socketWrapper.on('connect',()=>{
      console.log('-------------------socket connected--------------------------');
      setTimeout(() => {
        this.socketService.subscribeWalletDataChannels(this.userId);
      }, 1000);
    });

    this.socketWrapper.on('disconnect',()=>{
      console.log('-------------------socket disconnected--------------------------');
    });


  }

  displayedColumns = ['position', 'name', 'weight', 'symbol', 'action'];
  displayedLedgerColumns = ['createdAt','txnID', 'txnref','Assets', 'txnType','type', 'amount', 'availableBalance'];
  
  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
    
  }

  getcwaluserbalance() {
    this.userService.getMarketPrices().subscribe(async (marketPrices) => {
      this.marketPrices = marketPrices.result;
      this.tradeService.getCwalUserBalapi(this.data).subscribe(async (result) => {
        if (result.wallist) {
          this.wallist = result.wallist
          this.changeMarketPriceCurrency();
          this.wallistFilter = this.wallist.sort((a, b) => b.value - a.value);
        }
      }, (err) => {
        console.log(err);
      });
    });
  }

  fetchLedger() {
    this.tradeService.getCurrencies().subscribe(list => {
      this.currencylist = list;
      this.fiatCurrencies = list.filter(currency => currency.CurrencyType == 'FIAT');
      var selectedMarketPriceCurrencyImage = this.fiatCurrencies.find(currency => currency.CurrencySymbol == this.selectedMarketPriceCurrency);
      this.selectedMarketPriceCurrencyImage = selectedMarketPriceCurrencyImage.Icon;
      this.userService.getLedgerlistbyuserid(this.data, '10', '1', '', '','USER',false).subscribe((result) => {
        this.ledger = []
        this.ledgerFilter = [];
        this.ledger = result.ledger.map(v => ({...v, image: this.currencylist.find(e => e.code === v.currency )}));
        this.ledgerFilter = result.ledger.map(v => ({...v, image: this.currencylist.find(e => e.code === v.currency )})).slice(0,10);
      }, (withdrawerr) => {
        this.notifyService.showError('', withdrawerr.error.message);
      })
    })
  }

  dateformat(value) {
    return new DatePipe('en-Us').transform(value, 'dd-MM-yyyy HH:mm:ss', 'GMT+2');
  }

  hideZeroBalance(){
    if(this.isZeroBalanceChecked){
      this.wallistFilter = this.wallist.filter(wal => wal.availableBalance > 0);
    }else{
      this.wallistFilter = this.wallist;
    }
  }

  changeMarketPriceCurrency(){
    this.totalPortfolioValue = 0;
    this.wallist = this.wallist.map(v => {
      var Price = this.marketPrices.find(e => (e.BaseCurrency === v.currency && e.QuoteCurrency === this.selectedMarketPriceCurrency))
      if(typeof Price !== 'undefined'){
        v.marketPrice = Price.CurrentPrice;
        v.value = v.availableBalance * Price.CurrentPrice;
        this.totalPortfolioValue = this.totalPortfolioValue + v.value;
      }else{
        v.marketPrice = 0;
        v.value = 0;
      }
      if(typeof v.icon == 'undefined'){
        var CurrencyImage =  this.currencylist.find(e => e.CurrencySymbol === v.currency );
        v.icon = CurrencyImage.Icon;
      }
      return v;
    }
    );
   var selectedMarketPriceCurrencyImage = this.fiatCurrencies.find(currency => currency.CurrencySymbol == this.selectedMarketPriceCurrency);
   this.selectedMarketPriceCurrencyImage = selectedMarketPriceCurrencyImage.Icon;

  }

  round(value, float = 0) {
    return parseFloat(value).toFixed(float);
  }
}
