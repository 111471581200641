import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }
  public getLanguage(){
    return localStorage.getItem('locale')
  }
  public setLanguage(lang){
    return localStorage.setItem('locale',lang)
  }
}
