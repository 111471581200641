import { Injectable } from '@angular/core';
import { UserService } from "../services/user.service";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class WalletsService {

    api_url: string = environment.apiEndPoint;

    constructor(private http: HttpClient, private router: Router,private userService : UserService) {}

    public getUserCreditBlance(config): Observable<any> {
        return this.userService.userequest('post', 'getUserCreditBlance', config);
    }

    public getUserBlance(config): Observable<any> {
        return this.userService.userequest('post', 'getUserBlance', config);
    }

    public getUserCreditLimit(config): Observable<any> {
        return this.userService.userequest('post', 'getUserCreditLimit', config);
    }

    public fundTransfer(config): Observable<any> {
        return this.userService.userequest('post', 'fundTransfer', config);
    }

}
