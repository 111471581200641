import { Component, OnInit } from '@angular/core';
import { KycserviceService } from '../services/kycservice.service'
import { Router } from "@angular/router";

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.css']
})
export class SubmitComponent implements OnInit {

  constructor(private kycService: KycserviceService,
    private route: Router) { }

  ngOnInit(): void {
    this.kycService.getUserKYC().subscribe(res => {
      if (res.status == 'Pending') {
        this.route.navigateByUrl('/kyc')
      }
    }, (err) => { })
  }

}
