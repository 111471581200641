import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { UserService } from '../services/user.service';
import { NotificationService } from '../notification/notification.service';
import { NgForm }   from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '../services/storage.service';
import { ReCaptcha2Component } from 'ngx-captcha';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  public isButtonVisible = true;
  public siteKey:any = '';
  theme:any;
  size:any;
  lang:any;
  type:any;

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  constructor( private adminService:AdminService,
               private notifyService:NotificationService,
               private userService:UserService,
               private router : ActivatedRoute,
               private storageService: StorageService,
               public translate: TranslateService,) { 
                this.siteKey = "6LfmI0MlAAAAAKQhPIF7bW1AKWgaZ_FO2L5iQj-J"
               }
  loginobj:any={
    usertype:String
  }
  createobj:any = {
    message : "",
    trading_vol_monthly : "",
    telegram_id : "",
    contact_number : "",
    email : "",
    customer_name : "",
    business_name : "",    
  }
  errorObj:any={};
  esErrorObj:any={};
  currTab:any='business';
  submit:boolean = false ;
  referal_code = "";
  contact_submit = "";

  ngOnInit(): void {
    this.router.queryParams.subscribe(params => {
      this.referal_code = params.ref_code;
      this.createobj.referal_input = params.ref_code;
    });
    if (this.storageService.getLanguage() == 'es') {
      this.contact_submit = "Saada";
    }
    else
    {
      this.contact_submit = "Submit";
    }
  }
  handleSuccess(captchaResponse: any)  {
    this.createobj['captchaToken'] = captchaResponse;
  }
  addGetinTouch(getin: NgForm){
    this.submit = true;
    if(JSON.stringify(this.createobj).search('""') !=-1){
      return false
    } else {    
      if(!this.createobj['captchaToken'])  { 
        if(this.storageService.getLanguage() == 'es'){
          this.notifyService.showError('', 'Captcha on nõutav');
        } else {
          this.notifyService.showError('', 'Captcha is Required') 
        }
        return false
      } 
      this.userService.csrf().subscribe(resp =>{
        this.createobj._csrf = resp._csrf
        this.adminService.addgetIntouch(this.createobj).subscribe(data =>{
          if (data) {
            if (this.storageService.getLanguage() == 'es') {
              this.notifyService.showSuccess("", data.messagees);
            } else {
              this.notifyService.showSuccess("", data.message);
            }
          } else {
            if (this.storageService.getLanguage() == 'es') {
              this.notifyService.showSuccess("", data.messagees);
            } else {
              this.notifyService.showSuccess("", data.message);
            }
          }
          Object.keys(getin.controls).forEach((key) => {
              const control = getin.controls[key];
              control.setErrors(null);
          });
          getin.resetForm(); 
          this.captchaElem.resetCaptcha();
        },(err)=>{   
          this.errorObj = err.error.message;
          this.esErrorObj = err.error.messagees;
          if(this.errorObj && this.errorObj.trading_vol_monthly) {
            if (this.storageService.getLanguage() == 'es') this.notifyService.showError('',this.esErrorObj.trading_vol_monthly);
            else this.notifyService.showError('',this.errorObj.trading_vol_monthly);          
          }
          else this.notifyService.showError('',this.errorObj);
        })
      })
    }
    
    
  }
  tabChange(tab){
      this.currTab = tab
  }
  clickevent(df){
  }

}
