import {Component, OnInit} from '@angular/core';
import {TokenPayload, UserService} from "../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {RoutingstateService} from "../services/routingstate.service";
import {AdminService} from "../services/admin.service";
 import { NotificationService } from '../notification/notification.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  constructor(){

  }
  signobj:any={};
  isNavbarCollapsed:any;
  arriveresponse:boolean = false;
  submit:boolean = false ;


ngOnInit() {


}
 
signup(){

}
redirectFunc(obj){

}
 
}
