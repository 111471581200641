import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-withdraw-confirm-popup',
  templateUrl: './withdraw-confirm-popup.component.html',
  styleUrls: ['./withdraw-confirm-popup.component.css']
})
export class WithdrawConfirmPopupDialogComponent implements OnInit {

  cryptoWithdraw: any = {};
  cryptoCurrencyActive: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<WithdrawConfirmPopupDialogComponent>) {
      if(data){
        this.cryptoWithdraw = data.cryptoWithdraw ;
        this.cryptoCurrencyActive = data.cryptoCurrencyActive;
      }
  }

  ngOnInit() {
  }
  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
